import { useEffect, useState } from "react";

//STYLES
import styles from "./AdformSettings.module.scss";

//COMPONENTS
import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import Loading from "components/Shared/Loading/Loading";
import { useParams } from "react-router-dom";
import GetCampaignAdFormSettingsResponse from "model/response/campaign/getCampaignAdFormSettingsResponse";
import { createCampaignService } from "services/campaignService";
import NullableFormItem from "components/CustomForm/Components/NullableFormItem";
import EditCampaignAdFormSettingsRequest from "model/request/campaign/editCampaignAdFormSettingsRequest";
import { toast } from "react-toastify";

interface AdformSettingsProps {
  setReloadData: (value: boolean) => void;
}

const AdformSettings = ({ setReloadData }: AdformSettingsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [campaign, setCampaign] =
    useState<GetCampaignAdFormSettingsResponse | null>(null);
  const [editedCampaign, setEditedCampaign] =
    useState<GetCampaignAdFormSettingsResponse | null>(null);
  const [diff, setDiff] =
    useState<Partial<GetCampaignAdFormSettingsResponse> | null>(null);

  const campaignService = createCampaignService();

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const value =
      e.target.type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;
    if (editedCampaign) {
      setEditedCampaign({
        ...editedCampaign,
        [e.target.name]: value,
      });
    }
  };

  const handleSave = async () => {
    if (!diff || !id) return;
    setIsLoading(true);
    const postbody: EditCampaignAdFormSettingsRequest = {
      id: parseInt(id),
      adformIntegrated: diff.adformIntegrated,
      adformCampaignId: diff.adformCampaignId,
      adformPlacementId: diff.adformPlacementId,
    };
    const res = await campaignService.editAdFormSettings(postbody);
    if (res.success) {
      toast.success("Changes saved successfully");
      setReloadData(true);
    } else {
      setIsLoading(false);
      toast.error("Error saving changes");
    }
  };

  useEffect(() => {
    if (campaign && editedCampaign) {
      const changes: any = {};

      (
        Object.keys(
          editedCampaign
        ) as (keyof GetCampaignAdFormSettingsResponse)[]
      ).forEach((key) => {
        if (editedCampaign[key] != campaign[key]) {
          changes[key] = editedCampaign[key]!;
        }
      });

      setDiff(changes);
    } else {
      setDiff(null);
    }
  }, [campaign, editedCampaign]);

  useEffect(() => {
    (async () => {
      if (!id) return;
      const response = await campaignService.getAdFormSettings({
        id: parseInt(id),
      });
      setCampaign(response);
      setEditedCampaign(response);
    })();
    setIsLoading(false);
  }, []);

  if (isLoading || editedCampaign === null) return <Loading />;

  return (
    <div className={styles.adformSettings}>
      <CustomForm>
        <CustomFormItem title="Adform Integrated">
          <input
            type="checkbox"
            onChange={handleInputChange}
            checked={editedCampaign.adformIntegrated}
            name="adformIntegrated"
          />
        </CustomFormItem>
        <CustomFormItem title="Adform Campaign Id">
          <NullableFormItem<string>
            value={editedCampaign.adformCampaignId}
            setValue={(value: string | null) => {
              setEditedCampaign({
                ...editedCampaign,
                adformCampaignId: value,
              });
            }}
            defaultValue={""}
          >
            {(value, setValue) => (
              <input
                type="text"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                placeholder="Adform Campaign Id"
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title="Adform Placement Id">
          <NullableFormItem<string>
            value={editedCampaign.adformPlacementId}
            setValue={(value: string | null) => {
              setEditedCampaign({
                ...editedCampaign,
                adformPlacementId: value,
              });
            }}
            defaultValue={""}
          >
            {(value, setValue) => (
              <input
                type="text"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                placeholder="Adform Placement Id"
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        {diff && Object.keys(diff).length > 0 && (
          <CustomFormItem title="">
            <button onClick={handleSave} disabled={isLoading}>
              Submit
            </button>
          </CustomFormItem>
        )}
      </CustomForm>
    </div>
  );
};

export default AdformSettings;
