import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink, formatNumber } from "helpers";
const adsView: CustomDataGridViewDto = {
  viewName: "Ads",
  id: "ads",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "streamer":
        navigate(`streamer-popup/${params.row.streamer.id}/overview`);
        break;
      case "campaign":
        navigate(`campaigns-popup/${params.row.campaign.id}/overview`);
        break;
      default:
        navigate(`ads-popup/${params.id}/labelling`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        resizable: true,
        width: 100,
      },
      {
        field: "timestamp",
        headerName: "Timestamp",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return new Date(params.value).toLocaleString();
        },
      },
      {
        field: "isAutomated",
        headerName: "Is Automated",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value === 1 ? "Yes" : "No";
        },
      },
      {
        field: "messageSent",
        headerName: "Message Sent",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value === 1 ? "Yes" : "No";
        },
      },
      {
        field: "adMethod",
        headerName: "Ad Method",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.isAutomated === 1 ? "Automated" : "Manual";
        },
      },
      {
        field: "platform",
        headerName: "Streamer Platform",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "client",
        headerName: "Client",
        width: 150,
        resizable: true,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "standard",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "campaign",
        headerName: "Campaign",
        width: 400,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value.name : "-";
        },
      },
      {
        field: "redirectLink",
        headerName: "LIVAD.GG URL",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "clipLink",
        headerName: "Clip URL",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "promotionClipLink",
        headerName: "Promotion Clip URL",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "eventClipLink",
        headerName: "Event Clip",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "CPM",
        headerName: "CPM",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value?.value
            ? `${params.value?.currencySign} ${formatCurrency(
                params.value?.value
              )}`
            : params.value?.value === 0
            ? `${params.value?.currencySign} 0`
            : "-";
        },
      },
      {
        field: "CTR",
        headerName: "CTR",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value
            ? `${Number(params.value)}%`
            : params.value === 0
            ? "0%"
            : "-",
      },
      {
        field: "clicks",
        headerName: "Clicks",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "views",
        headerName: "Views",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "category",
        headerName: "Category",
        width: 250,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value.name}` : "-",
      },
      {
        field: "streamerCPM",
        headerName: "Streamer CPM",
        width: 140,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.value.currencySign} ${formatCurrency(
                params.value.value
              )}`
            : params.value === 0
            ? "0"
            : "-";
        },
      },
      {
        field: "gender",
        headerName: "Gender",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default adsView;
