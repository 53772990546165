import styles from "./StreamerTargeting.module.scss";
import SelectInput from "components/Shared/SelectInput/SelectInput";
import { toAbsoluteUrl } from "helpers";
import EnumFilterType from "model/enum/enumFilterType";
import GetCampaignBusinessSettingsResponse from "model/response/campaign/getCampaignBusinessSettingsResponse";
import { createFilterService } from "services/filterService";

interface StreamerTargetingProps {
  data: GetCampaignBusinessSettingsResponse;
  setData: (data: GetCampaignBusinessSettingsResponse) => void;
}
const StreamerTargeting = ({ data, setData }: StreamerTargetingProps) => {
  const filterService = createFilterService();
  return (
    <div className={styles.streamerTargeting}>
      <SelectInput
        fetchResults={(search) => {
          return filterService.get({
            type: EnumFilterType.Streamers,
            search: search,
          });
        }}
        select={(entity) => {
          if (!data.streamers.find((x) => x.value === entity.value)) {
            setData({
              ...data,
              streamers: [
                ...data.streamers,
                entity,
              ],
            });
          }
        }}
      />
      <div className={styles.selectedList}>
        {data.streamers.map((streamer, index) => (
          <div key={index} className={styles.listItem}>
            <img
              src={
                streamer.logo ??
                toAbsoluteUrl("/assets/icons/defaultAvatar.png")
              }
              alt="Streamer"
            />
            {/* <img
              src={toAbsoluteUrl(
                `/assets/icons/Platforms/${streamer.platform}-icon.png`
              )}
              alt="Platform"
              className={styles.platformIcon}
            /> */}
            {streamer.label}
            <button
              onClick={() => {
                setData({
                  ...data,
                  streamers: data.streamers.filter((x) => x.value !== streamer.value),
                });
              }}
              className={styles.removeBtn}
            >
              <svg
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM9.41 15.95 12 13.36l2.59 2.59L16 14.54l-2.59-2.59L16 9.36l-1.41-1.41L12 10.54 9.41 7.95 8 9.36l2.59 2.59L8 14.54z"></path>
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StreamerTargeting;
