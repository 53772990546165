import styles from "./ToggleSwitch.module.scss";
import classNames from "classnames";

interface ToggleSwitchProps {
  value: boolean;
  size?: "small" | "medium" | undefined;
  setValue: () => void;
}

const ToggleSwitch = ({ value, setValue, size }: ToggleSwitchProps) => {
  return (
    <div
      className={classNames(styles.toggleSwitch, {
        [styles.active]: value,
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
      })}
      onClick={() => setValue()}
    >
      <div className={styles.toggleDot}></div>
    </div>
  );
};

export default ToggleSwitch;
