import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { EnumRequestType } from "model/enum/enumRequestType";
import { getAuth } from "firebase/auth";

export const createBaseApiService = (path: string) => {
  const baseUrl = process.env.REACT_APP_TEAM_API_URL + path;

  //   const post = async <T>(
  //     url: string,
  //     body: Object = {},
  //     requestType: EnumRequestType = EnumRequestType.Json,
  //     config: AxiosRequestConfig = {}
  //   ): Promise<AxiosResponse<T>> => {
  //     try {
  //       let headers = await generateHeader(requestType);
  //       if (requestType === EnumRequestType.FormUrlEncoder) {
  //         body = qs.stringify(body, { allowDots: true });
  //       }
  //       let response = await axios.post<T, AxiosResponse<T>>(
  //         baseUrl + url,
  //         body ?? {},
  //         {
  //           headers: headers,
  //           // withCredentials: true,
  //           ...config,
  //         }
  //       );
  //       return response;
  //     } catch (err: any) {
  //       throw err;
  //     }
  //   };

  const post = async <T>(
    url: string,
    body?: Object,
    requestType: EnumRequestType = EnumRequestType.Json,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType);
      let response = await axios.post<T, AxiosResponse<T>>(
        baseUrl + url,
        body ?? {},
        {
          headers: headers,
          // withCredentials: true,
          ...config,
        }
      );
      return response;
    } catch (err: any) {
      // return errorHandler<T>(err)
      throw err;
    }
  };

  const redirect = async (url: string): Promise<void> => {
    window.location.href = url;
  };

  const generateHeader = async (
    requestType: EnumRequestType
  ): Promise<{ [key: string]: string }> => {
    let headers: { [key: string]: string } = {};
    if (requestType === EnumRequestType.Json) {
      headers["Content-Type"] = "application/json";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.FormUrlEncoder) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.NoAuthJson) {
      headers["Content-Type"] = "application/json";
    } else if (requestType === EnumRequestType.FormData) {
      headers["Content-Type"] = "multipart/form-data";
      headers.Authorization = await getToken();
    } else {
      throw new Error("requestType is not valid");
    }
    return headers;
  };

  const getToken = async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // User is not logged in, throw an error
      throw new Error("User is not logged in.");
    }
    const idTokenResult = await user.getIdTokenResult();
    const freshToken = "Bearer " + idTokenResult.token;
    return freshToken;
  };
  return { post, redirect };
};

export const createFansApiBaseService = (path: string) => {
  const baseUrl = process.env.REACT_APP_FANS_API_URL + path;

  //   const post = async <T>(
  //     url: string,
  //     body: Object = {},
  //     requestType: EnumRequestType = EnumRequestType.Json,
  //     config: AxiosRequestConfig = {}
  //   ): Promise<AxiosResponse<T>> => {
  //     try {
  //       let headers = await generateHeader(requestType);
  //       if (requestType === EnumRequestType.FormUrlEncoder) {
  //         body = qs.stringify(body, { allowDots: true });
  //       }
  //       let response = await axios.post<T, AxiosResponse<T>>(
  //         baseUrl + url,
  //         body ?? {},
  //         {
  //           headers: headers,
  //           // withCredentials: true,
  //           ...config,
  //         }
  //       );
  //       return response;
  //     } catch (err: any) {
  //       throw err;
  //     }
  //   };

  const post = async <T>(
    url: string,
    body?: Object,
    requestType: EnumRequestType = EnumRequestType.Json,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType);
      let response = await axios.post<T, AxiosResponse<T>>(
        baseUrl + url,
        body ?? {},
        {
          headers: headers,
          // withCredentials: true,
          ...config,
        }
      );
      return response;
    } catch (err: any) {
      // return errorHandler<T>(err)
      throw err;
    }
  };

  const redirect = async (url: string): Promise<void> => {
    window.location.href = url;
  };

  const generateHeader = async (
    requestType: EnumRequestType
  ): Promise<{ [key: string]: string }> => {
    let headers: { [key: string]: string } = {};
    if (requestType === EnumRequestType.Json) {
      headers["Content-Type"] = "application/json";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.FormUrlEncoder) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.NoAuthJson) {
      headers["Content-Type"] = "application/json";
    } else if (requestType === EnumRequestType.FormData) {
      headers["Content-Type"] = "multipart/form-data";
      headers.Authorization = await getToken();
    } else {
      throw new Error("requestType is not valid");
    }
    return headers;
  };

  const getToken = async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // User is not logged in, throw an error
      throw new Error("User is not logged in.");
    }
    const idTokenResult = await user.getIdTokenResult();
    const freshToken = "Bearer " + idTokenResult.token;
    return freshToken;
  };
  return { post, redirect };
};

export const createAdsApiBaseService = (path: string) => {
  const baseUrl = process.env.REACT_APP_ADS_API_URL + path;

  //   const post = async <T>(
  //     url: string,
  //     body: Object = {},
  //     requestType: EnumRequestType = EnumRequestType.Json,
  //     config: AxiosRequestConfig = {}
  //   ): Promise<AxiosResponse<T>> => {
  //     try {
  //       let headers = await generateHeader(requestType);
  //       if (requestType === EnumRequestType.FormUrlEncoder) {
  //         body = qs.stringify(body, { allowDots: true });
  //       }
  //       let response = await axios.post<T, AxiosResponse<T>>(
  //         baseUrl + url,
  //         body ?? {},
  //         {
  //           headers: headers,
  //           // withCredentials: true,
  //           ...config,
  //         }
  //       );
  //       return response;
  //     } catch (err: any) {
  //       throw err;
  //     }
  //   };

  const post = async <T>(
    url: string,
    body?: Object,
    requestType: EnumRequestType = EnumRequestType.Json,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    try {
      let headers = await generateHeader(requestType);
      let response = await axios.post<T, AxiosResponse<T>>(
        baseUrl + url,
        body ?? {},
        {
          headers: headers,
          // withCredentials: true,
          ...config,
        }
      );
      return response;
    } catch (err: any) {
      // return errorHandler<T>(err)
      throw err;
    }
  };

  const redirect = async (url: string): Promise<void> => {
    window.location.href = url;
  };

  const generateHeader = async (
    requestType: EnumRequestType
  ): Promise<{ [key: string]: string }> => {
    let headers: { [key: string]: string } = {};
    if (requestType === EnumRequestType.Json) {
      headers["Content-Type"] = "application/json";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.FormUrlEncoder) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
      headers.Authorization = await getToken();
    } else if (requestType === EnumRequestType.NoAuthJson) {
      headers["Content-Type"] = "application/json";
    } else if (requestType === EnumRequestType.FormData) {
      headers["Content-Type"] = "multipart/form-data";
      headers.Authorization = await getToken();
    } else {
      throw new Error("requestType is not valid");
    }
    return headers;
  };

  const getToken = async (): Promise<string> => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      // User is not logged in, throw an error
      throw new Error("User is not logged in.");
    }
    const idTokenResult = await user.getIdTokenResult();
    const freshToken = "Bearer " + idTokenResult.token;
    return freshToken;
  };
  return { post, redirect };
};
