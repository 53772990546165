import { useEffect, useState } from "react";

import EnumCampaignStatus from "model/enum/enumCampaignStatus";

//STYLES
import styles from "./PostReview.module.scss";

//COMPONENTS
import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import CustomDateInput from "components/CustomForm/Components/CustomDateInput/CustomDateInput";
import NullableFormItem from "components/CustomForm/Components/NullableFormItem";
import InfluencerCpmInput from "./Components/InfluencerCpmInput/InfluencerCpmInput";
import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";
import { toast } from "react-toastify";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import Loading from "components/Shared/Loading/Loading";
import { createFilterService } from "services/filterService";
import EnumFilterType from "model/enum/enumFilterType";
import { SearchFilterResponseItem } from "model/response/filter/searchFilterResponse";
import EditCampaignPostReviewSettingsRequest from "model/request/campaign/editCampaignPostReviewSettingsRequest";
import ChatEngagementKeywords from "./Components/ChatEngagementKeywords/ChatEngagementKeywords";
import ChatMessageList from "./Components/ChatMessageList/ChatMessageList";
import Pagination from "components/Shared/Pagination/Pagination";

interface PostReviewProps {
  setReloadData: (value: boolean) => void;
}
const PostReview = ({ setReloadData }: PostReviewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [owners, setOwners] = useState<SearchFilterResponseItem[]>([]);
  const [campaign, setCampaign] =
    useState<GetCampaignPostReviewSettingsResponse | null>(null);
  const [editedCampaign, setEditedCampaign] =
    useState<GetCampaignPostReviewSettingsResponse | null>(null);
  const [diff, setDiff] =
    useState<Partial<GetCampaignPostReviewSettingsResponse> | null>(null);
  const [activeKeywordIndex, setActiveKeywordIndex] = useState<number>(0);
  const [pageOptions, setPageOptions] = useState({
    page: 0,
    hasNextPage: false,
  });

  const { id } = useParams();

  const campaignService = createCampaignService();
  const filterService = createFilterService();

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const value =
      e.target.type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;
    if (editedCampaign) {
      setEditedCampaign({
        ...editedCampaign,
        [e.target.name]: value,
      });
    }
  };
  const handleFetchPostReviewSettings = async () => {
    const response = await campaignService.getPostReviewSettings({
      id: id ? parseInt(id) : 0,
    });
    setCampaign(response);
    setEditedCampaign(response);
  };
  const handleFetchOwnerList = async () => {
    const response = await filterService.get({
      type: EnumFilterType.Owner,
      search: "",
    });
    setOwners(response.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          handleFetchPostReviewSettings(),
          handleFetchOwnerList(),
        ]);
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    if (!diff || !id) return;
    setIsLoading(true);
    const postbody: EditCampaignPostReviewSettingsRequest = {
      id: parseInt(id),
      displayName: diff.displayName,
      status: diff.status,
      ownerId: diff.ownerId,
      influencerCPMs: diff.influencerCPMs,
      influencerCPMBonusPercentage: diff.influencerCPMBonusPercentage,
      paymentDueDate: diff.paymentDueDate,
      keywords: diff.keywords,
    };
    const res = await campaignService.editPostReviewSettings(postbody);
    if (res.success) {
      toast.success("Changes saved successfully");
      setReloadData(true);
    } else {
      setIsLoading(false);
      toast.error("Error saving changes");
    }
  };

  useEffect(() => {
    if (campaign && editedCampaign) {
      const changes: any = {};

      (
        Object.keys(
          editedCampaign
        ) as (keyof GetCampaignPostReviewSettingsResponse)[]
      ).forEach((key) => {
        if (editedCampaign[key] != campaign[key]) {
          changes[key] = editedCampaign[key]!;
        }
      });

      setDiff(changes);
    } else {
      setDiff(null);
    }
  }, [campaign, editedCampaign]);

  if (!id) return null;
  if (isLoading || editedCampaign === null) return <Loading />;

  return (
    <div className={styles.postReview}>
      <CustomForm>
        <CustomFormItem title="Campaign Display Name">
          <input
            name="displayName"
            type="text"
            placeholder="Campaign Display Name"
            value={editedCampaign.displayName ?? undefined}
            onChange={handleInputChange}
          />
        </CustomFormItem>
        <CustomFormItem title="Campaign Status">
          <select
            name="status"
            value={editedCampaign.status}
            onChange={handleInputChange}
          >
            {Object.entries(EnumCampaignStatus).map(([key, value]) => (
              <option key={key} value={value}>
                {value
                  .split("_")
                  .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                  .join(" ")}
              </option>
            ))}
          </select>
        </CustomFormItem>
        <CustomFormItem title="Campaign Owner">
          <NullableFormItem
            value={editedCampaign.ownerId}
            setValue={(value) => {
              setEditedCampaign({
                ...editedCampaign,
                ownerId: value,
              });
            }}
            defaultValue={""}
          >
            {(value, setValue) => (
              <select
                value={value ?? undefined}
                onChange={(e) => setValue(e.target.value)}
              >
                <option value={""}>Select Owner</option>
                {owners.map((owner) => (
                  <option key={owner.value} value={owner.value}>
                    {owner.label}
                  </option>
                ))}
              </select>
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title={"Influencer CPM"}>
          <InfluencerCpmInput
            campaignData={editedCampaign}
            setCampaignData={setEditedCampaign}
            currencyId={"try"}
          />
          <InfluencerCpmInput
            campaignData={editedCampaign}
            setCampaignData={setEditedCampaign}
            currencyId={"usd"}
          />
          <InfluencerCpmInput
            campaignData={editedCampaign}
            setCampaignData={setEditedCampaign}
            currencyId={"eur"}
          />
          <InfluencerCpmInput
            campaignData={editedCampaign}
            setCampaignData={setEditedCampaign}
            currencyId={"gbp"}
          />
        </CustomFormItem>
        <CustomFormItem title="Influencer CPM Bonus Percentage">
          <NullableFormItem<number>
            value={
              editedCampaign.influencerCPMBonusPercentage === null
                ? null
                : parseFloat(
                    (editedCampaign.influencerCPMBonusPercentage * 100).toFixed(
                      2
                    )
                  )
            }
            setValue={(value) => {
              setEditedCampaign({
                ...editedCampaign,
                influencerCPMBonusPercentage:
                  value === null ? null : value / 100,
              });
            }}
            defaultValue={0}
          >
            {(value, setValue) => (
              <>
                <span>%</span>
                <input
                  type="number"
                  value={value !== null ? value : ""}
                  min={0}
                  step="0.01"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numericValue =
                      inputValue === "" ? 0 : parseFloat(inputValue);
                    setValue(numericValue);
                  }}
                />
              </>
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title="Payment Due Date">
          <NullableFormItem<Date>
            value={editedCampaign.paymentDueDate}
            setValue={(value: Date | null) => {
              setEditedCampaign({
                ...editedCampaign,
                paymentDueDate: value,
              });
            }}
            defaultValue={new Date()}
          >
            {(value, setValue) => (
              <CustomDateInput
                value={value}
                setValue={setValue}
                includeTime={true}
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title="Chat Engagement Keywords">
          <ChatEngagementKeywords
            editedCampaign={editedCampaign}
            setEditedCampaign={setEditedCampaign}
            activeKeywordIndex={activeKeywordIndex}
            setActiveKeywordIndex={setActiveKeywordIndex}
          />
        </CustomFormItem>
        {editedCampaign.keywords && editedCampaign.keywords.length > 0 && (
          <CustomFormItem title="Keyword's Chat Results">
            <ChatMessageList
              editedCampaign={editedCampaign}
              setEditedCampaign={setEditedCampaign}
              activeKeywordIndex={activeKeywordIndex}
              pageOptions={pageOptions}
              setPageOptions={setPageOptions}
            />
          </CustomFormItem>
        )}
      </CustomForm>
      <div style={{ marginLeft: "auto" }}>
        <Pagination pageOptions={pageOptions} setPageOptions={setPageOptions} />
      </div>
      <CustomForm>
        {diff && Object.keys(diff).length > 0 && (
          <CustomFormItem title="">
            <button onClick={handleSave} disabled={isLoading}>
              Save
            </button>
          </CustomFormItem>
        )}
      </CustomForm>
    </div>
  );
};

export default PostReview;
