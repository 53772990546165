import styles from "./ChangeStatus.module.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import EditCampaignRequestedStreamersRequest, {
  EditCampaignRequestedStreamersRequestItem,
} from "model/request/campaign/editCampaignRequestedStreamersRequest";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import EnumCampaignHasStreamerStatus from "model/enum/enumCampaignHasStreamerStatus";

interface ChangeStatusProps {
  setReloadData: (value: boolean) => void;
  rowSelectionModel: string[];
  setRowSelectionModel: (rowSelectionModel: number[]) => void;
}

const ChangeStatus = ({
  setReloadData,
  rowSelectionModel,
  setRowSelectionModel,
}: ChangeStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<
    Partial<EditCampaignRequestedStreamersRequestItem>
  >({});
  const adFrequencyOptions = [];
  const { id } = useParams();
  const campaignService = createCampaignService();

  const handleUpdateBulk = async () => {
    if (!id) return;
    setIsLoading(true);

    if (Object.keys(data).length === 0)
      return toast.error("Please select at least one field to update");

    const postBody: EditCampaignRequestedStreamersRequest = {
      id: parseInt(id),
      streamers: rowSelectionModel.map((streamerId) => ({
        ...data,
        id: streamerId,
      })),
    };

    try {
      const response = await toast.promise(
        campaignService.editRequestedStreamers(postBody),
        {
          pending: "Updating streamers...",
        }
      );
      if (response.success) {
        toast.success("Streamers status updated successfully");
        setReloadData(true);
        setRowSelectionModel([]);
      } else {
        toast.error("An error occurred while updating streamers");
      }
    } catch (error) {
      toast.error("An error occurred while updating streamers");
    } finally {
      setIsLoading(false);
    }
  };

  for (let i = 5; i <= 180; i += 5) {
    adFrequencyOptions.push(i);
  }

  return (
    <div className={styles.changeStatus}>
      <div className={styles.section}>
        <div className={styles.title}>
          Status
        </div>
        <select
          onChange={(e) => {
            if (e.target.value !== "default-value") {
              setData({
                ...data,
                status: e.target.value as EnumCampaignHasStreamerStatus,
              });
            } else {
              let newData = { ...data };
              delete newData.status;
              setData(newData);
            }
          }}
          value={data.status}
        >
          <option value="default-value">Select</option>
          {Object.entries(EnumCampaignHasStreamerStatus).map(([key, value]) => (
            <option key={key} value={value}>
              {value
                .split("_")
                .map((word) => word[0].toUpperCase() + word.slice(1))
                .join(" ")}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>
          Ads On
        </div>
        <select
          onChange={(e) => {
            if (e.target.value !== "default-value") {
              setData({
                ...data,
                adsOn: parseInt(e.target.value) === 1,
              });
            } else {
              let newData = { ...data };
              delete newData.adsOn;
              setData(newData);
            }
          }}
          value={
            data.adsOn === undefined ? "default-value" : data.adsOn ? 1 : 0
          }
        >
          <option value="default-value">Select</option>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>
          Automated Ads On
        </div>
        <select
          onChange={(e) => {
            if (e.target.value !== "default-value") {
              setData({
                ...data,
                automatedAdsOn: parseInt(e.target.value) === 1,
              });
            } else {
              let newData = { ...data };
              delete newData.automatedAdsOn;
              setData(newData);
            }
          }}
          value={
            data.automatedAdsOn === undefined
              ? "default-value"
              : data.automatedAdsOn
              ? 1
              : 0
          }
        >
          <option value="default-value">Select</option>
          <option value={1}>Yes</option>
          <option value={0}>No</option>
        </select>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>
          Ad Frequency
        </div>
        <select
          onChange={(e) => {
            if (e.target.value !== "default-value") {
              setData({
                ...data,
                adFrequency: parseInt(e.target.value),
              });
            } else {
              let newData = { ...data };
              delete newData.adFrequency;
              setData(newData);
            }
          }}
          value={data.adFrequency}
        >
          <option value="default-value">Select</option>
          {adFrequencyOptions.map((option) => (
            <option key={option} value={option}>
              {option} minutes
            </option>
          ))}
        </select>
      </div>
      <button
        className={styles.saveChanges}
        disabled={isLoading || Object.keys(data).length === 0}
        onClick={handleUpdateBulk}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18 19H19V6.82843L17.1716 5H16V9H7V5H5V19H6V12H18V19ZM4 3H18L20.7071 5.70711C20.8946 5.89464 21 6.149 21 6.41421V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM8 14V19H16V14H8Z" />
        </svg>
        Save for Selected
      </button>
    </div>
  );
};

export default ChangeStatus;
