import styles from "./TabSwitcher.module.scss";

//COMPONENTS
import TabItem from "./Components/TabItem/TabItem";

interface TabSwitcherProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabs: { title: string; value: string }[];
}

const TabSwitcher = (props: TabSwitcherProps) => {
  return (
    <div className={styles.tabSwitcher}>
      {props.tabs.map((tab, index) => {
        if (tab.value === "overview") return null;
        return (
          <TabItem
            key={index}
            title={tab.title}
            value={tab.value}
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
          />
        );
      })}
    </div>
  );
};

export default TabSwitcher;
