import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";

const chatEngagementFilterOptions: FilterItemDto[] = [
  {
    type: "static",
    title: "Is Approved",
    results: [
      { label: "Approved", value: 1, selected: false },
      { label: "Rejected", value: 0, selected: false },
      { label: "Not Labeled", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "isApproved",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Streamer",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
];

export default chatEngagementFilterOptions;
