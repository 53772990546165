import { useEffect, useRef } from "react";
//STYLES
import styles from "./Streamers.module.scss";

//CONFIG
import streamerViews from "./config/views";
//CONTEXT

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useStreamersContext } from "context/StreamersContext";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import { useFilterContext } from "context/FilterContext";
import { useSearchParams } from "react-router-dom";

const Streamers = () => {
  document.title = "Streamers - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchStreamers,
    gridIsLoading,
  } = useStreamersContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchStreamers();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchStreamers();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.streamers}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />{" "}
          </svg>
          Streamers
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchStreamers}
        export={true}
        from="streamers"
        clear={true}
      />
      {streamerViews.length > 1 && (
        <CustomSelect
          views={streamerViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          streamerViews.find((view) => view.id === activeView) ??
          streamerViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default Streamers;
