const tabs = [
  {
    title: "overview",
    value: "overview",
  },
  {
    title: "Edit Payout",
    value: "edit-payout",
  },
];
export default tabs;
