import { useEffect, useState } from "react";

//STYLES
import styles from "./TranscriptText.module.scss";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";

//MODELS
import AdSubtitleDto from "model/dto/ad/adSubtitleDto";
import GetAdTranscriptsResponse from "model/response/ads/getAdTranscriptsResponse";

interface TranscriptTextProps {
  adTranscripts?: GetAdTranscriptsResponse;
  adSubtitles?: AdSubtitleDto[];
  clipType: "main" | "promotion";
  promotionStartTime: number | null;
  promotionEndTime: number | null;
  loading: boolean;
}

const TranscriptText = ({
  adTranscripts,
  adSubtitles,
  clipType,
  promotionStartTime,
  promotionEndTime,
  loading,
}: TranscriptTextProps) => {
  const [promotionText, setPromotionText] =
    useState<GetAdTranscriptsResponse | null>(null);
  const [promotionSubtitle, setPromotionSubtitle] = useState<AdSubtitleDto[]>(
    []
  );
  useEffect(() => {
    if (clipType === "promotion") {
      if (adSubtitles && promotionStartTime && promotionEndTime) {
        const promotionSubtitle = adSubtitles.filter(
          (subtitle) =>
            subtitle.startTime >= promotionStartTime! &&
            subtitle.endTime <= promotionEndTime!
        );
        setPromotionSubtitle(promotionSubtitle);
      }
    } else {
      setPromotionSubtitle([]);
    }
  }, [clipType]);

  useEffect(() => {
    if (clipType === "promotion") {
      if (promotionSubtitle.length > 0) {
        const promotionMainText = promotionSubtitle
          .map((subtitle) => subtitle.text)
          .join(". ");
        const promotionEnglishText = promotionSubtitle
          .map((subtitle) => subtitle.englishText)
          .join(". ");
        setPromotionText({
          text: promotionMainText,
          englishText: promotionEnglishText,
        });
      }
    } else {
      setPromotionText(null);
    }
  }, [clipType, promotionSubtitle]);

  if (loading)
    return (
      <div className={styles.transcriptText}>
        <Loading />
      </div>
    );

  return (
    <div className={styles.transcriptText}>
      <div className={styles.textSection}>
        <div className={styles.type}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M14.17 5 19 9.83V19H5V5h9.17m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59zM7 15h10v2H7v-2zm0-4h10v2H7v-2zm0-4h7v2H7V7z" />
          </svg>
          Main
        </div>
        <p>
          {promotionText?.text
            ? promotionText.text
            : adTranscripts?.text || (
                <div className={styles.notAvailable}>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 16 16"
                    height="200px"
                    width="200px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                    <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                  </svg>
                  Main text is not available
                </div>
              )}
        </p>
      </div>
      <div className={styles.textSection}>
        <div className={styles.type}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z" />
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31" />
          </svg>
          Translated
        </div>
        <p>
          {promotionText?.englishText
            ? promotionText.englishText
            : adTranscripts?.englishText || (
                <div className={styles.notAvailable}>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 16 16"
                    height="200px"
                    width="200px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
                    <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                  </svg>
                  Translated text is not available
                </div>
              )}
        </p>
      </div>
    </div>
  );
};

export default TranscriptText;
