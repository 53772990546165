import GetPayoutAccountsRequest from "model/request/payouts/getPayoutAccountsRequest";
import { createBaseApiService } from "./baseApiService";
import GetPayoutsRequest from "model/request/payouts/getPayoutsRequest";
import GetPayoutsResponse from "model/response/payouts/getPayoutsResponse";
import GetPayoutAccountsResponse from "model/response/payouts/getPayoutAccountsResponse";
import IdRequest from "model/request/idRequest";
import GetPayoutAccountDetailResponse from "model/response/payouts/getPayoutAccountDetailResponse";
import UpdatePayoutRequest from "model/request/payouts/updatePayoutRequest";
import UpdateResponse from "model/response/updateResponse";
import GetPayoutDetailResponse from "model/response/payouts/getPayoutDetailResponse";
import { EnumRequestType } from "model/enum/enumRequestType";

export const createPayoutsService = () => {
  const baseService = createBaseApiService("/payouts");

  const get = async (req: GetPayoutsRequest) => {
    const response = await baseService.post<GetPayoutsResponse>("/get", req);
    return response.data;
  };
  const getPayoutDetail = async (req: IdRequest) => {
    const response = await baseService.post<GetPayoutDetailResponse>(
      "/getPayoutDetail",
      req
    );
    return response.data;
  };
  const updatePayout = async (req: UpdatePayoutRequest) => {
    const response = await baseService.post<UpdateResponse>(
      "/updatePayout",
      req
    );
    return response.data;
  };

  const updatePayoutBulk = async (req: UpdatePayoutRequest[]) => {
    const response = await baseService.post<UpdateResponse>(
      "/updatePayoutBulk",
      req
    );
    return response.data;
  };

  const getPayoutAccounts = async (req: GetPayoutAccountsRequest) => {
    const response = await baseService.post<GetPayoutAccountsResponse>(
      "/getPayoutAccounts",
      req
    );
    return response.data;
  };
  const getPayoutAccountDetail = async (req: IdRequest) => {
    const response = await baseService.post<GetPayoutAccountDetailResponse>(
      "/getPayoutAccountDetail",
      req
    );
    return response.data;
  };

  const exportPayoutsAsCSV = async (
    req: GetPayoutsRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportPayoutsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_PAYOUTS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const exportPayoutAcccountsAsCSV = async (
    req: GetPayoutAccountsRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportPayoutAccountsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_PAYOUT_ACCOUNTS_${new Date()
            .toISOString()
            .replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    getPayoutDetail,
    updatePayout,
    updatePayoutBulk,
    getPayoutAccounts,
    getPayoutAccountDetail,
    exportPayoutsAsCSV,
    exportPayoutAcccountsAsCSV,
  };
};
