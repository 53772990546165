import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatNumber } from "helpers";
import PercentageCell from "components/CustomDataGrid/Components/PercentageCell/PercentageCell";

const adAnalyticsView: CustomDataGridViewDto = {
  viewName: "Ad Analytics",
  id: "ad-analytics",
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "__check__":
        break;
      default:
        navigate(`campaigns-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "name"] } },
    columns: [
      {
        field: "name",
        headerName: "Campaign",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.client.logo
                ? {
                    rounded: "standard",
                    value: params.row.client.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "id",
        headerName: "Id",
        width: 60,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "owner",
        headerName: "Owner",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.fullName}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "budget",
        headerName: "Budget",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "revenue",
        headerName: "Revenue",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "cpm",
        headerName: "CPM",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "influencerCpms",
        headerName: "Influencer CPM",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "influencerRevenues",
        headerName: "Influencer Spend",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "profit",
        headerName: "Profit",
        width: 150,
        editable: false,
      },
      {
        field: "viewsProgress",
        headerName: "Views Progress",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return (
            <PercentageCell
              value={Math.round(
                (params.row.viewsRealized / params.row.viewsGoal) * 100
              )}
            />
          );
        },
      },
      {
        field: "dailyViewsProgress",
        headerName: "Daily Views Progress",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return (
            <PercentageCell
              value={Math.round(
                (params.row.viewsRealizedToday / params.row.dailyViewGoal) * 100
              )}
            />
          );
        },
      },
      {
        field: "viewsGoal",
        headerName: "Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "-",
      },
      {
        field: "viewsRealized",
        headerName: "Views Realized",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "dailyViewGoal",
        headerName: "Daily Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "viewsRealizedToday",
        headerName: "Views Realized Today",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "CTR",
        headerName: "CTR",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value} %` : "-",
      },
      {
        field: "hourlyViewGoal",
        headerName: "Hourly Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${formatNumber(params.value)}` : "-",
      },
      {
        field: "automationGoal",
        headerName: "Automation Goal",
        width: 150,
        editable: false,
      },
      {
        field: "startDate",
        headerName: "Start Date",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.value).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }),
      },
      {
        field: "endDate",
        headerName: "End Date",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.value).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }),
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default adAnalyticsView;
