import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import { formatLink } from "helpers";

const interactiveTriggersPopupView: CustomDataGridViewDto = {
  viewName: "Interactive Triggers Popup",
  id: "interactive-triggers-popup",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      default:
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    getRowHeight: (params) => {
      if (params.model?.name?.length > 50) return "auto";
    },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 100,
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "asset",
        headerName: "Asset",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "assetGif",
        headerName: "Asset GIF",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "assetHtml",
        headerName: "Asset HTML",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "assetType",
        headerName: "Asset Type",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "pretext",
        headerName: "Pretext",
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "redirectLink",
        headerName: "Redirect Link",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "duration",
        headerName: "Duration",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "topPadding",
        headerName: "Top Padding",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "leftPadding",
        headerName: "Left Padding",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "width",
        headerName: "Width",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
    ],
    rowHeight: 40,
    density: "compact",
    hideFooter: true,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default interactiveTriggersPopupView;
