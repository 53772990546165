import { Navigate, useParams } from "react-router-dom";

//POPUPS
import SidePopup from "components/SidePopup/SidePopup";
import AdsPopup from "popups/AdsPopup/AdsPopup";
import CampaignsPopup from "popups/CampaignsPopup/CampaignsPopup";
import StreamersPopup from "popups/StreamersPopup/StreamersPopup";
import PayoutAccountsPopup from "popups/PayoutAccountsPopup/PayoutAccountsPopup";
import InteractiveTriggersPopup from "popups/InteractiveTriggersPopup/InteractiveTriggersPopup";
import GameEventsPopup from "popups/GameEventsPopup/GameEventsPopup";
import PayoutsPopup from "popups/PayoutsPopup/PayoutsPopup";
import ExportCsvPopup from "popups/ExportCsvPopup/ExportCsvPopup";

const PopupWrapper = () => {
  const { popupName } = useParams();
  if (popupName === "ads-popup")
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <AdsPopup />
      </SidePopup>
    );
  if (popupName === "campaigns-popup")
    return (
      <SidePopup popupRoute={popupName} width={"95%"}>
        <CampaignsPopup />
      </SidePopup>
    );
  if (popupName === "streamer-popup")
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <StreamersPopup />
      </SidePopup>
    );
  if (popupName === "payout-accounts-popup")
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <PayoutAccountsPopup />
      </SidePopup>
    );
  if (popupName === "interactive-triggers-popup")
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <InteractiveTriggersPopup />
      </SidePopup>
    );
  if (popupName === "game-events-popup")
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <GameEventsPopup />
      </SidePopup>
    );
  if (popupName === "payouts-popup") {
    return (
      <SidePopup popupRoute={popupName} width="95%">
        <PayoutsPopup />
      </SidePopup>
    );
  }
  if (popupName === "export-csv-popup") {
    return (
      <SidePopup popupRoute={popupName} width="50%">
        <ExportCsvPopup />
      </SidePopup>
    );
  }
  return <Navigate to="/" />;
};

export default PopupWrapper;
