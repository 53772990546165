import IdRequest from "model/request/idRequest";
import { createBaseApiService } from "./baseApiService";
import GetCampaignRequest from "model/request/campaign/getCampaignRequest";
import GetCampaignResponse from "model/response/campaign/getCampaignResponse";
import GetCampaignDetailResponse from "model/response/campaign/getCampaignDetailResponse";
import GetCampaignBusinessSettingsResponse from "model/response/campaign/getCampaignBusinessSettingsResponse";
import UpdateResponse from "model/response/updateResponse";
import EditCampaignBusinessSettingsRequest from "model/request/campaign/editCampaignBusinessSettingsRequest";
import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";
import EditCampaignPostReviewSettingsRequest from "model/request/campaign/editCampaignPostReviewSettingsRequest";
import GetCampaignAdDisplaySettingsResponse from "model/response/campaign/getCampaignAdDisplaySettingsResponse";
import axios from "axios";
import GetCampaignAssetUploadUrlResponse from "model/response/campaign/getCampaignAssetUploadUrlResponse";
import CampaignAssetDto from "model/dto/campaign/campaignAssetDto";
import UpdateCampaignAssetUrlRequest from "model/request/campaign/updateCampaignAssetUrlRequest";
import GetCampaignAssetUploadUrlRequest from "model/request/campaign/getCampaignAssetUploadUrlRequest";
import EditCampaignAdDisplaySettingsRequest from "model/request/campaign/editCampaignAdDisplaySettingsRequest";
import GetCampaignChatMessageCountByKeywordsRequest from "model/request/campaign/getCampaignChatMessageCountByKeywordsRequest";
import GetCampaignChatMessageCountByKeywordsResponse from "model/response/campaign/getCampaignChatMessageCountByKeywordsResponse";
import GetCampaignChatMessagesByKeywordRequest from "model/request/campaign/getCampaignChatMessagesByKeywordRequest";
import GetCampaignChatMessagesByKeywordResponse from "model/response/campaign/getCampaignChatMessagesByKeywordResponse";
import GetCampaignKeywordsResponse from "model/response/campaign/getCampaignKeywordsResponse";
import GetCampaignAdFormSettingsResponse from "model/response/campaign/getCampaignAdFormSettingsResponse";
import EditCampaignAdFormSettingsRequest from "model/request/campaign/editCampaignAdFormSettingsRequest";
import GetCampaignScreenshotsRequest from "model/request/campaign/getCampaignScreenshotsRequest";
import GetCampaignScreenshotsResponse from "model/response/campaign/getCampaignScreenshotsResponse";
import EditCampaignScreenshotsRequest from "model/request/campaign/editCampaignScreenshotsRequest";
import GetCampaignRequestedStreamersRequest from "model/request/campaign/getCampaignRequestedStreamersRequest";
import GetCampaignRequestedStreamersResponse from "model/response/campaign/getCampaignRequestedStreamersResponse";
import GetCampaignOtherStreamersRequest from "model/request/campaign/getCampaignOtherStreamersRequest";
import GetCampaignOtherStreamersResponse from "model/response/campaign/getCampaignOtherStreamersResponse";
import AddStreamersToCampaignFromTargetingResponse from "model/response/campaign/addStreamersToCampaignFromTargetingResponse";
import AddStreamersToCampaignRequest from "model/request/campaign/addStreamersToCampaignRequest";
import EditCampaignRequestedStreamersRequest from "model/request/campaign/editCampaignRequestedStreamersRequest";
import { EnumRequestType } from "model/enum/enumRequestType";
import GetCampaignPostAdSettingsResponse from "model/response/campaign/getCampaignPostAdSettingsResponse";
import EditCampaignSettingsRequest from "model/request/campaign/editCampaignSettingsRequest";

export const createCampaignService = () => {
  const baseService = createBaseApiService("/campaigns");

  const get = async (req: GetCampaignRequest) => {
    const response = await baseService.post<GetCampaignResponse>("/get", req);
    return response.data;
  };

  const getDetail = async (req: IdRequest) => {
    const response = await baseService.post<GetCampaignDetailResponse>(
      `/getDetail`,
      req
    );
    return response.data;
  };
  const getBusinessSettings = async (req: IdRequest) => {
    const response =
      await baseService.post<GetCampaignBusinessSettingsResponse>(
        `/getBusinessSettings`,
        req
      );
    return response.data;
  };

  const editBusinessSettings = async (
    req: EditCampaignBusinessSettingsRequest
  ) => {
    const response = await baseService.post<UpdateResponse>(
      `/editBusinessSettings`,
      req
    );
    return response.data;
  };

  const getPostReviewSettings = async (req: IdRequest) => {
    const response =
      await baseService.post<GetCampaignPostReviewSettingsResponse>(
        `/getPostReviewSettings`,
        req
      );
    return response.data;
  };

  const editPostReviewSettings = async (
    req: EditCampaignPostReviewSettingsRequest
  ) => {
    const response = await baseService.post<UpdateResponse>(
      `/editPostReviewSettings`,
      req
    );
    return response.data;
  };

  const getAdDisplaySettings = async (req: IdRequest) => {
    const response =
      await baseService.post<GetCampaignAdDisplaySettingsResponse>(
        `/getAdDisplaySettings`,
        req
      );
    return response.data;
  };

  const editAdDisplaySettings = async (
    req: EditCampaignAdDisplaySettingsRequest
  ) => {
    const response = await baseService.post<UpdateResponse>(
      `/editAdDisplaySettings`,
      req
    );
    return response.data;
  };

  const getAdFormSettings = async (req: IdRequest) => {
    const response = await baseService.post<GetCampaignAdFormSettingsResponse>(
      `/getAdFormSettings`,
      req
    );
    return response.data;
  };

  const editAdFormSettings = async (req: EditCampaignAdFormSettingsRequest) => {
    const response = await baseService.post<UpdateResponse>(
      `/editAdFormSettings`,
      req
    );
    return response.data;
  };

  const getScreenshots = async (req: GetCampaignScreenshotsRequest) => {
    const response = await baseService.post<GetCampaignScreenshotsResponse>(
      `/getCampaignScreenshots`,
      req
    );
    return response.data;
  };

  const editScreenshots = async (req: EditCampaignScreenshotsRequest) => {
    const response = await baseService.post<UpdateResponse>(
      `/editCampaignScreenshots`,
      req
    );
    return response.data;
  };

  const getCampaignAssetUploadUrl = async (
    req: GetCampaignAssetUploadUrlRequest
  ): Promise<GetCampaignAssetUploadUrlResponse> => {
    const response = await baseService.post<GetCampaignAssetUploadUrlResponse>(
      "/getAssetUploadUrl",
      req
    );
    return response.data;
  };

  const updateCampaignAssetUrl = async (
    req: UpdateCampaignAssetUrlRequest
  ): Promise<CampaignAssetDto> => {
    const response = await baseService.post<CampaignAssetDto>(
      "/updateAssetUrl",
      req
    );
    return response.data;
  };

  const uploadAsset = async (
    campaignId: number,
    file: File,
    progressHandler: (progress: number) => void,
    duration?: number
  ): Promise<string> => {
    progressHandler(0);
    const { saasUrl, fullBlobUrl } = await getCampaignAssetUploadUrl({
      id: campaignId,
      fileType: file.type,
    });

    // Directly upload the file using the SAS URL
    const response = await axios.put(saasUrl, file, {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": file.type, // Use the file's MIME type
      },
      onUploadProgress: (event) => {
        if (event.loaded !== undefined && event.total !== undefined) {
          let progress = Math.round((event.loaded / event.total) * 100);
          // Artificially adjust progress to slow down as it nears completion
          if (progress > 95) progress = 95 + (progress - 95) / 2;
          progressHandler(progress);
        }
      },
      withCredentials: true,
    });

    return fullBlobUrl;
  };

  const getCampaignChatMessageCountByKeywords = async (
    req: GetCampaignChatMessageCountByKeywordsRequest
  ): Promise<GetCampaignChatMessageCountByKeywordsResponse> => {
    const response =
      await baseService.post<GetCampaignChatMessageCountByKeywordsResponse>(
        "/getChatMessageCountByKeywords",
        req
      );
    return response.data;
  };

  const getCampaignChatMessagesByKeyword = async (
    req: GetCampaignChatMessagesByKeywordRequest
  ): Promise<GetCampaignChatMessagesByKeywordResponse> => {
    const response =
      await baseService.post<GetCampaignChatMessagesByKeywordResponse>(
        "/getChatMessagesByKeyword",
        req
      );
    return response.data;
  };

  const getCampaignKeywords = async (
    req: IdRequest
  ): Promise<GetCampaignKeywordsResponse> => {
    const response = await baseService.post<GetCampaignKeywordsResponse>(
      "/getKeywords",
      req
    );
    return response.data;
  };

  const getCampaignRequestedStreamers = async (
    req: GetCampaignRequestedStreamersRequest
  ): Promise<GetCampaignRequestedStreamersResponse> => {
    const response =
      await baseService.post<GetCampaignRequestedStreamersResponse>(
        "/getRequestedStreamers",
        req
      );
    return response.data;
  };

  const getCampaignOtherStreamers = async (
    req: GetCampaignOtherStreamersRequest
  ): Promise<GetCampaignOtherStreamersResponse> => {
    const response = await baseService.post<GetCampaignOtherStreamersResponse>(
      "/getOtherStreamers",
      req
    );
    return response.data;
  };

  const addStreamersToCampaignFromTargeting = async (
    req: IdRequest
  ): Promise<AddStreamersToCampaignFromTargetingResponse> => {
    const response =
      await baseService.post<AddStreamersToCampaignFromTargetingResponse>(
        `/addStreamersToCampaignFromTargeting`,
        req
      );
    return response.data;
  };

  const addStreamersToCampaign = async (
    req: AddStreamersToCampaignRequest
  ): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(
      `/addStreamersToCampaign`,
      req
    );
    return response.data;
  };

  const editRequestedStreamers = async (
    req: EditCampaignRequestedStreamersRequest
  ): Promise<UpdateResponse> => {
    const response = await baseService.post<UpdateResponse>(
      `/editRequestedStreamers`,
      req
    );
    return response.data;
  };

  const exportCampaignsAsCSV = async (
    req: GetCampaignRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportCampaignsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_CAMPAIGNS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const getPostAdSettings = async (
    req: IdRequest
  ): Promise<GetCampaignPostAdSettingsResponse> => {
    const response = await baseService.post<GetCampaignPostAdSettingsResponse>(
      `/getPostAdSettings`,
      req
    );
    return response.data;
  };

  const editCampaignSettingsBulk = async (
    req: EditCampaignSettingsRequest[]
  ) => {
    const response = await baseService.post<UpdateResponse>(
      `/editCampaignsBulk`,
      req
    );
    return response.data;
  };

  return {
    get,
    getDetail,
    getBusinessSettings,
    editBusinessSettings,
    getPostReviewSettings,
    editPostReviewSettings,
    getAdDisplaySettings,
    editAdDisplaySettings,
    getAdFormSettings,
    editAdFormSettings,
    getScreenshots,
    editScreenshots,
    uploadAsset,
    updateCampaignAssetUrl,
    getCampaignChatMessageCountByKeywords,
    getCampaignChatMessagesByKeyword,
    getCampaignKeywords,
    getCampaignRequestedStreamers,
    getCampaignOtherStreamers,
    addStreamersToCampaignFromTargeting,
    addStreamersToCampaign,
    editRequestedStreamers,
    exportCampaignsAsCSV,
    getPostAdSettings,
    editCampaignSettingsBulk,
  };
};
