import {
  DataGridPro,
  DataGridProProps,
  GridApiPro,
  GridCallbackDetails,
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
//STYLES
import styles from "./CustomDataGrid.module.scss";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";

//CONTEXT
import { useFilterContext } from "context/FilterContext";
import { useCallback, useEffect, useRef, useState } from "react";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked": {
            color: "#5d7fff",
          },
          "&.Mui-checked:hover": {
            backgroundColor: "rgba(93,127,255,0.10)",
          },
          "&:hover": {
            backgroundColor: "rgba(93,127,255,0.10)",
          },
        },
      },
    },
  },
});

interface CustomDataGridProps {
  view: CustomDataGridViewDto;
  rows: GridRowsProp;
  isLoading?: boolean;
  rowCount: number;
  minHeight?: string;
  height?: string;
  maxHeight?: string;
  checkboxSelection?: DataGridProProps["checkboxSelection"];
  apiRef?: React.MutableRefObject<GridApiPro>;
  pageSizeOptions?: number[];
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void;
  rowSelectionModel?: GridRowSelectionModel;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
}

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  view,
  rows,
  isLoading,
  rowCount,
  height,
  minHeight,
  maxHeight,
  checkboxSelection,
  apiRef,
  pageSizeOptions,
  paginationModel,
  onPaginationModelChange,
  rowSelectionModel,
  onRowSelectionModelChange,
}) => {
  const { gridView } = view;
  const { setQuery } = useFilterContext();
  const location = useLocation();
  const navigate = useNavigate();
  const memoizedGetDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(gridView.getDetailPanelContent!, []);
  const memoizedGetDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(gridView.getDetailPanelHeight!, []);
  return (
    <div
      style={{ minHeight: minHeight, maxHeight: maxHeight, height: height }}
      className={styles.dataGrid}
    >
      <ThemeProvider theme={darkTheme}>
        <DataGridPro
          {...gridView}
          apiRef={apiRef}
          rows={rows}
          columns={gridView.columns}
          sx={{
            "& .MuiDataGrid-cell": {
              cursor: "pointer",
            },
            "--DataGrid-pinnedBackground": {
              color: "#fece1a",
            },
          }}
          disableColumnPinning={gridView.disableColumnPinning ?? false}
          rowHeight={gridView.rowHeight ?? 52}
          initialState={gridView.initialState}
          density={gridView.density ?? "standard"}
          getDetailPanelContent={memoizedGetDetailPanelContent ?? undefined}
          getDetailPanelHeight={memoizedGetDetailPanelHeight ?? undefined}
          rowCount={rowCount}
          loading={isLoading}
          pagination={true}
          pageSizeOptions={pageSizeOptions ?? [50]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          disableRowSelectionOnClick={
            gridView.disableRowSelectionOnClick ?? true
          }
          onRowClick={(params) => {
            if (view.onRowClick) {
              setQuery(location.search);
              view.onRowClick(params, navigate);
            }
          }}
          onCellClick={(params, event) => {
            if (view.onCellClick) {
              setQuery(location.search);
              view.onCellClick(params, event, navigate);
            }
          }}
          onCellDoubleClick={(params, event) => {
            if (view.onCellDoubleClick) {
              setQuery(location.search);
              view.onCellDoubleClick(params, event, navigate);
            }
          }}
          checkboxSelection={checkboxSelection ?? undefined}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
        />
      </ThemeProvider>
    </div>
  );
};

export default CustomDataGrid;
