import { useEffect, useRef } from "react";
//STYLES
import styles from "./PayoutAccounts.module.scss";

//CONFIG
import payoutAccountsViews from "./config/views";
//CONTEXT
import { usePayoutAccountsContext } from "context/PayoutAccountsContext";
import { useFilterContext } from "context/FilterContext";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useSearchParams } from "react-router-dom";

const PayoutAccounts = () => {
  document.title = "Payout Accounts - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchPayoutAccounts,
    gridIsLoading,
  } = usePayoutAccountsContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchPayoutAccounts();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchPayoutAccounts();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.payoutAccounts}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M47.5 104H432V51.52a16 16 0 0 0-19.14-15.69l-368 60.48a16 16 0 0 0-12 10.47A39.69 39.69 0 0 1 47.5 104zm416 24h-416a16 16 0 0 0-16 16v288a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V144a16 16 0 0 0-16-16zM368 320a32 32 0 1 1 32-32 32 32 0 0 1-32 32z" />
            <path d="M31.33 259.5V116c0-12.33 5.72-18.48 15.42-20 35.2-5.53 108.58-8.5 108.58-8.5s-8.33 16-27.33 16V128c18.5 0 31.33 23.5 31.33 23.5L84.83 236z" />
          </svg>
          Payout Accounts
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchPayoutAccounts}
        from="payout-accounts"
        export={true}
        clear={true}
      />
      {payoutAccountsViews.length > 1 && (
        <CustomSelect
          views={payoutAccountsViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          payoutAccountsViews.find((view) => view.id === activeView) ??
          payoutAccountsViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default PayoutAccounts;
