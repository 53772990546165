import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import EnumPayingEntity from "model/enum/enumPayingEntity";
import EnumPaymentMethod from "model/enum/enumPaymentMethod";
import EnumPayoutAccountStatus from "model/enum/enumPayoutAccountStatus";

const payoutAccountFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Status",
    tooltip: "Payout Account status",
    results: Object.entries(EnumPayoutAccountStatus).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    multiSelect: true,
    fieldName: "status",
    style: "select",
  },
  {
    type: "searchable",
    title: "Billing Country",
    tooltip: "Billing Country name",
    results: [],
    filterType: EnumFilterType.Country,
    fieldName: "countryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "static",
    title: "Payment Method",
    tooltip: "Payment Method of the Payout Account",
    results: Object.entries(EnumPaymentMethod).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "paymentMethod",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Paying Entity",
    tooltip: "Payment Entity of the Payout Account",
    results: Object.entries(EnumPayingEntity).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "payingEntity",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Historical Data",
    tooltip: "Get all Payout Accounts of the Streamer",
    results: [{ label: "isHistorical", value: 1, selected: false }],
    filterType: null,
    fieldName: "isHistorical",
    multiSelect: true,
    style: "toggle",
  },
];

export default payoutAccountFilterOptions;
