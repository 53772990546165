import { useEffect, useRef } from "react";
//STYLES
import styles from "./InteractiveTriggers.module.scss";

//CONFIG
import interactiveTriggerViews from "./config/views";
//CONTEXT
import { useInteractiveTriggersContext } from "context/InteractiveTriggersContext";
import { useFilterContext } from "context/FilterContext";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useSearchParams } from "react-router-dom";

const InteractiveTriggers = () => {
  document.title = "Interactive Triggers - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchInteractiveTriggers,
    gridIsLoading,
  } = useInteractiveTriggersContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchInteractiveTriggers();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchInteractiveTriggers();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.interactiveTriggers}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            version="1.1"
            viewBox="0 0 17 17"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g />
            <path d="M17 0v17h-5v-17h5zM9 17h1v-1h-1v1zM7.223 17h0.889v-1h-0.889v1zM1.889 17h0.889v-1h-0.889v1zM3.667 17h0.889v-1h-0.889v1zM5.445 17h0.889v-1h-0.889v1zM0 17h1v-1h-1v1zM0 9h1v-1h-1v1zM0 3h1v-1h-1v1zM0 11h1v-1h-1v1zM0 7h1v-1h-1v1zM0 13h1v-1h-1v1zM0 15h1v-1h-1v1zM0 5h1v-1h-1v1zM0 1h1v-1h-1v1zM8.111 0h-0.889v1h0.889v-1zM6.333 0h-0.889v1h0.889v-1zM2.777 0h-0.888v1h0.889v-1zM4.555 0h-0.888v1h0.889v-1zM9 1h1v-1h-1v1zM9 15h1v-1h-1v1zM9 13h1v-1h-1v1zM9 9h1v-1h-1v1zM9 11h1v-1h-1v1zM9 5h1v-1h-1v1zM9 7h1v-1h-1v1zM9 3h1v-1h-1v1z" />
          </svg>
          Interactive Triggers
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchInteractiveTriggers}
        export={true}
        from="interactive-triggers"
        clear={true}
      />
      {interactiveTriggerViews.length > 1 && (
        <CustomSelect
          views={interactiveTriggerViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          interactiveTriggerViews.find((view) => view.id === activeView) ??
          interactiveTriggerViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default InteractiveTriggers;
