import { toast } from "react-toastify";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GridRowsProp } from "@mui/x-data-grid-pro";
import { useFilterContext } from "./FilterContext";
import { createCampaignService } from "services/campaignService";
import gridViews from "pages/Campaigns/config/views";
import EditCampaignSettingsRequest from "model/request/campaign/editCampaignSettingsRequest";
import UpdateResponse from "model/response/updateResponse";

interface CampaignsContextProps {
  children: React.ReactNode;
}

type CampaignsContextInterface = {
  activeView: string;
  changeActiveView: (view: string) => void;
  gridRows: GridRowsProp;
  gridRowCount: number;
  gridIsLoading: boolean;
  fetchCampaigns: () => void;
  exportCampaigns: (rowCount: number, fileName?: string) => Promise<void>;
  editCampaignsBulk: (
    ids: number[],
    editCampaignsData: Partial<EditCampaignSettingsRequest>
  ) => Promise<UpdateResponse>;
};

export const CampaignsContext = createContext<
  CampaignsContextInterface | undefined
>(undefined);

export const useCampaignsContext = () => {
  const context = useContext(CampaignsContext);
  if (context === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error(
      "useCampaignsContext must be used within a CampaignsProvider"
    );
  }
  const filterContext = useFilterContext();
  if (filterContext === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const CampaignsProvider = ({ children }: CampaignsContextProps) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeView, setActiveView] = useState("ad-analytics");
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [gridIsLoading, setGridIsLoading] = useState(false);
  const [gridRowCount, setGridRowCount] = useState(0);
  const { filterList, paginationModel } = useFilterContext();

  const campaignService = createCampaignService();

  const setQueryParams = () => {
    const selectedFilters: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        selectedFilters[filterItem.fieldName] = selectedResults.map(
          (result) => {
            return { label: result.label, value: result.value };
          }
        );
      }
    });
    if (Object.keys(selectedFilters).length === 0) {
      setSearchParams((prevSearchParams) => {
        const searchParams = new URLSearchParams(prevSearchParams);
        searchParams.delete("filters");
        return searchParams;
      });
      return;
    }
    const filtersString: string = encodeURIComponent(
      JSON.stringify(selectedFilters)
    );
    setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.set("filters", filtersString);
      return searchParams;
    });
  };

  const fetchCampaigns = async () => {
    setInitialLoading(false);
    setQueryParams();
    const postBody: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          postBody[filterItem.fieldName] = selectedResults.map(
            (result) => result.value
          );
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    postBody.take = paginationModel.pageSize;
    postBody.skip = paginationModel.page * paginationModel.pageSize;
    try {
      setGridIsLoading(true);
      const response = await campaignService.get(postBody);
      setGridRows(response.data);
      setGridRowCount(response.rowCount);
      setGridIsLoading(false);
    } catch (err) {
      setGridIsLoading(false);
      toast.error("An error occurred while fetching the data");
    }
  };

  const exportCampaigns = async (rowCount: number, fileName?: string) => {
    const postBody: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          postBody[filterItem.fieldName] = selectedResults.map(
            (result) => result.value
          );
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    postBody.take = rowCount;
    try {
      toast.promise(campaignService.exportCampaignsAsCSV(postBody, fileName), {
        pending: "Generating the export file",
        success:
          "The export file has been generated, it will start downloading shortly",
        error: "An error occurred while generating the export file",
      });
    } catch (err) {
      toast.error("An error occurred while generating the export file");
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/campaigns") {
      const view = searchParams.get("view") ?? "";
      const viewExist = gridViews.some((gridView) => gridView.id === view);
      setActiveView(viewExist ? view : gridViews[0].id);
      // if (!viewExist) {
      //   setSearchParams((prevSearchParams) => {
      //     const searchParams = new URLSearchParams(prevSearchParams);
      //     searchParams.set("view", gridViews[0].id);
      //     return searchParams;
      //   });
      // }
    }
  }, []);

  useEffect(() => {
    if (initialLoading) return;
    fetchCampaigns();
  }, [paginationModel]);

  const changeActiveView = (view: string) => {
    setActiveView(view);
    setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.set("view", view);
      return searchParams;
    });
  };

  const editCampaignsBulk = async (
    ids: number[],
    editCampaignsData: Partial<EditCampaignSettingsRequest>
  ) => {
    setGridIsLoading(true);
    const postBody= ids.map((id) => ({ id, ...editCampaignsData }));
    try {
      const res = await campaignService.editCampaignSettingsBulk(postBody);
      toast.success("Campaign(s) updated successfully");
      await fetchCampaigns();
      setGridIsLoading(false);
      return res;
    } catch (error: any){
      setGridIsLoading(false);
      toast.error(error.response.data.message ? error.response.data.message : "An error occurred while updating the data");
      return {success: false, message: "An error occurred while updating the data"};
    }
  };

  const contextValue = {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    gridIsLoading,
    fetchCampaigns,
    exportCampaigns,
    editCampaignsBulk,
  };
  return (
    <CampaignsContext.Provider value={contextValue}>
      {children}
    </CampaignsContext.Provider>
  );
};
