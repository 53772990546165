import FilterItemDto from "model/dto/filterItemDto";

const screenshotsFilterOptions: FilterItemDto[] = [
  {
    type: "static",
    title: "Is Approved",
    results: [
      { label: "Approved", value: 1, selected: false },
      { label: "Rejected", value: 0, selected: false },
      { label: "Not Labeled", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "isApproved",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Auto Validity",
    results: [
      { label: "Is Valid", value: true, selected: false },
      { label: "Is Not Valid", value: false, selected: false },
    ],
    filterType: null,
    fieldName: "screenshotApprovedAutomated",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Type",
    results: [
      { label: "Screenshot", value: "screenshot", selected: true },
      { label: "Gif", value: "gif", selected: false },
    ],
    filterType: null,
    fieldName: "type",
    multiSelect: false,
    style: "select",
  },
];

export default screenshotsFilterOptions;
