import GetClientsRequest from "model/request/clients/getClientsRequest";
import { createBaseApiService } from "./baseApiService";
import GetClientsResponse from "model/response/clients/getClientsResponse";
import { EnumRequestType } from "model/enum/enumRequestType";

export const createClientsService = () => {
  const baseService = createBaseApiService("/clients");

  const get = async (req: GetClientsRequest) => {
    const response = await baseService.post<GetClientsResponse>("/get", req);
    return response.data;
  };

  const exportClientsAsCSV = async (
    req: GetClientsRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportClientsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_CLIENTS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  return {
    get,
    exportClientsAsCSV,
  };
};
