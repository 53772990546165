import { toast } from "react-toastify";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GridRowsProp } from "@mui/x-data-grid-pro";

//CONTEXTS
import { useFilterContext } from "./FilterContext";

//CONFIGS
import gridViews from "pages/Agencies/config/views";

//SERVICES
import { createAgencyService } from "services/agencyService";

//MODELS
interface AgenciesContextProps {
  children: React.ReactNode;
}

type AgenciesContextInterface = {
  activeView: string;
  changeActiveView: (view: string) => void;
  gridRows: GridRowsProp;
  gridRowCount: number;
  gridIsLoading: boolean;
  fetchAgencies: () => void;
  exportAgencies: (rowCount: number, fileName?: string) => Promise<void>;
};

export const AgenciesContext = createContext<
  AgenciesContextInterface | undefined
>(undefined);

export const useAgenciesContext = () => {
  const context = useContext(AgenciesContext);
  if (context === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error(
      "useAgenciesContext must be used within a AgenciesProvider"
    );
  }
  const filterContext = useFilterContext();
  if (filterContext === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const AgenciesProvider = ({ children }: AgenciesContextProps) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeView, setActiveView] = useState("agencies");
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [gridIsLoading, setGridIsLoading] = useState(false);
  const [gridRowCount, setGridRowCount] = useState(0);
  const { filterList, paginationModel } = useFilterContext();

  const agencyService = createAgencyService();

  const setQueryParams = () => {
    const selectedFilters: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        selectedFilters[filterItem.fieldName] = selectedResults.map(
          (result) => {
            return { label: result.label, value: result.value };
          }
        );
      }
    });
    if (Object.keys(selectedFilters).length === 0) {
      setSearchParams((prevSearchParams) => {
        const searchParams = new URLSearchParams(prevSearchParams);
        searchParams.delete("filters");
        return searchParams;
      });
      return;
    }
    const filtersString: string = encodeURIComponent(
      JSON.stringify(selectedFilters)
    );
    setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.set("filters", filtersString);
      return searchParams;
    });
  };

  const fetchAgencies = async () => {
    setInitialLoading(false);
    setQueryParams();
    const postBody: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          postBody[filterItem.fieldName] = selectedResults.map(
            (result) => result.value
          );
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    postBody.take = paginationModel.pageSize;
    postBody.skip = paginationModel.page * paginationModel.pageSize;
    try {
      setGridIsLoading(true);
      const response = await agencyService.get(postBody);
      setGridRows(response.data);
      setGridRowCount(response.rowCount);
      setGridIsLoading(false);
    } catch (err) {
      setGridIsLoading(false);
      toast.error("An error occurred while fetching the data");
    }
  };

  const exportAgencies = async (rowCount: number, fileName?: string) => {
    const postBody: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          postBody[filterItem.fieldName] = selectedResults.map(
            (result) => result.value
          );
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    postBody.take = rowCount;
    try {
      toast.promise(agencyService.exportAgeciesAsCSV(postBody, fileName), {
        pending: "Generating the export file",
        success:
          "The export file has been generated, it will start downloading shortly",
        error: "An error occurred while generating the export file",
      });
    } catch (err) {
      toast.error("An error occurred while generating the export file");
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/agencies") {
      const view = searchParams.get("view") ?? "";
      const viewExist = gridViews.some((gridView) => gridView.id === view);
      setActiveView(viewExist ? view : gridViews[0].id);
      // if (!viewExist) {
      //   setSearchParams((prevSearchParams) => {
      //     const searchParams = new URLSearchParams(prevSearchParams);
      //     searchParams.set("view", gridViews[0].id);
      //     return searchParams;
      //   });
      // }
    }
  }, []);

  useEffect(() => {
    if (initialLoading) return;
    fetchAgencies();
  }, [paginationModel]);

  const changeActiveView = (view: string) => {
    setActiveView(view);
    setSearchParams((prevSearchParams) => {
      const searchParams = new URLSearchParams(prevSearchParams);
      searchParams.set("view", view);
      return searchParams;
    });
  };

  const contextValue = {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    gridIsLoading,
    fetchAgencies,
    exportAgencies,
  };
  return (
    <AgenciesContext.Provider value={contextValue}>
      {children}
    </AgenciesContext.Provider>
  );
};
