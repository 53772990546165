import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatNumber } from "helpers";
const gameEventsView: CustomDataGridViewDto = {
  viewName: "Game Events",
  id: "game-events",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      default:
        navigate(`game-events-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={
                params.value?.charAt(0).toUpperCase() + params.value?.slice(1)
              }
              status={params.value}
            />
          ) : (
            "-"
          ),
      },
      {
        field: "name",
        headerName: "Event",
        resizable: true,
        width: 250,
      },
      {
        field: "clientName",
        headerName: "Brand",
        resizable: true,
        width: 250,
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "eventDate",
        headerName: "Event Date",
        resizable: true,
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? new Date(params.value).toLocaleString() : "-",
      },
      {
        field: "joinedFansCount",
        headerName: "Joined Fans",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value || params.value === 0
            ? formatNumber(params.value)
            : "-";
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default gameEventsView;
