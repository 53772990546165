import { useEffect, useState } from "react";

//STYLES
import styles from "./MainSettings.module.scss";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";
import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import CustomDateInput from "components/CustomForm/Components/CustomDateInput/CustomDateInput";
import NullableFormItem from "components/CustomForm/Components/NullableFormItem";
import LockToggleButton from "components/LockToggleButton/LockToggleButton";

//MODELS
import EnumCategoryTargetingType from "model/enum/enumCategoryTargetingType";
import LanguageTargeting from "./Components/LanguageTargeting/LanguageTargeting";
import CountryTargeting from "./Components/CountryTargeting/CountryTargeting";
import SimpleSelectInput from "components/Shared/SimpleSelectInput/SimpleSelectInput";
import CategoryTargeting from "./Components/CategoryTargeting/CategoryTargeting";
import ExcludedCategoryTargeting from "./Components/ExcludedCategoryTargeting/ExcludedCategoryTargeting";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import CampaignAssetPreview from "components/Shared/CampaignAssetPreview/CampaignAssetPreview";
import StreamerTargeting from "./Components/StreamerTargeting/StreamerTargeting";
import EnumCampaignObjective from "model/enum/enumCampaignObjective";
import EnumCampaignFormatType from "model/enum/enumCampaignFormatType";
import GetCampaignBusinessSettingsResponse, {
  Answer,
} from "model/response/campaign/getCampaignBusinessSettingsResponse";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import EditCampaignBusinessSettingsRequest from "model/request/campaign/editCampaignBusinessSettingsRequest";
import { toast } from "react-toastify";
import CampaignPollPreview from "components/Shared/CampaignPollPreview/CampaignPollPreview";
import EnumCampaignType from "model/enum/enumCampaignType";
import EnumAssetType from "model/enum/enumAssetType";

interface MainSettingsProps {
  setReloadData: (value: boolean) => void;
}

const MainSettings = ({ setReloadData }: MainSettingsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] =
    useState<GetCampaignBusinessSettingsResponse | null>(null);
  const [editedCampaign, setEditedCampaign] =
    useState<GetCampaignBusinessSettingsResponse | null>(null);
  const [diff, setDiff] =
    useState<Partial<GetCampaignBusinessSettingsResponse> | null>(null);
  const [viewInputIsLocked, setViewInputIsLocked] = useState(true);
  const [budgetInputIsLocked, setBudgetInputIsLocked] = useState(false);
  const [cpmInputIsLocked, setCpmInputIsLocked] = useState(false);
  const [activeAsset, setActiveAsset] = useState<Answer | null>(null);

  const { id } = useParams();

  const campaignService = createCampaignService();

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const value =
      e.target.type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;
    if (editedCampaign) {
      setEditedCampaign({
        ...editedCampaign,
        [e.target.name]: value,
      });
    }
  };
  const handleFetchBusinessSettings = async () => {
    setIsLoading(true);
    const response = await campaignService.getBusinessSettings({
      id: id ? parseInt(id) : 0,
    });
    setCampaign(response);
    setEditedCampaign(response);
    setIsLoading(false);
  };

  useEffect(() => {
    handleFetchBusinessSettings();
  }, []);

  useEffect(() => {
    if (editedCampaign?.answers && editedCampaign?.answers.length > 0) {
      setActiveAsset(editedCampaign.answers[0]);
    }
  }, [editedCampaign]);

  const handleSave = async () => {
    if (!diff || !id) return;
    if (diff.viewsGoal === null || diff.budget === null || diff.CPM === null)
      return toast.error("Please fill viewsGoal, budget and CPM fields");
    setIsLoading(true);
    const postbody: EditCampaignBusinessSettingsRequest = {
      id: parseInt(id),
      name: diff.name,
      startDate: diff.startDate,
      endDate: diff.endDate,
      languageTargetingOn: diff.languageTargetingOn,
      languages: diff.languages?.map((language) => `${language.value}`),
      countryTargetingOn: diff.countryTargetingOn,
      countries: diff.countries?.map((country) => `${country.value}`),
      categoryTargetingOn: diff.categoryTargetingOn,
      categoryTargetingType: diff.categoryTargetingType,
      categories: diff.categories?.map((category) =>
        parseInt(`${category.value}`)
      ),
      excludedCategories: diff.excludedCategories?.map((category) =>
        parseInt(`${category.value}`)
      ),
      streamerTargetingOn: diff.streamerTargetingOn,
      streamers: diff.streamers?.map((streamer) => `${streamer.value}`),
      objective: diff.objective,
      formatType: diff.formatType,
      viewsGoal: diff.viewsGoal,
      budget: diff.budget,
      CPM: diff.CPM,
      pretext: diff.pretext,
      redirectLink: diff.redirectLink,
      brief: diff.brief,
    };
    const res = await campaignService.editBusinessSettings(postbody);
    if (res.success) {
      toast.success("Changes saved successfully");
      setReloadData(true);
    } else {
      setIsLoading(false);
      toast.error("Error saving changes");
    }
  };

  useEffect(() => {
    if (campaign && editedCampaign) {
      const changes: any = {};

      (
        Object.keys(
          editedCampaign
        ) as (keyof GetCampaignBusinessSettingsResponse)[]
      ).forEach((key) => {
        if (editedCampaign[key] != campaign[key]) {
          changes[key] = editedCampaign[key]!;
        }
      });

      setDiff(changes);
    } else {
      setDiff(null);
    }
  }, [campaign, editedCampaign]);

  const handleSetLockViewInput = (isLocked: boolean) => {
    if (isLocked) {
      setViewInputIsLocked(isLocked);
      setBudgetInputIsLocked(false);
      setCpmInputIsLocked(false);
    }
  };
  const handleSetLockBudgetInput = (isLocked: boolean) => {
    if (isLocked) {
      setBudgetInputIsLocked(isLocked);
      setViewInputIsLocked(false);
      setCpmInputIsLocked(false);
    }
  };
  const handleSetLockCpmInput = (isLocked: boolean) => {
    if (isLocked) {
      setCpmInputIsLocked(isLocked);
      setViewInputIsLocked(false);
      setBudgetInputIsLocked(false);
    }
  };

  if (!id) return null;
  if (isLoading || editedCampaign === null || campaign === null)
    return <Loading />;

  return (
    <div className={styles.mainSettings}>
      <CustomForm>
        <CustomFormItem title="Campaign Name">
          <input
            name="name"
            type="text"
            onChange={handleInputChange}
            value={editedCampaign.name}
          />
        </CustomFormItem>
        <CustomFormItem title="Start Date">
          <NullableFormItem<Date>
            value={editedCampaign.startDate}
            setValue={(value: Date | null) => {
              setEditedCampaign({
                ...editedCampaign,
                startDate: value,
              });
            }}
            defaultValue={new Date()}
          >
            {(value, setValue) => (
              <CustomDateInput
                value={value}
                setValue={setValue}
                includeTime={true}
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title="End Date">
          <NullableFormItem<Date>
            value={editedCampaign.endDate}
            setValue={(value: Date | null) => {
              setEditedCampaign({
                ...editedCampaign,
                endDate: value,
              });
            }}
            defaultValue={new Date()}
          >
            {(value, setValue) => (
              <CustomDateInput
                value={value}
                setValue={setValue}
                includeTime={true}
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <div className={styles.customSection}>
          <div className={styles.title}>
            Language Targeting
            <ToggleSwitch
              size="medium"
              value={editedCampaign.languageTargetingOn}
              setValue={() => {
                setEditedCampaign({
                  ...editedCampaign,
                  languageTargetingOn: !editedCampaign.languageTargetingOn,
                });
              }}
            />
          </div>
          {!!editedCampaign.languageTargetingOn && (
            <LanguageTargeting
              data={editedCampaign}
              setData={setEditedCampaign}
            />
          )}
        </div>
        <div className={styles.customSection}>
          <div className={styles.title}>
            Country Targeting
            <ToggleSwitch
              size="medium"
              value={editedCampaign.countryTargetingOn}
              setValue={() => {
                setEditedCampaign({
                  ...editedCampaign,
                  countryTargetingOn: !editedCampaign.countryTargetingOn,
                });
              }}
            />
          </div>
          {!!editedCampaign.countryTargetingOn && (
            <CountryTargeting
              data={editedCampaign}
              setData={setEditedCampaign}
            />
          )}
        </div>
        <div className={styles.customSection}>
          <div className={styles.title}>
            Category Targeting
            <ToggleSwitch
              size="medium"
              value={editedCampaign.categoryTargetingOn}
              setValue={() => {
                setEditedCampaign({
                  ...editedCampaign,
                  categoryTargetingOn: !editedCampaign.categoryTargetingOn,
                });
              }}
            />
          </div>
          {!!editedCampaign.categoryTargetingOn && (
            <SimpleSelectInput<EnumCategoryTargetingType>
              options={[
                { name: "Include", value: EnumCategoryTargetingType.Included },
                { name: "Exclude", value: EnumCategoryTargetingType.Excluded },
              ]}
              defaultValue={
                editedCampaign.categoryTargetingType ===
                EnumCategoryTargetingType.Included
                  ? {
                      name: "Include",
                      value: EnumCategoryTargetingType.Included,
                    }
                  : {
                      name: "Exclude",
                      value: EnumCategoryTargetingType.Excluded,
                    }
              }
              onSelect={(value) => {
                setEditedCampaign({
                  ...editedCampaign,
                  categoryTargetingType: value,
                });
              }}
            />
          )}
          {!!editedCampaign.categoryTargetingOn &&
            editedCampaign.categoryTargetingType ===
              EnumCategoryTargetingType.Included && (
              <CategoryTargeting
                data={editedCampaign}
                setData={setEditedCampaign}
              />
            )}
          {!!editedCampaign.categoryTargetingOn &&
            editedCampaign.categoryTargetingType ===
              EnumCategoryTargetingType.Excluded && (
              <ExcludedCategoryTargeting
                data={editedCampaign}
                setData={setEditedCampaign}
              />
            )}
        </div>
        <div className={styles.customSection}>
          <div className={styles.title}>
            Streamer Targeting
            <ToggleSwitch
              size="medium"
              value={editedCampaign.streamerTargetingOn}
              setValue={() => {
                setEditedCampaign({
                  ...editedCampaign,
                  streamerTargetingOn: !editedCampaign.streamerTargetingOn,
                });
              }}
            />
          </div>
          {!!editedCampaign.streamerTargetingOn && (
            <StreamerTargeting
              data={editedCampaign}
              setData={setEditedCampaign}
            />
          )}
        </div>
        <CustomFormItem title="Objective">
          <select
            name="objective"
            value={editedCampaign?.objective}
            onChange={handleInputChange}
          >
            {Object.entries(EnumCampaignObjective).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>
        </CustomFormItem>
        <CustomFormItem title="Format">
          <select
            name="formatType"
            value={editedCampaign?.formatType}
            onChange={handleInputChange}
          >
            <option value={""}>Select Format</option>
            {Object.entries(EnumCampaignFormatType).map(([key, value]) => (
              <option key={key} value={value}>
                {value
                  .split("_")
                  .map((word) => word[0].toUpperCase() + word.slice(1))
                  .join(" ")}
              </option>
            ))}
          </select>
        </CustomFormItem>
        <CustomFormItem title={"Views"}>
          <LockToggleButton
            isLocked={viewInputIsLocked}
            setIsLocked={handleSetLockViewInput}
          />
          <input
            name="viewsGoal"
            type="number"
            placeholder="Views"
            value={editedCampaign.viewsGoal ?? undefined}
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => {
              if (viewInputIsLocked) return;
              if (budgetInputIsLocked) {
                if (e.target.value === "") {
                  setEditedCampaign({
                    ...editedCampaign,
                    viewsGoal: null,
                    CPM: campaign.CPM,
                  });
                } else {
                  const CPM = editedCampaign.budget
                    ? Number(
                        (
                          (editedCampaign.budget * 1000) /
                          parseInt(e.target.value)
                        ).toFixed(2)
                      )
                    : campaign.CPM;
                  setEditedCampaign({
                    ...editedCampaign,
                    viewsGoal: parseInt(e.target.value),
                    CPM,
                  });
                }
              } else if (cpmInputIsLocked) {
                if (e.target.value === "") {
                  setEditedCampaign({
                    ...editedCampaign,
                    viewsGoal: null,
                    budget: campaign.budget,
                  });
                } else {
                  const budget = editedCampaign.CPM
                    ? Math.round(
                        (parseInt(e.target.value) * editedCampaign.CPM) / 1000
                      )
                    : campaign.budget;
                  setEditedCampaign({
                    ...editedCampaign,
                    viewsGoal: parseInt(e.target.value),
                    budget,
                  });
                }
              } else {
                setEditedCampaign({
                  ...editedCampaign,
                  viewsGoal: parseInt(e.target.value),
                });
              }
            }}
            disabled={viewInputIsLocked}
          />
        </CustomFormItem>
        <CustomFormItem title={`Total Budget`}>
          <LockToggleButton
            isLocked={budgetInputIsLocked}
            setIsLocked={handleSetLockBudgetInput}
          />
          <div content="currencySign">
            <span>{campaign?.currencySign}</span>
            <input
              name="budget"
              type="number"
              placeholder="Total Budget"
              value={editedCampaign.budget ? editedCampaign.budget : undefined}
              onWheel={(e) => e.currentTarget.blur()}
              onChange={(e) => {
                if (budgetInputIsLocked) return;
                if (viewInputIsLocked) {
                  if (e.target.value === "") {
                    setEditedCampaign({
                      ...editedCampaign,
                      budget: null,
                      CPM: campaign.CPM,
                    });
                  } else {
                    const CPM = editedCampaign.viewsGoal
                      ? parseFloat(
                          (
                            (parseInt(e.target.value) * 1000) /
                            editedCampaign.viewsGoal
                          ).toFixed(2)
                        )
                      : campaign.CPM;
                    setEditedCampaign({
                      ...editedCampaign,
                      budget: parseInt(e.target.value),
                      CPM,
                    });
                  }
                } else if (cpmInputIsLocked) {
                  if (e.target.value === "") {
                    setEditedCampaign({
                      ...editedCampaign,
                      viewsGoal: campaign.viewsGoal,
                      budget: null,
                    });
                  } else {
                    const viewsGoal = editedCampaign.CPM
                      ? Math.round(
                          (parseInt(e.target.value) * 1000) / editedCampaign.CPM
                        )
                      : campaign.viewsGoal;
                    setEditedCampaign({
                      ...editedCampaign,
                      budget: parseInt(e.target.value),
                      viewsGoal,
                    });
                  }
                } else {
                  setEditedCampaign({
                    ...editedCampaign,
                    budget: parseInt(e.target.value),
                  });
                }
              }}
              disabled={budgetInputIsLocked}
            />
          </div>
        </CustomFormItem>
        <CustomFormItem title={`CPM`}>
          <LockToggleButton
            isLocked={cpmInputIsLocked}
            setIsLocked={handleSetLockCpmInput}
          />
          <div content="currencySign">
            <span>{campaign?.currencySign}</span>
            <input
              name="CPM"
              type="number"
              placeholder="CPM"
              value={editedCampaign.CPM ? editedCampaign.CPM : undefined}
              onWheel={(e) => e.currentTarget.blur()}
              onChange={(e) => {
                if (cpmInputIsLocked) return;
                if (viewInputIsLocked) {
                  if (e.target.value === "") {
                    setEditedCampaign({
                      ...editedCampaign,
                      budget: campaign.budget,
                      CPM: null,
                    });
                  } else {
                    const budget = editedCampaign.viewsGoal
                      ? Math.round(
                          (editedCampaign.viewsGoal *
                            parseFloat(e.target.value)) /
                            1000
                        )
                      : campaign.budget;

                    setEditedCampaign({
                      ...editedCampaign,
                      CPM: parseFloat(e.target.value),
                      budget,
                    });
                  }
                } else if (budgetInputIsLocked) {
                  if (e.target.value === "") {
                    setEditedCampaign({
                      ...editedCampaign,
                      CPM: null,
                      viewsGoal: campaign.viewsGoal,
                    });
                  } else {
                    const viewsGoal = editedCampaign.budget
                      ? Math.round(
                          (editedCampaign.budget * 1000) /
                            parseFloat(e.target.value)
                        )
                      : campaign.viewsGoal;
                    setEditedCampaign({
                      ...editedCampaign,
                      CPM: parseFloat(e.target.value),
                      viewsGoal,
                    });
                  }
                } else {
                  setEditedCampaign({
                    ...editedCampaign,
                    CPM: parseFloat(e.target.value),
                  });
                }
              }}
              disabled={cpmInputIsLocked}
            />
          </div>
        </CustomFormItem>
        <CustomFormItem title={"Sponsor Message"}>
          <textarea
            name="pretext"
            placeholder="Sponsor Message"
            value={editedCampaign.pretext}
            onChange={handleInputChange}
          ></textarea>
        </CustomFormItem>
        <CustomFormItem title={"Landing Page URL"}>
          <input
            name="redirectLink"
            type="text"
            placeholder="https://..."
            value={editedCampaign.redirectLink}
            onChange={handleInputChange}
          />
        </CustomFormItem>
        <CustomFormItem title={"Streamer Script"}>
          <textarea
            name="brief"
            placeholder="Streamer Script"
            value={editedCampaign.brief}
            onChange={handleInputChange}
          ></textarea>
        </CustomFormItem>
        {editedCampaign.asset &&
        editedCampaign.pretext &&
        editedCampaign.redirectLink ? (
          <CustomFormItem title={"Campaign Asset Preview"}>
            <CampaignAssetPreview
              assetUrl={editedCampaign.asset.assetURL}
              assetType={editedCampaign.asset.assetType}
              pretext={editedCampaign.pretext}
              redirectLink={editedCampaign.redirectLink}
              displaySettings={editedCampaign.asset.displaySettings}
            />
          </CustomFormItem>
        ) : null}
        {(editedCampaign.campaignType === EnumCampaignType.Poll ||
          editedCampaign.campaignType === EnumCampaignType.Interactive) && (
          <CampaignPollPreview
            question={editedCampaign.question ?? editedCampaign.pretext}
            options={editedCampaign.answers && editedCampaign.answers.length ? editedCampaign.answers : []}
          />
        )}
        {diff && Object.keys(diff).length > 0 && (
          <CustomFormItem title="">
            <button onClick={handleSave} disabled={isLoading}>
              Save
            </button>
          </CustomFormItem>
        )}
      </CustomForm>
    </div>
  );
};

export default MainSettings;
