import styles from './CustomFormItem.module.scss'

interface CustomFormItemProps {
  title: string
  children: React.ReactNode
}

const CustomFormItem: React.FC<CustomFormItemProps> = ({ title, children }) => {
  return (
    <div className={styles.line}>
      <div className={styles.lineTitle}>{title}</div>
      <div className={styles.lineContent}>{children}</div>
    </div>
  )
}

export default CustomFormItem
