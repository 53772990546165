import { useEffect, useRef } from "react";
import { formatTime } from "helpers";
import classNames from "classnames";

//STYLES
import styles from "./SubtitleItem.module.scss";

//MODELS
import AdSubtitleDto from "model/dto/ad/adSubtitleDto";

interface SubtitleItemProps {
  data: AdSubtitleDto;
  currentVideoTime: number;
  setCurrentVideoTime: (time: number) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  promotionStartTime: number | null;
  clipType: string;
}

const SubtitleItem = ({
  data,
  currentVideoTime,
  setCurrentVideoTime,
  videoRef,
  promotionStartTime,
  clipType,
}: SubtitleItemProps) => {
  const isHighlighted = useRef<boolean>(false);

  useEffect(() => {
    if (clipType === "promotion") {
      if (
        currentVideoTime >= data.startTime - promotionStartTime! &&
        currentVideoTime <= data.endTime - promotionStartTime!
      ) {
        if (!isHighlighted.current) {
          isHighlighted.current = true;
        }
      } else {
        if (isHighlighted.current) {
          isHighlighted.current = false;
        }
      }
    } else {
      if (
        currentVideoTime >= data.startTime &&
        currentVideoTime <= data.endTime
      ) {
        if (!isHighlighted.current) {
          isHighlighted.current = true;
        }
      } else {
        if (isHighlighted.current) {
          isHighlighted.current = false;
        }
      }
    }
  }, [currentVideoTime]);

  useEffect(() => {
    if (isHighlighted.current) {
      const element = document.querySelector(`.${styles.highlighted}`);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isHighlighted.current]);

  return (
    <div
      className={classNames(styles.subtitleItem, {
        [styles.highlighted]: isHighlighted.current,
      })}
      onClick={() => {
        videoRef.current!.currentTime =
          clipType === "promotion"
            ? data.startTime - promotionStartTime!
            : data.startTime;
        setCurrentVideoTime(
          clipType === "promotion"
            ? data.startTime - promotionStartTime!
            : data.startTime
        );
      }}
    >
      <div className={styles.timeStamps}>
        <div className={styles.startTime}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 15 15"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.49998 0.5C5.49998 0.223858 5.72383 0 5.99998 0H7.49998H8.99998C9.27612 0 9.49998 0.223858 9.49998 0.5C9.49998 0.776142 9.27612 1 8.99998 1H7.99998V2.11922C9.09832 2.20409 10.119 2.56622 10.992 3.13572C11.0116 3.10851 11.0336 3.08252 11.058 3.05806L12.058 2.05806C12.3021 1.81398 12.6978 1.81398 12.9419 2.05806C13.186 2.30214 13.186 2.69786 12.9419 2.94194L11.967 3.91682C13.1595 5.07925 13.9 6.70314 13.9 8.49998C13.9 12.0346 11.0346 14.9 7.49998 14.9C3.96535 14.9 1.09998 12.0346 1.09998 8.49998C1.09998 5.13361 3.69904 2.3743 6.99998 2.11922V1H5.99998C5.72383 1 5.49998 0.776142 5.49998 0.5ZM2.09998 8.49998C2.09998 5.51764 4.51764 3.09998 7.49998 3.09998C10.4823 3.09998 12.9 5.51764 12.9 8.49998C12.9 11.4823 10.4823 13.9 7.49998 13.9C4.51764 13.9 2.09998 11.4823 2.09998 8.49998ZM7.49998 8.49998V4.09998C5.06992 4.09998 3.09998 6.06992 3.09998 8.49998C3.09998 10.93 5.06992 12.9 7.49998 12.9C8.715 12.9 9.815 12.4075 10.6112 11.6112L7.49998 8.49998Z"
              fill="currentColor"
            />
          </svg>
          {clipType === "promotion" && promotionStartTime
            ? formatTime(data.startTime - promotionStartTime)
            : formatTime(data.startTime)}
        </div>
        <div className={styles.endTime}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 15 15"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.49998 0.5C5.49998 0.223858 5.72383 0 5.99998 0H7.49998H8.99998C9.27612 0 9.49998 0.223858 9.49998 0.5C9.49998 0.776142 9.27612 1 8.99998 1H7.99998V2.11922C9.09832 2.20409 10.119 2.56622 10.992 3.13572C11.0116 3.10851 11.0336 3.08252 11.058 3.05806L12.058 2.05806C12.3021 1.81398 12.6978 1.81398 12.9419 2.05806C13.186 2.30214 13.186 2.69786 12.9419 2.94194L11.967 3.91682C13.1595 5.07925 13.9 6.70314 13.9 8.49998C13.9 12.0346 11.0346 14.9 7.49998 14.9C3.96535 14.9 1.09998 12.0346 1.09998 8.49998C1.09998 5.13361 3.69904 2.3743 6.99998 2.11922V1H5.99998C5.72383 1 5.49998 0.776142 5.49998 0.5ZM2.09998 8.49998C2.09998 5.51764 4.51764 3.09998 7.49998 3.09998C10.4823 3.09998 12.9 5.51764 12.9 8.49998C12.9 11.4823 10.4823 13.9 7.49998 13.9C4.51764 13.9 2.09998 11.4823 2.09998 8.49998ZM7.49998 8.49998V4.09998C5.06992 4.09998 3.09998 6.06992 3.09998 8.49998C3.09998 10.93 5.06992 12.9 7.49998 12.9C8.715 12.9 9.815 12.4075 10.6112 11.6112L7.49998 8.49998Z"
              fill="currentColor"
            />
          </svg>
          {clipType === "promotion" && promotionStartTime
            ? formatTime(data.endTime - promotionStartTime)
            : formatTime(data.endTime)}
        </div>
      </div>
      <div className={styles.subtitleContent}>
        <div className={styles.main}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 2C13.0675 2 16.426 5.03562 16.9337 8.96494L19.1842 12.5037C19.3324 12.7367 19.3025 13.0847 18.9593 13.2317L17 14.071V17C17 18.1046 16.1046 19 15 19H13.001L13 22H4L4.00025 18.3061C4.00033 17.1252 3.56351 16.0087 2.7555 15.0011 1.65707 13.6313 1 11.8924 1 10 1 5.58172 4.58172 2 9 2ZM21.1535 18.1024 19.4893 16.9929C20.4436 15.5642 21 13.8471 21 12.0001 21 10.153 20.4436 8.4359 19.4893 7.00722L21.1535 5.89771C22.32 7.64386 23 9.74254 23 12.0001 23 14.2576 22.32 16.3562 21.1535 18.1024Z" />
          </svg>
          {data.text}
        </div>
        <div className={styles.translated}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286zm1.634-.736L5.5 3.956h-.049l-.679 2.022z" />
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm7.138 9.995q.289.451.63.846c-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6 6 0 0 1-.415-.492 2 2 0 0 1-.94.31" />
          </svg>
          {data.englishText ? data.englishText : "-"}
        </div>
      </div>
    </div>
  );
};

export default SubtitleItem;
