import { toAbsoluteUrl } from "helpers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// STYLES
import styles from "./DropdownMenu.module.scss";
// COMOPONENTS
import DropdownNav from "../DropdownNav/DropdownNav";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import classNames from "classnames";

type DropdownMenuProps = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
};

const DropdownMenu = ({
  isDropdownOpen,
  setIsDropdownOpen,
}: DropdownMenuProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.dropdownMenu}>
      {/* TODO:FIX THIS */}
      <>
        {/* <div className={styles.adminMenu}>
          <div className={styles.adminUserSearch}>
            <input type='text' placeholder='Search username...' />
            <button className='userSearch'>
              <img src={toAbsoluteUrl('./assets/icons/Header/search.png')} alt='search' />
            </button>
          </div>
        </div>
        <select className={styles.languageSelect} name='' id=''>
          <option value=''>Türkçe</option>
          <option value=''>English</option>
          <option value=''>Español</option>
        </select> */}
        <DropdownNav />
      </>
    </div>
  );
};

export default DropdownMenu;
