import classNames from "classnames";
import { formatDate, toAbsoluteUrl } from "helpers";

//STYLES
import styles from "./ChatMessageList.module.scss";
import FilterItemDto from "model/dto/filterItemDto";
import { useEffect, useRef, useState } from "react";
import { GetCampaignChatMessagesByKeywordResponseItem } from "model/response/campaign/getCampaignChatMessagesByKeywordResponse";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GetCampaignChatMessagesByKeywordRequest from "model/request/campaign/getCampaignChatMessagesByKeywordRequest";
import Loading from "components/Shared/Loading/Loading";
import { createMessageService } from "services/messageService";
import PageOptions from "model/dto/pageOptions";

interface ChatMessageListProps {
  filterList: FilterItemDto[];
  activeKeywordIndex: number;
  keywords: string[];
  pageOptions: PageOptions;
  setPageOptions: (pageOptions: PageOptions) => void;
}

const ChatMessageList = ({
  filterList,
  activeKeywordIndex,
  keywords,
  pageOptions,
  setPageOptions,
}: ChatMessageListProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [chatMessages, setChatMessages] = useState<
    GetCampaignChatMessagesByKeywordResponseItem[]
  >([]);
  const [messagePerPage, setMessagePerPage] = useState(15);
  const campaignService = createCampaignService();
  const messageService = createMessageService();
  const { id } = useParams();
  const [previousFilterList, setPreviousFilterList] = useState<FilterItemDto[]>(
    []
  );
  const [stateForTrigger, setStateForTrigger] = useState(false);
  const previousActiveKeywordIndex = useRef<number>(activeKeywordIndex);

  const preparePostBody = (filterList: FilterItemDto[]) => {
    const postBody: {
      [key: string]:
        | Array<boolean | string | number | null>
        | boolean
        | string
        | number
        | null;
    } = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          postBody[filterItem.fieldName] = selectedResults.map(
            (result) => result.value
          );
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    return postBody;
  };

  const fetchMessages = async (
    filterList: FilterItemDto[],
    keywords: string[],
    activeKeywordIndex: number,
    pageOptions: PageOptions,
    setPageOptions: (pageOptions: PageOptions) => void
  ) => {
    if (!id) return setIsLoading(false);
    if (!keywords.length) return setIsLoading(false);
    let postBody = preparePostBody(filterList);

    postBody.id = parseInt(id);
    postBody.keyword = keywords[activeKeywordIndex] ?? keywords[0];
    postBody.take = messagePerPage;
    postBody.skip = pageOptions.page * messagePerPage;
    try {
      setIsLoading(true);
      const response = await campaignService.getCampaignChatMessagesByKeyword(
        postBody as unknown as GetCampaignChatMessagesByKeywordRequest
      );
      setChatMessages(response.data);
      setPageOptions({
        ...pageOptions,
        hasNextPage:
          response.rowCount > (pageOptions.page + 1) * messagePerPage,
      });
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch chat messages by keywords");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPreviousFilterList(filterList);
  }, []);

  const hasDiff = (
    previousFilterList: FilterItemDto[],
    filterList: FilterItemDto[]
  ) => {
    const previousSelectedFilters = preparePostBody(previousFilterList);
    const selectedFilters = preparePostBody(filterList);
    return (
      JSON.stringify(selectedFilters) !==
      JSON.stringify(previousSelectedFilters)
    );
  };

  useEffect(() => {
    let resetPage = false;
    if (previousActiveKeywordIndex.current !== activeKeywordIndex) {
      previousActiveKeywordIndex.current = activeKeywordIndex;
      if (pageOptions.page !== 0) resetPage = true;
    }
    if (
      hasDiff(previousFilterList, filterList) &&
      !resetPage &&
      pageOptions.page !== 0
    )
      resetPage = true;

    if (resetPage) {
      setPageOptions({ ...pageOptions, page: 0 });
    } else {
      setPreviousFilterList(filterList);
      fetchMessages(
        filterList,
        keywords,
        activeKeywordIndex,
        pageOptions,
        setPageOptions
      );
    }
  }, [stateForTrigger, activeKeywordIndex, keywords, pageOptions.page]);

  useEffect(() => {
    if (hasDiff(previousFilterList, filterList)) {
      setStateForTrigger(!stateForTrigger);
    }
  }, [filterList]);

  const handleMessageAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    message: GetCampaignChatMessagesByKeywordResponseItem
  ) => {
    try {
      setDisabled(true);
      if (e.currentTarget.name === "approve") {
        setChatMessages(
          chatMessages.map((msg) =>
            msg.id === message.id
              ? { ...msg, isApproved: message.isApproved == true ? null : true }
              : msg
          )
        );
        await messageService.editMessage({
          id: message.id,
          isApproved: message.isApproved == true ? null : true,
        });
      } else if (e.currentTarget.name === "reject") {
        setChatMessages(
          chatMessages.map((msg) =>
            msg.id === message.id
              ? {
                  ...msg,
                  isApproved: message.isApproved == false ? null : false,
                }
              : msg
          )
        );
        await messageService.editMessage({
          id: message.id,
          isApproved: message.isApproved == false ? null : false,
        });
      } else {
        return;
      }
    } catch (error) {
      toast.error("Failed to edit message");
      setChatMessages(
        chatMessages.map((msg) =>
          msg.id === message.id
            ? { ...msg, isApproved: message.isApproved }
            : msg
        )
      );
    } finally {
      setDisabled(false);
    }
  };

  if (!id) return null;
  if (isLoading) return <Loading />;

  return (
    <div className={styles.chatMessageList}>
      {chatMessages.map((message, index) => (
        <div className={styles.message} key={index}>
          <div className={styles.messageContent}>
            <div className={styles.messageSender}>
              <div className={styles.senderName}>
                <img
                  src={toAbsoluteUrl(
                    `/assets/icons/avatar${(index % 6) + 1}.png`
                  )}
                  alt="Sender"
                />
                {message.sender}
              </div>
              <div className={styles.keyword}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 4h-5V3h5v1zm-1 3h-2v1h2V7zm-4 0H1v1h9V7zm2 6H1v1h11v-1zm-5-3H1v1h6v-1zm8 0h-5v1h5v-1zM8 2v3H1V2h7zM7 3H2v1h5V3z" />
                </svg>
                <b>Keyword:</b> {message.keyword}
              </div>
            </div>
            <div className={styles.messageText}>{message.message}</div>
            <div className={styles.messageDetails}>
              <div className={styles.streamer}>
                <img src={message.streamer.logo ?? undefined} alt="Streamer" />
                <img
                  src={toAbsoluteUrl(
                    `/assets/icons/Platforms/${message.streamer.platform}-icon.png`
                  )}
                  alt="Platform"
                />
                {message.streamer.name}
              </div>
              <div className={styles.messageDate}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
                </svg>
                {message.date ? formatDate(new Date(message.date)) : undefined}
              </div>
            </div>
            <div className={styles.actionSection}>
              <button
                className={classNames(styles.reject, {
                  [styles.selected]: message.isApproved == false,
                })}
                name="reject"
                onClick={(e) => handleMessageAction(e, message)}
                disabled={disabled}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M24 24H0V0h24v24z" />
                  <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z" />
                </svg>
                Reject
              </button>
              <button
                className={classNames(styles.approve, {
                  [styles.selected]: message.isApproved == true,
                })}
                name="approve"
                onClick={(e) => handleMessageAction(e, message)}
                disabled={disabled}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M24 24H0V0h24v24z" />
                  <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66a4.8 4.8 0 0 0-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84A2.34 2.34 0 0 0 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z" />
                </svg>
                Approve
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessageList;
