import { createContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { LicenseInfo } from "@mui/x-license";

interface AppLevelContextProps {
  children: React.ReactNode;
}

interface AppLevelContextInterface {
  user: any;
  globalLoading: boolean;
  signIn: (
    email: string,
    password: string
  ) => Promise<{
    success: boolean;
    error: string | null;
  }>;
  logout: () => void;
  resetPassword: (email: string) => Promise<{
    success: boolean;
    error: string | null;
  }>;
}

export const AppLevelContext = createContext<AppLevelContextInterface>({
  user: null,
  globalLoading: true,
  signIn: async () => {
    return {
      success: false,
      error: null,
    };
  },
  logout: () => {},
  resetPassword: async () => {
    return {
      success: false,
      error: null,
    };
  },
});

export const AppLevelContextProvider = ({ children }: AppLevelContextProps) => {
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);
  const [globalLoading, setGlobalLoading] = useState<boolean>(true);

  const signIn = async (
    email: string,
    password: string
  ): Promise<{
    success: boolean;
    error: string | null;
  }> => {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password);
      console.log("data", data);
      return {
        success: true,
        error: null,
      };
    } catch (error: any) {
      console.log("error", error.message);
      if (error.message === "Firebase: Error (auth/invalid-credential).") {
        return {
          success: false,
          error: "Invalid email or password",
        };
      } else if (error.message === "Firebase: Error (auth/invalid-email).") {
        return {
          success: false,
          error: "Invalid email",
        };
      } else {
        return {
          success: false,
          error: "Something went wrong",
        };
      }
    }
  };

  //LOGOUT & CLEAR TOKEN / USER
  const logout = async () => {
    try {
      await signOut(auth);
      setToken(null);
      setUser(null);
    } catch (error) {
      // console.log(error)
    }
  };

  // Function to reset password
  const resetPassword = async (
    email: string
  ): Promise<{
    success: boolean;
    error: string | null;
  }> => {
    try {
      await sendPasswordResetEmail(auth, email);
      // Handle success - you can set some state here if you want to show a message to the user
      return {
        success: true,
        error: null,
      };
    } catch (error: any) {
      // Handle errors - for example, showing error messages
      if (error.message === "Firebase: Error (auth/invalid-email).") {
        return {
          success: false,
          error: "Invalid email",
        };
      } else {
        return {
          success: false,
          error: "Something went wrong",
        };
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("user", user);
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true);
        const authToken = idTokenResult.token;
        setToken(authToken);
        setUser(user);
        setGlobalLoading(false);
      } else {
        // User is signed out
        setToken(null);
        setUser(null);
        setGlobalLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");
  }, []);

  return (
    <AppLevelContext.Provider
      value={{
        user,
        globalLoading,
        signIn,
        logout,
        resetPassword,
      }}
    >
      {children}
    </AppLevelContext.Provider>
  );
};
