import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink, formatNumber } from "helpers";
const adsView: CustomDataGridViewDto = {
  viewName: "Streamer Score",
  id: "streamer-score",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "streamer":
        navigate(`streamer-popup/${params.row.streamer.id}/overview`);
        break;
      case "campaign":
        navigate(`campaigns-popup/${params.row.campaign.id}/overview`);
        break;
      default:
        navigate(`ads-popup/${params.id}/labelling`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        resizable: true,
        width: 100,
      },
      {
        field: "timestamp",
        headerName: "Timestamp",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return new Date(params.value).toLocaleString();
        },
      },
      {
        field: "platform",
        headerName: "Streamer Platform",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "promotionScore",
        headerName: "Promotion Score",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0 ? `${params.value}` : "-",
      },
      {
        field: "similarityScore",
        headerName: "Similarity Score",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0 ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyScore",
        headerName: "Brand Safety Score",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0 ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyCategory",
        headerName: "Brand Safety Category",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyCausedSentence",
        headerName: "Brand Safety Caused Sentence",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyExplanation",
        headerName: "Brand Safety Explanation",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "scoredBy",
        headerName: "Scored By",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default adsView;
