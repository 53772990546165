import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink, formatNumber } from "helpers";

const adsPopupView: CustomDataGridViewDto = {
  viewName: "Ads Popup",
  id: "ads-popup",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "campaign":
        navigate(`/ads/campaigns-popup/${params.row.campaign.id}/overview`);
        break;
      default:
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    getRowHeight: (params) => {
      if (params.model?.name?.length > 50) return "auto";
    },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 90,
      },
      {
        field: "timestamp",
        headerName: "Timestamp",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "views",
        headerName: "Views",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "clicks",
        headerName: "Clicks",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "0",
      },
      {
        field: "cpm",
        headerName: "CPM",
        width: 80,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.value.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "ctr",
        headerName: "CTR",
        width: 80,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value} %` : "-",
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "client",
        headerName: "Client",
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "standard",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "campaign",
        headerName: "Campaign",
        width: 300,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? <a>{params.value.name}</a> : "-";
        },
      },
      {
        field: "category",
        headerName: "Category",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value.name}` : "-",
      },
      {
        field: "clipLink",
        headerName: "Clip",
        width: 50,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              Link
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "promotionClipLink",
        headerName: "Promotion Clip",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              Link
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "similarityScore",
        headerName: "Similarity Score",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0 ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyScore",
        headerName: "Brand Safety Score",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0 ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyCategory",
        headerName: "Brand Safety Category",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyCausedSentence",
        headerName: "Brand Safety Caused Sentence",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "brandSafetyExplanation",
        headerName: "Brand Safety Explanation",
        width: 200,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "scoredBy",
        headerName: "Scored By",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value}` : "-",
      },
      {
        field: "videoQuality",
        headerName: "Video Quality",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return "480p";
        },
      },
    ],
    rowHeight: 40,
    density: "compact",
    hideFooter: true,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default adsPopupView;
