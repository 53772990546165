import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";

interface InfluencerCpmInputProps {
  campaignData: GetCampaignPostReviewSettingsResponse;
  setCampaignData: (
    campaignData: GetCampaignPostReviewSettingsResponse
  ) => void;
  currencyId: string;
}
const InfluencerCpmInput = ({
  campaignData,
  setCampaignData,
  currencyId,
}: InfluencerCpmInputProps) => {
  const influencerCPM = campaignData.influencerCPMs.find(
    (x) => x.currencyId === currencyId
  )?.value;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <span
        style={{
          textTransform: "uppercase",
        }}
      >
        {currencyId}
      </span>
      <div style={{ position: "relative", width: "100%", minWidth: "150px" }}>
        <input
          onChange={(e) => {
            const currencyCPM = campaignData.influencerCPMs.find(
              (x) => x.currencyId === currencyId
            );
            if (currencyCPM) {
              currencyCPM.value = parseFloat(e.target.value);
              const influencerCPMs = campaignData.influencerCPMs.filter(
                (x) => x.currencyId !== currencyId
              );
              setCampaignData({
                ...campaignData,
                influencerCPMs: [currencyCPM, ...influencerCPMs],
              });
            } else {
              setCampaignData({
                ...campaignData,
                influencerCPMs: [
                  ...campaignData.influencerCPMs,
                  {
                    currencyId: currencyId,
                    value: parseFloat(e.target.value),
                  },
                ],
              });
            }
          }}
          value={influencerCPM || ""}
          type="number"
          placeholder={currencyId}
        />
        {influencerCPM !== undefined &&
          campaignData.influencerCPMBonusPercentage !== null &&
          !isNaN(influencerCPM * campaignData.influencerCPMBonusPercentage) && (
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                position: "absolute",
                right: "0",
                top: "0",
                zIndex: 1,
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.75rem",
                fontWeight: 500,
                background: "#313131",
                color: "#5d7fff",
                borderRadius: "0.5rem",
                padding: "0.25rem 0.5rem",
                boxSizing: "border-box",
                marginLeft: "0.25rem",
                height: "44px",
                whiteSpace: "nowrap",
              }}
            >
              +
              {parseFloat(
                (
                  influencerCPM * campaignData.influencerCPMBonusPercentage
                ).toFixed(2)
              )}{" "}
              Bonus
            </span>
          )}
      </div>
      {campaignData.influencerCPMs.find((x) => x.currencyId === currencyId)
        ?.value ? (
        <button
          onClick={() => {
            const influencerCPMs = campaignData.influencerCPMs.filter(
              (x) => x.currencyId !== currencyId
            );
            setCampaignData({
              ...campaignData,
              influencerCPMs: influencerCPMs,
            });
          }}
        >
          Remove
        </button>
      ) : null}
    </div>
  );
};

export default InfluencerCpmInput;
