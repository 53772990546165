import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

// STYLES
import styles from "./DragAndDrop.module.scss";

// COMPONENTS
import Loading from "components/Shared/Loading/Loading";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GetCampaignPostAdSettingsResponse from "model/response/campaign/getCampaignPostAdSettingsResponse";
import AdSettingDto from "model/dto/ad/AdSettingDto";

interface DragAndDropProps {
  assetData: GetCampaignPostAdSettingsResponse | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  postAdSettings: AdSettingDto;
  setPostAdSettings: React.Dispatch<React.SetStateAction<AdSettingDto>>;
  assetLoading: boolean;
  setAssetLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DragAndDrop = ({
  assetData,
  isLoading,
  setIsLoading,
  postAdSettings,
  setPostAdSettings,
  assetLoading,
  setAssetLoading,
}: DragAndDropProps) => {
  const constraintsRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);

  const convertVhToPercent = (vh: string) => {
    // check if the string is a valid vh value
    if (!vh.includes("vh")) return vh;
    // remove the vh part of the string
    const value = vh.slice(0, -2);
    // convert to percentage
    return `${value}%`;
  };

  const getPercentageFromString = (sign: string, string: string) => {
    if (!string || !string.endsWith(sign)) {
      return;
    }
    string = string.slice(0, -sign.length);
    return parseFloat(string);
  };

  const setAssetPlace = () => {
    if (!assetData) return;
    if (!constraintsRef.current) return;
    const { topPadding, leftPadding } = assetData;
    const a = getPercentageFromString("%", convertVhToPercent(topPadding));
    const b = getPercentageFromString("%", leftPadding);
    if (a !== undefined) {
      setTop((a / 100) * constraintsRef.current.getBoundingClientRect().height);
    }
    if (b !== undefined) {
      setLeft((b / 100) * constraintsRef.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    if (!assetData) return;
    setAssetPlace();
    setIsLoading(false);
  }, [assetData]);

  const handleDragEnd = () => {
    if (itemRef.current && constraintsRef.current) {
      const itemRect = itemRef.current.getBoundingClientRect();
      const containerRect = constraintsRef.current.getBoundingClientRect();
      const left = itemRect.left - containerRect.left;
      const top = itemRect.top - containerRect.top;
      setLeft(left);
      setTop(top);
    }
  };

  useEffect(() => {
    if (!assetLoading) {
      if (!constraintsRef.current) return;
      setPostAdSettings({
        ...postAdSettings,
        leftPadding:
          (
            (left / constraintsRef.current.getBoundingClientRect().width) *
            100
          ).toFixed(2) + "%",
        topPadding:
          (
            (top / constraintsRef.current.getBoundingClientRect().height) *
            100
          ).toFixed(2) + "%",
      });
    }
    // eslint-disable-next-line
  }, [left, top]);

  return (
    <div className={styles.previewSection}>
      <motion.div ref={constraintsRef} className={styles.demoDragScreen}>
        {assetLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <Loading />
          </div>
        )}
        {!assetData?.asset && !assetLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              height: "100%",
              textShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              fontSize: "1.4rem",
            }}
          >
            This campaign does not have an asset
          </div>
        )}
        {!isLoading && assetData && (
          <motion.div
            ref={itemRef}
            style={{
              display: "flex",
              width: assetData?.width || "auto",
              height: assetData?.width === "auto" ? "100%" : "auto",
            }}
            initial={{ x: left, y: top }}
            drag
            dragMomentum={false}
            dragConstraints={constraintsRef}
            dragElastic={0}
            onDragEnd={handleDragEnd}
          >
            {assetData.assetType === "video" && (
              <video
                onLoadedData={() => {
                  setAssetLoading(false);
                }}
                preload={"auto"}
                style={{ width: "100%", height: "auto", cursor: "move" }}
                autoPlay
                muted
                playsInline
                loop={true}
                controls={false}
              >
                <source
                  src={assetData?.asset ? assetData.asset : undefined}
                  type="video/webm"
                />
              </video>
            )}
            {assetData.assetType === "image" && (
              <img
                draggable="false"
                onLoad={() => {
                  setAssetLoading(false);
                }}
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "move",
                  opacity: assetLoading ? 0 : 1,
                }}
                src={assetData?.asset ? assetData.asset : undefined}
                alt="ad"
              />
            )}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default DragAndDrop;
