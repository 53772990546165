import PageOptions from 'model/dto/pageOptions';
import styles from './Pagination.module.scss';
import classNames from 'classnames';

interface PaginationProps {
    pageOptions: PageOptions;
    setPageOptions: (pageOptions: PageOptions) => void;
}

const Pagination = ({
    pageOptions,
    setPageOptions,
}: PaginationProps) => {
    const onPageChange = (newPage: number) => {
        setPageOptions({ ...pageOptions, page: newPage });
    };

    return (
        <div className={styles.pagination}>
            {pageOptions.page > 0 && (
                <button
                    className={styles.backBtn}
                    onClick={() => onPageChange(pageOptions.page - 1)}
                >
                    <svg strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                </button>
            )}
            <div className={styles.pageNumbers}>
                {pageOptions.page > 0 && (
                    <>
                        <div
                            className={styles.pageNumber}
                            onClick={() => onPageChange(0)}
                        >
                            1
                        </div>
                        {pageOptions.page > 1 && <div className={styles.pageNumber}>...</div>}
                    </>
                )}
                <div className={classNames(styles.pageNumber, styles.active)}>
                    {pageOptions.page + 1}
                </div>
                {pageOptions.hasNextPage && (
                    <>
                        <div
                            className={styles.pageNumber}
                            onClick={() => onPageChange(pageOptions.page + 1)}
                        >
                            {pageOptions.page + 2}
                        </div>
                        <div className={styles.pageNumber}>...</div>
                    </>
                )}
            </div>
            {pageOptions.hasNextPage && (
                <button
                    className={styles.nextBtn}
                    onClick={() => onPageChange(pageOptions.page + 1)}
                >
                    <svg strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
                </button>
            )}
        </div>
    );
};

export default Pagination;
