import { toAbsoluteUrl } from "helpers";
import StatusCircle from "../StatusCircle/StatusCircle";
import styles from "./CustomComponentCell.module.scss";
import EnumCampaignStatus from "model/enum/enumCampaignStatus";
import EnumPayoutRequestStatus from "model/enum/enumPayoutRequestStatus";
import EnumPayoutAccountStatus from "model/enum/enumPayoutAccountStatus";
import EnumGameSessionStatus from "model/enum/enumGameSessionStatus";
import EnumDonationStatus from "model/enum/enumDonationStatus";
import EnumCampaignHasStreamerStatus from "model/enum/enumCampaignHasStreamerStatus";
import EnumPlatform from "model/enum/enumPlatform";

type Props = {
  value?: string;
  image?: {
    rounded: "rounded" | "standard" | "circle";
    value: string;
  };
  status?:
    | EnumCampaignStatus
    | EnumPayoutRequestStatus
    | EnumPayoutAccountStatus
    | EnumGameSessionStatus
    | EnumDonationStatus
    | EnumCampaignHasStreamerStatus;
  platform?: string;
};

const CustomComponentCell: React.FC<Props> = ({
  value,
  image,
  status,
  platform,
}) => {
  return (
    <div className={styles.customComponentCell}>
      {status ? <StatusCircle status={status} /> : null}
      {image ? (
        <img
          src={image.value}
          alt={"cell-icon"}
          className={
            image.rounded === "rounded"
              ? styles.roundedDataIcon
              : image.rounded === "circle"
              ? styles.circleDataIcon
              : styles.dataIcon
          }
          onError={(e) => {
            e.currentTarget.src = toAbsoluteUrl(
              "/assets/icons/defaultAvatar.png"
            );
          }}
        />
      ) : null}
      {platform === EnumPlatform.Twitch ? (
        <>
          <img
            className={styles.dataIcon}
            src={toAbsoluteUrl("/assets/icons/Platforms/twitch-icon.png")}
            alt="platform-icon"
          />
          Twitch
        </>
      ) : platform === EnumPlatform.Youtube ? (
        <>
          <img
            className={styles.dataIcon}
            src={toAbsoluteUrl("/assets/icons/Platforms/youtube-icon.png")}
            alt="platform-icon"
          />
          Youtube
        </>
      ) : platform === EnumPlatform.Trovo ? (
        <>
          <img
            className={styles.dataIcon}
            src={toAbsoluteUrl("/assets/icons/Platforms/trovo-icon.png")}
            alt="platform-icon"
          />
          Trovo
        </>
      ) : platform === EnumPlatform.Kick ? (
        <>
          <img
            className={styles.dataIcon}
            src={toAbsoluteUrl("/assets/icons/Platforms/kick-icon.png")}
            alt="platform-icon"
          />
          Kick
        </>
      ) : null}
      {value}
    </div>
  );
};

export default CustomComponentCell;
