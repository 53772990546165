import { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { toAbsoluteUrl } from "helpers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
// COMPONENTS
import ProfileMenu from "./components/ProfileMenu/ProfileMenu";
import QuickMenu from "./components/ProfileMenu/components/QuickMenu/QuickMenu";

type HeaderProps = {
  sidebarToggle: boolean;
  setSidebarToggle: (value: boolean) => void;
};

const Header = ({ sidebarToggle, setSidebarToggle }: HeaderProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // CHECKING THE WINDOW SIZE
  const getWindowWidth = () => {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowWidth());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  // SETTING THE MENU STATE ACCORDING TO THE WINDOW SIZE
  useEffect(() => {
    if (windowSize > 768) {
      setSidebarToggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  return (
    <div className={styles.header}>
      {/*MOBILE MENU*/}
      <div className={styles.mobileMenu}>
        <img
          onClick={() => {
            setSidebarToggle(!sidebarToggle);
          }}
          className={styles.hamburgerIcon}
          src={toAbsoluteUrl("/assets/icons/Header/hamburgerMenu.png")}
          alt="menuIcon"
        />
        <div className={styles.mobileLogo}></div>
      </div>

      <div className={styles.userMenu}>
        {/*QUICK MENU*/}
        <QuickMenu />

        {/*PROFILE MENU*/}
        <ProfileMenu
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      </div>
    </div>
  );
};

export default Header;
