import PrivateRoutes from "./PrivateRoutes";

//COMPONENTS
import { Navigate } from "react-router-dom";
import PopupWrapper from "components/PopupWrapper/PopupWrapper";

//PAGES
import Login from "pages/Login/Login";
import Ads from "pages/Ads/Ads";
import Campaigns from "pages/Campaigns/Campaigns";
import Clients from "pages/Clients/Clients";
import Agencies from "pages/Agencies/Agencies";
import Streamers from "pages/Streamers/Streamers";
import Payouts from "pages/Payouts/Payouts";
import PayoutAccounts from "pages/PayoutAccounts/PayoutAccounts";
import InteractiveTriggers from "pages/InteractiveTriggers/InteractiveTriggers";
import GameSessions from "pages/GameSessions/GameSessions";
import Donations from "pages/Donations/Donations";
import GameEvents from "pages/GameEvents/GameEvents";
import LiveConsole from "pages/LiveConsole/LiveConsole";

//CONTEXT
import { FilterProvider } from "context/FilterContext";
import { CampaignsProvider } from "context/CampaignsContext";
import { ClientsProvider } from "context/ClientsContext";
import { AdsProvider } from "context/AdsContext";
import { AgenciesProvider } from "context/AgenciesContext";
import { StreamersProvider } from "context/StreamersContext";
import { PayoutsProvider } from "context/PayoutsContext";
import { PayoutAccountsProvider } from "context/PayoutAccountsContext";
import { InteractiveTriggersProvider } from "context/InteractiveTriggersContext";
import { GameEventsProvider } from "context/GameEventsContext";
import { GameSessionsProvider } from "context/GameSessionsContext";
import { DonationsProvider } from "context/DonationsContext";

//CONFIG
import campaignFilterOptions from "pages/Campaigns/config/campaignFilterOptions";
import adFilterOptions from "pages/Ads/config/adFilterOptions";
import clientsFilterOptions from "pages/Clients/config/clientsFilterOptions";
import agenciesFilterOptions from "pages/Agencies/config/agenciesFilterOptions";
import streamersFilterOptions from "pages/Streamers/config/streamersFilterOptions";
import payoutFilterOptions from "pages/Payouts/config/payoutFilterOptions";
import payoutAccountFilterOptions from "pages/PayoutAccounts/config/payoutAccountFilterOptions";
import interactiveTriggerFilterOptions from "pages/InteractiveTriggers/config/interactiveTriggerFilterOptions";
import gameSessionsFilterOptions from "pages/GameSessions/config/gameSessionsFilterOptions";
import donationFilterOptions from "pages/Donations/config/donationFilterOptions";
import gameEventsFilterOptions from "pages/GameEvents/config/gameEventsFilterOptions";
import Analytics from "pages/Analytics/Analytics";
import { LiveConsoleProvider } from "context/LiveConsoleContext";

export const routesPaths = [
  // PAGES
  {
    path: "/",
    element: <Navigate to={"/ads"} />,
    auth: true,
  },
  {
    path: "/ads",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <AdsProvider>
          <Ads />
        </AdsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //ADS POPUPS
  {
    path: "/ads/:popupName",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <AdsProvider>
          <Ads />
          <PopupWrapper />
        </AdsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/ads/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/ads/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <AdsProvider>
          <Ads />
          <PopupWrapper />
        </AdsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/ads/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <AdsProvider>
          <Ads />
          <PopupWrapper />
        </AdsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/campaigns",
    element: (
      <FilterProvider initialFilterList={campaignFilterOptions}>
        <CampaignsProvider>
          <Campaigns />
        </CampaignsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //CAMPAIGNS POPUPS
  {
    path: "/campaigns/:popupName",
    element: (
      <FilterProvider initialFilterList={campaignFilterOptions}>
        <CampaignsProvider>
          <Campaigns />
          <PopupWrapper />
        </CampaignsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/campaigns/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/campaigns/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={campaignFilterOptions}>
        <CampaignsProvider>
          <Campaigns />
          <PopupWrapper />
        </CampaignsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/campaigns/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={campaignFilterOptions}>
        <CampaignsProvider>
          <Campaigns />
          <PopupWrapper />
        </CampaignsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/clients",
    element: (
      <FilterProvider initialFilterList={clientsFilterOptions}>
        <ClientsProvider>
          <Clients />
        </ClientsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //CLIENTS POPUPS
  {
    path: "/clients/:popupName",
    element: (
      <FilterProvider initialFilterList={clientsFilterOptions}>
        <ClientsProvider>
          <Clients />
          <PopupWrapper />
        </ClientsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/clients/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/clients/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={clientsFilterOptions}>
        <ClientsProvider>
          <Clients />
          <PopupWrapper />
        </ClientsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/clients/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={clientsFilterOptions}>
        <ClientsProvider>
          <Clients />
          <PopupWrapper />
        </ClientsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/agencies",
    element: (
      <FilterProvider initialFilterList={agenciesFilterOptions}>
        <AgenciesProvider>
          <Agencies />
        </AgenciesProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //AGENCIES POPUPS
  {
    path: "/agencies/:popupName",
    element: (
      <FilterProvider initialFilterList={agenciesFilterOptions}>
        <AgenciesProvider>
          <Agencies />
          <PopupWrapper />
        </AgenciesProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/agencies/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/agencies/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={agenciesFilterOptions}>
        <AgenciesProvider>
          <Agencies />
          <PopupWrapper />
        </AgenciesProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/agencies/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={agenciesFilterOptions}>
        <AgenciesProvider>
          <Agencies />
          <PopupWrapper />
        </AgenciesProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/streamers",
    element: (
      <FilterProvider initialFilterList={streamersFilterOptions}>
        <StreamersProvider>
          <Streamers />
        </StreamersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //STREAMERS POPUPS
  {
    path: "/streamers/:popupName",
    element: (
      <FilterProvider initialFilterList={streamersFilterOptions}>
        <StreamersProvider>
          <Streamers />
          <PopupWrapper />
        </StreamersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/streamers/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/streamers/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={streamersFilterOptions}>
        <StreamersProvider>
          <Streamers />
          <PopupWrapper />
        </StreamersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/streamers/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={streamersFilterOptions}>
        <StreamersProvider>
          <Streamers />
          <PopupWrapper />
        </StreamersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payouts",
    element: (
      <FilterProvider initialFilterList={payoutFilterOptions}>
        <PayoutsProvider>
          <Payouts />
        </PayoutsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //PAYOUTS POPUPS
  {
    path: "/payouts/:popupName",
    element: (
      <FilterProvider initialFilterList={payoutFilterOptions}>
        <PayoutsProvider>
          <Payouts />
          <PopupWrapper />
        </PayoutsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payouts/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/payouts/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={payoutFilterOptions}>
        <PayoutsProvider>
          <Payouts />
          <PopupWrapper />
        </PayoutsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payouts/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={payoutFilterOptions}>
        <PayoutsProvider>
          <Payouts />
          <PopupWrapper />
        </PayoutsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payout-accounts",
    element: (
      <FilterProvider initialFilterList={payoutAccountFilterOptions}>
        <PayoutAccountsProvider>
          <PayoutAccounts />
        </PayoutAccountsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //PAYOUT ACCOUNTS POPUPS
  {
    path: "/payout-accounts/:popupName",
    element: (
      <FilterProvider initialFilterList={payoutAccountFilterOptions}>
        <PayoutAccountsProvider>
          <PayoutAccounts />
          <PopupWrapper />
        </PayoutAccountsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payout-accounts/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/payout-accounts/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={payoutAccountFilterOptions}>
        <PayoutAccountsProvider>
          <PayoutAccounts />
          <PopupWrapper />
        </PayoutAccountsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/payout-accounts/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={payoutAccountFilterOptions}>
        <PayoutAccountsProvider>
          <PayoutAccounts />
          <PopupWrapper />
        </PayoutAccountsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/interactive-triggers",
    element: (
      <FilterProvider initialFilterList={interactiveTriggerFilterOptions}>
        <InteractiveTriggersProvider>
          <InteractiveTriggers />
        </InteractiveTriggersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //ADS POPUPS
  {
    path: "/interactive-triggers/:popupName",
    element: (
      <FilterProvider initialFilterList={interactiveTriggerFilterOptions}>
        <InteractiveTriggersProvider>
          <InteractiveTriggers />
          <PopupWrapper />
        </InteractiveTriggersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/interactive-triggers/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/interactive-triggers/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={interactiveTriggerFilterOptions}>
        <InteractiveTriggersProvider>
          <InteractiveTriggers />
          <PopupWrapper />
        </InteractiveTriggersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/interactive-triggers/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={interactiveTriggerFilterOptions}>
        <InteractiveTriggersProvider>
          <InteractiveTriggers />
          <PopupWrapper />
        </InteractiveTriggersProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-sessions",
    element: (
      <FilterProvider initialFilterList={gameSessionsFilterOptions}>
        <GameSessionsProvider>
          <GameSessions />
        </GameSessionsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //GAME SESSIONS POPUPS
  {
    path: "/game-sessions/:popupName",
    element: (
      <FilterProvider initialFilterList={gameSessionsFilterOptions}>
        <GameSessionsProvider>
          <GameSessions />
          <PopupWrapper />
        </GameSessionsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-sessions/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/game-sessions/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={gameSessionsFilterOptions}>
        <GameSessionsProvider>
          <GameSessions />
          <PopupWrapper />
        </GameSessionsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-sessions/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={gameSessionsFilterOptions}>
        <GameSessionsProvider>
          <GameSessions />
          <PopupWrapper />
        </GameSessionsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/donations",
    element: (
      <FilterProvider initialFilterList={donationFilterOptions}>
        <DonationsProvider>
          <Donations />
        </DonationsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //DONATIONS POPUPS
  {
    path: "/donations/:popupName",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <DonationsProvider>
          <Donations />
          <PopupWrapper />
        </DonationsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/donations/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/donations/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <DonationsProvider>
          <Donations />
          <PopupWrapper />
        </DonationsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/donations/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={adFilterOptions}>
        <DonationsProvider>
          <Donations />
          <PopupWrapper />
        </DonationsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  ,
  {
    path: "/analytics",
    element: (
      <FilterProvider initialFilterList={gameEventsFilterOptions}>
        <GameEventsProvider>
          <Analytics />
        </GameEventsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-events",
    element: (
      <FilterProvider initialFilterList={gameEventsFilterOptions}>
        <GameEventsProvider>
          <GameEvents />
        </GameEventsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //GAME EVENTS POPUPS
  {
    path: "/game-events/:popupName",
    element: (
      <FilterProvider initialFilterList={gameEventsFilterOptions}>
        <GameEventsProvider>
          <GameEvents />
          <PopupWrapper />
        </GameEventsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-events/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/game-events/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={gameEventsFilterOptions}>
        <GameEventsProvider>
          <GameEvents />
          <PopupWrapper />
        </GameEventsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/game-events/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={gameEventsFilterOptions}>
        <GameEventsProvider>
          <GameEvents />
          <PopupWrapper />
        </GameEventsProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/live-console",
    element: (
      <FilterProvider initialFilterList={[]}>
        <LiveConsoleProvider>
          <LiveConsole />
        </LiveConsoleProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  //LIVE CONSOLE POPUPS
  {
    path: "/live-console/:popupName",
    element: (
      <FilterProvider initialFilterList={[]}>
        <LiveConsoleProvider>
          <LiveConsole />
          <PopupWrapper />
        </LiveConsoleProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/live-console/:popupName/:id",
    element: <Navigate to="./overview" />,
    auth: true,
  },
  {
    path: "/live-console/:popupName/:id/:popupView",
    element: (
      <FilterProvider initialFilterList={[]}>
        <LiveConsoleProvider>
          <LiveConsole />
          <PopupWrapper />
        </LiveConsoleProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/live-console/:popupName/:id/:popupView/:popupSectionView",
    element: (
      <FilterProvider initialFilterList={[]}>
        <LiveConsoleProvider>
          <LiveConsole />
          <PopupWrapper />
        </LiveConsoleProvider>
      </FilterProvider>
    ),
    auth: true,
  },
  {
    path: "/login",
    element: <Login />,
    auth: false,
  },
  {
    path: "*",
    element: (
      <>
        <h1>404</h1>
        <p>Page not found</p>
      </>
    ),
    auth: true,
  },
];

const authCheck = (routes) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRoutes>{route.element}</PrivateRoutes>;
    }
    return route;
  });

export default authCheck(routesPaths);
