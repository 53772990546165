import { createBaseApiService } from "./baseApiService";
import SearchFilterRequest from "model/request/filter/searchFilterRequest";
import SearchFilterResponse from "model/response/filter/searchFilterResponse";

export const createFilterService = () => {
  const baseService = createBaseApiService("/filters");

  const get = async (req: SearchFilterRequest) => {
    const response = await baseService.post<SearchFilterResponse>("/get", req);
    return response.data;
  };
  return {
    get,
  };
};
