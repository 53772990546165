import Datetime from "react-datetime";
import { toAbsoluteUrl } from "helpers";
//STYLES
import styles from "./CustomForm.module.scss";

//COMPONENTS
import AddButton from "./Components/AddButton/AddButton";
import CustomFormItem from "./Components/CustomFormItem/CustomFormItem";

interface CustomFormProps {
  children: React.ReactNode;
}

const CustomForm: React.FC<CustomFormProps> = ({ children }) => {
  return <div className={styles.customForm}>{children}</div>;
};

export default CustomForm;
