import { useEffect, useState } from "react";
import styles from "./BulkPayoutInput.module.scss";
import { usePayoutsContext } from "context/PayoutsContext";

interface BulkPayoutInputProps {
  rowSelectionModel: number[];
  setRowSelectionModel: (rowSelectionModel: number[]) => void;
}

const BulkPayoutInput = ({
  rowSelectionModel,
  setRowSelectionModel,
}: BulkPayoutInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { fetchPayoutsByIds } = usePayoutsContext();

  useEffect(() => {
    if (rowSelectionModel.length > 0) {
      setInputValue(rowSelectionModel.join(","));
    } else {
      setInputValue("");
    }
  }, [rowSelectionModel]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    let selectedIds = e.target.value
      .split(",")
      .map((id) => parseInt(id.trim()));
    selectedIds = selectedIds.filter((id) => id !== null && !isNaN(id));
    setSelectedIds(selectedIds);
  };

  const handleSelect = () => {
    (async () => {
      await fetchPayoutsByIds(selectedIds);
      setRowSelectionModel(selectedIds);
    })();
  };

  return (
    <div className={styles.bulkPayoutInput}>
      <div className={styles.title}>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
        </svg>
        Bulk Payout
      </div>
      <div className={styles.input}>
        <input
          type="text"
          placeholder="Enter payment ids for bulk selection, example: 5121,5130,5256"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSelect();
            }
          }}
        />
        <button className={styles.selectBtn} onClick={handleSelect}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z" />
            <path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8zm8.933 3.519-1.726-1.726-1.414 1.414 3.274 3.274 5.702-6.84-1.538-1.282z" />
          </svg>
          Bulk Select
        </button>
      </div>
    </div>
  );
};

export default BulkPayoutInput;
