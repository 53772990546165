import { toAbsoluteUrl } from "helpers";
//STYLES
import styles from "./SelectInput.module.scss";
import { useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import SearchFilterResponse, {
  SearchFilterResponseItem,
} from "model/response/filter/searchFilterResponse";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";

interface Props {
  fetchResults: (search: string) => Promise<SearchFilterResponse>;
  select: (entity: SearchFilterResponseItem) => void;
  showResult?: boolean;
  defaultValue?: SearchFilterResponseItem | null;
  initialSearch?: boolean;
}

const SelectInput = ({
  fetchResults,
  select,
  showResult = false,
  defaultValue = null,
  initialSearch = false,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SearchFilterResponseItem[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] =
    useState<SearchFilterResponseItem | null>(null);

  const handleSearch = async () => {
    setResults(null);
    setIsLoading(true);
    try {
      const res = await fetchResults(query);
      setResults(res.data);
    } catch (error) {
      toast.error("Error while searching");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (initialSearch) {
      handleSearch();
    }
  }, []);

  return (
    <div className={styles.selectWrapper}>
      <div
        onClick={() => {
          setIsOpen(true);
        }}
        className={styles.selectInput}
      >
        <span className={styles.inputPlaceholder}>
          {selectedValue && showResult
            ? selectedValue.label
            : defaultValue
            ? defaultValue.label
            : "Select... "}
        </span>
        <img
          src={toAbsoluteUrl("/assets/icons/downArrow.png")}
          alt="Arrow-Icon"
        />
      </div>
      {isOpen && (
        <ClickAwayListener
          onClickAway={() => {
            setIsOpen(false);
          }}
        >
          <div className={styles.selectResult}>
            <div className={styles.selectSearch}>
              <input
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                type="text"
                placeholder="Search..."
              />
              <button onClick={handleSearch} className={styles.searchBtn}>
                <svg
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z"></path>
                </svg>
              </button>
            </div>
            {!!(isLoading || results !== null) && (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className={styles.searchResultList}>
                    {results?.map((result, index) => (
                      <div
                        key={index}
                        className={styles.resultItem}
                        onClick={() => {
                          select(result);
                          setSelectedValue(result);
                          setIsOpen(false);
                          setResults(null);
                          setQuery("");
                        }}
                      >
                        {/* <div className={classNames(styles.resultCheckbox, {
                              [styles.checked]: result.selected
                            })}>
                              <img
                                src={toAbsoluteUrl(
                                  "/assets/icons/NewCampaign/selectedIcon.png"
                                )}
                                alt="Checked-Icon"
                              />
                            </div>  */}
                        <span className={styles.resultText}>
                          {result.logo && <img src={result.logo} alt="Icon" />}
                          {result.platform && (
                            <img
                              src={toAbsoluteUrl(
                                `/assets/icons/Platforms/${result.platform}-icon.png`
                              )}
                              alt="Platform"
                            />
                          )}
                          {result.label}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SelectInput;
