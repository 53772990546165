import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import EnumPayoutRequestStatus from "model/enum/enumPayoutRequestStatus";

const payoutFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Status",
    tooltip: "Payout status",
    results: Object.entries(EnumPayoutRequestStatus).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    multiSelect: true,
    fieldName: "status",
    style: "select",
  },
  {
    type: "searchable",
    title: "Country",
    tooltip: "Country name",
    results: [],
    filterType: EnumFilterType.Country,
    fieldName: "countryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
];

export default payoutFilterOptions;
