import { useEffect, useState } from "react";
import { toast } from "react-toastify";

//STYLES
import styles from "./AgenciesDetailPanel.module.scss";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import TabSwitcher from "components/TabSwitcher/TabSwitcher";

//SERVICES
import { createClientsService } from "services/clientsService";
import { createAgencyService } from "services/agencyService";

//CONFIGS
import clientDetailsView from "./config/views/clientDetailsView";
import billingDetailsView from "./config/views/billingDetailsView";

//MODELS
import { GetAgencyBillingDetailsItem } from "model/response/agency/getAgencyBillingDetailsResponse";
import { GetClientsResponseItem } from "model/response/clients/getClientsResponse";

interface AgenciesDetailPanelProps {
  id: number;
}

const Tabs = [
  { title: "Clients", value: "clients" },
  { title: "Billing Details", value: "billing-details" },
];

const AgenciesDetailPanel = (props: AgenciesDetailPanelProps) => {
  const [activeTab, setActiveTab] = useState<string>("clients");
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<GetClientsResponseItem[]>([]);
  const [billingDetails, setBillingDetails] = useState<
    GetAgencyBillingDetailsItem[]
  >([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const clientsService = createClientsService();
  const agencyService = createAgencyService();

  const fetchClients = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await clientsService.get({
        agencyId: [id],
        take: 50,
        skip: 0,
      });
      setClients(response.data);
      setRowCount(response.rowCount);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch clients of the agency");
      setIsLoading(false);
    }
  };
  const fetchBillingDetails = async (id: number) => {
    try {
      setIsLoading(true);
      const response = await agencyService.getBillingDetails({ id });
      setBillingDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch billing details of the agency");
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "clients") fetchClients(props.id);
    if (activeTab === "billing-details") fetchBillingDetails(props.id);
  }, [activeTab, props.id]);

  return (
    <div className={styles.agenciesDetailPanel}>
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={Tabs}
      />
      {activeTab === "clients" && (
        <CustomDataGrid
          minHeight="140px"
          height={rowCount ? "auto" : "140px"}
          view={clientDetailsView}
          rows={clients}
          rowCount={rowCount}
          isLoading={isLoading}
        />
      )}
      {activeTab === "billing-details" && (
        <CustomDataGrid
          minHeight="140px"
          height={billingDetails.length ? "auto" : "140px"}
          view={billingDetailsView}
          rows={billingDetails}
          rowCount={billingDetails?.length}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default AgenciesDetailPanel;
