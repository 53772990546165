const getVideoMetadata = (file: File): Promise<{ duration: number, size: number, type: string, width: number, height: number }> => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            resolve({
                duration: video.duration,
                size: file.size,
                type: file.type,
                width: video.videoWidth,
                height: video.videoHeight
            });
        };

        video.onerror = function () {
            reject("Error loading video");
        };

        video.src = URL.createObjectURL(file);
    });
};

export default getVideoMetadata;