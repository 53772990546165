import React, { ReactNode, useState } from 'react';

interface NullableFormItemProps<T> {
    value: T | null;
    setValue: (value: T | null) => void;
    children: (value: T, setValue: (value: T) => void) => ReactNode;
    defaultValue: T;
}

const NullableFormItem = <T extends unknown>({ value, setValue, children, defaultValue }: NullableFormItemProps<T>) => {
    return (
        <>
            {value !== null && children(value as T, setValue as (value: T) => void)}
            {value !== null ? (
                <button onClick={() => { setValue(null);}}>Remove</button>
            ) : (
                <button style={{marginLeft: 'unset'}} onClick={() => setValue(defaultValue)}>Add</button>
            )}
        </>
    );
};

export default NullableFormItem;
