import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createPayoutsService } from "services/payoutsService";
import Loading from "components/Shared/Loading/Loading";
import EnumPayoutRequestStatus from "model/enum/enumPayoutRequestStatus";
import GetPayoutDetailResponse from "model/response/payouts/getPayoutDetailResponse";

interface EditStreamerProps {
  payout: GetPayoutDetailResponse;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditPayout = ({ payout, setRefreshData }: EditStreamerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatedPayout, setUpdatedPayout] =
    useState<GetPayoutDetailResponse>(payout);
  const [isUpdated, setIsUpdated] = useState(false);
  const payoutsService = createPayoutsService();

  const handleValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value: any = e.target.value;
    if (e.target.value === "default-select-value") {
      value = null;
    }
    setUpdatedPayout({
      ...updatedPayout,
      [e.target.name]: value,
    });
  };

  const handleUpdatePayout = async () => {
    setIsLoading(true);
    let postBody: any = {};
    postBody = {
      id: payout.id,
      status:
        payout.status !== updatedPayout.status
          ? updatedPayout.status
          : undefined,
    };
    Object.keys(postBody).forEach(
      (key) => postBody[key] === undefined && delete postBody[key]
    );
    try {
      await payoutsService.updatePayout(postBody);
      toast.success("Payout updated succesfully");
      setIsLoading(false);
      setRefreshData(true);
    } catch (err: any) {
      setIsLoading(false);
      toast.error("Failed to update Payout");
    }
  };

  useEffect(() => {
    if (JSON.stringify(payout) !== JSON.stringify(updatedPayout)) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  }, [updatedPayout]);

  if (!payout) return null;
  if (isLoading) return <Loading />;
  return (
    <CustomForm>
      <CustomFormItem title="Payout Request Status">
        <select
          name="status"
          value={updatedPayout.status}
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          {Object.entries(EnumPayoutRequestStatus).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </select>
      </CustomFormItem>
      {isUpdated && (
        <button disabled={isLoading} onClick={handleUpdatePayout}>
          Save Changes
        </button>
      )}
    </CustomForm>
  );
};

export default EditPayout;
