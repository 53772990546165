import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink } from "helpers";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
const donationsView: CustomDataGridViewDto = {
  viewName: "Donations",
  id: "donations",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      default:
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        resizable: true,
        width: 100,
      },
      {
        field: "fan",
        headerName: "Fan",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "platform",
        headerName: "Platform",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "consent",
        headerName: "Consent",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value && params.row.currency
            ? `${params.row.currency?.currencySign} ${formatCurrency(
                params.value
              )}`
            : params.value === 0 && params.row.currency
            ? `${params.row.currency?.currencySign} 0`
            : "-";
        },
      },
      {
        field: "message",
        headerName: "Message",
        resizable: true,
        width: 250,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "nameType",
        headerName: "Name Type",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "type",
        headerName: "Type",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "takeover",
        headerName: "Takeover",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "standard",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "clipLink",
        headerName: "Clip URL",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "asset",
        headerName: "Asset URL",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a
              href={formatLink(params.value.url)}
              target="_blank"
              rel="noreferrer"
            >
              {params.value.fileName}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "prompt",
        headerName: "Prompt",
        resizable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return new Date(params.value).toLocaleString();
        },
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return new Date(params.value).toLocaleString();
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default donationsView;
