import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";

import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";

//STYLES
import styles from "./ChatEngagementKeywords.module.scss";
import GetCampaignChatMessageCountByKeywordsResponse from "model/response/campaign/getCampaignChatMessageCountByKeywordsResponse";
import { createCampaignService } from "services/campaignService";

interface ChatEngagementKeywordsProps {
  editedCampaign: GetCampaignPostReviewSettingsResponse;
  setEditedCampaign: React.Dispatch<
    React.SetStateAction<GetCampaignPostReviewSettingsResponse | null>
  >;
  activeKeywordIndex: number;
  setActiveKeywordIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ChatEngagementKeywords = ({
  editedCampaign,
  setEditedCampaign,
  activeKeywordIndex,
  setActiveKeywordIndex,
}: ChatEngagementKeywordsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState<string>("");
  const [messageCountByKeywords, setMessageCountByKeywords] = useState<
    GetCampaignChatMessageCountByKeywordsResponse["keywords"] | null
  >(null);

  const campaignService = createCampaignService();

  useEffect(() => {
    if (editedCampaign.keywords && editedCampaign.keywords.length > 0) {
      (async () => {
        try {
          setIsLoading(true);
          const response =
            await campaignService.getCampaignChatMessageCountByKeywords({
              id: editedCampaign.id,
              keywords: editedCampaign.keywords,
            });
          setMessageCountByKeywords(response.keywords);
          setIsLoading(false);
        } catch (error) {
          toast.error("Failed to fetch message count by keywords");
          setIsLoading(false);
        }
      })();
    }
  }, [editedCampaign.keywords]);

  const handleAddKeyword = () => {
    if (!keyword) return;
    if (!editedCampaign.keywords) {
      setEditedCampaign({
        ...editedCampaign,
        keywords: [keyword.trim()],
      });
      setKeyword("");
    } else if (!editedCampaign.keywords.includes(keyword.trim())) {
      setEditedCampaign({
        ...editedCampaign,
        keywords: [...editedCampaign.keywords, keyword.trim()],
      });
      setKeyword("");
    } else {
      setKeyword("");
      toast.error("Keyword already exists");
    }
  };

  const removeKeyword = (index: number) => {
    const newKeywords = editedCampaign.keywords.filter((_, i) => i !== index);
    setEditedCampaign({
      ...editedCampaign,
      keywords: newKeywords,
    });
  };

  useEffect(() => {
    if (
      editedCampaign.keywords &&
      editedCampaign.keywords.length < activeKeywordIndex + 1
    ) {
      setActiveKeywordIndex(0);
    }
  }, [editedCampaign.keywords]);

  return (
    <div className={styles.chatEngagementKeywords}>
      <div className={styles.keywordsInput}>
        <input
          type="text"
          placeholder="Enter keywords"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value.toLowerCase())}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddKeyword();
            }
          }}
        />
        <button className={styles.addBtn} onClick={handleAddKeyword}>
          Add
        </button>
      </div>
      <div className={styles.selectedList}>
        {editedCampaign.keywords &&
          editedCampaign.keywords.map((keyword, index) => (
            <div
              className={classNames(styles.listItem, {
                [styles.active]: activeKeywordIndex === index,
              })}
              key={index}
              onClick={() => setActiveKeywordIndex(index)}
            >
              {messageCountByKeywords &&
                messageCountByKeywords.find((x) => x.keyword === keyword) && (
                  <span className={styles.count}>
                    {
                      messageCountByKeywords.find((x) => x.keyword === keyword)
                        ?.count
                    }
                    {!!messageCountByKeywords.find((x) => x.keyword === keyword)
                      ?.count && (
                      <span title="Unique Chatters">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>
                        {
                          messageCountByKeywords.find(
                            (x) => x.keyword === keyword
                          )?.uniqueChattersCount
                        }
                      </span>
                    )}
                  </span>
                )}
              {keyword}
              <button
                className={styles.removeBtn}
                onClick={() => removeKeyword(index)}
              >
                <svg
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zM9.41 15.95 12 13.36l2.59 2.59L16 14.54l-2.59-2.59L16 9.36l-1.41-1.41L12 10.54 9.41 7.95 8 9.36l2.59 2.59L8 14.54z"></path>
                </svg>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChatEngagementKeywords;
