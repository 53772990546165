import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//STYLES
import styles from "./PayoutsPopup.module.scss";

//CONFIGS
import payoutsPopupView from "./config/views/payoutsPopupView";
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import Loading from "components/Shared/Loading/Loading";
import EditPayout from "./Components/EditPayout/EditPayout";

//MODELS
import GetPayoutDetailResponse from "model/response/payouts/getPayoutDetailResponse";

//SERVICES
import { createPayoutsService } from "services/payoutsService";

const PayoutsPopup = () => {
  const [activeTab, setActiveTab] = useState("edit-payout");
  const [isLoading, setIsLoading] = useState(true);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [payoutRow, setPayoutRow] = useState<Array<GetPayoutDetailResponse>>(
    []
  );
  const [refreshData, setRefreshData] = useState(false);

  const payoutsService = createPayoutsService();

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  const fetchPayoutDetail = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const payout = await payoutsService.getPayoutDetail({ id: parseInt(id) });
      setPayoutRow([payout]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch Payout detail");
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchPayoutDetail();
  }, [id]);

  useEffect(() => {
    if (refreshData) {
      fetchPayoutDetail();
      setRefreshData(false);
    }
  }, [refreshData]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.payoutsPopup}>
      <CustomDataGrid
        view={payoutsPopupView}
        rows={payoutRow}
        rowCount={1}
        isLoading={isLoading}
        minHeight="auto"
      />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
      {activeTab === "edit-payout" && (
        <EditPayout payout={payoutRow[0]} setRefreshData={setRefreshData} />
      )}
    </div>
  );
};

export default PayoutsPopup;
