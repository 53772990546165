import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import AddButton from "components/CustomForm/Components/AddButton/AddButton";
import styles from "./CustomDateInput.module.scss";

interface CustomDateInputProps {
  value: Date;
  setValue: (value: Date) => void;
  includeTime?: boolean;
}
const CustomDateInput = ({
  value,
  setValue,
  includeTime,
}: CustomDateInputProps) => {
  return (
    <Datetime
      onChange={(date) => {
        // Check if 'date' is a Moment object
        if (typeof date !== "string" && date.isValid()) {
          setValue(date.toDate());
        }
      }}
      className={styles.datePicker}
      value={new Date(value)}
      dateFormat={includeTime ? "YYYY-MM-DD" : undefined} // This format will allow picking only the date
      timeFormat={includeTime ?? false} // This disables the time selection
    />
  );
};
export default CustomDateInput;
