import { useState } from "react";
import classNames from "classnames";
import styles from "./SimpleSelectInput.module.scss";
import { toAbsoluteUrl } from "helpers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

interface SimpleSelectInputProps<T> {
  options: Array<{ name: string; value: T }>;
  defaultValue?: { name: string; value: T };
  onSelect?: (value: T) => void;
}

function SimpleSelectInput<T>({
  options,
  defaultValue,
  onSelect,
}: SimpleSelectInputProps<T>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    { ...defaultValue }?.name
  );

  const handleSelect = (option: { name: string; value: T }) => {
    onSelect && onSelect(option.value);
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.selectWrapper}>
        <div className={styles.selectInput} onClick={() => setIsOpen(!isOpen)}>
          <span className={styles.inputPlaceholder}>
            {selectedOption || "Select..."}
          </span>
          <img
            src={toAbsoluteUrl("/assets/icons/downArrow.png")}
            alt="Arrow-Icon"
          />
        </div>
        {isOpen && (
          <div className={styles.selectResult}>
            {options.map((option, index) => (
              <div
                key={index}
                className={classNames(styles.resultItem)}
                onClick={() => handleSelect(option)}
              >
                <span className={styles.resultText}>{option.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default SimpleSelectInput;
