import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

//STYLES
import styles from "./AdDisplaySettings.module.scss";

//COMPONENTS
import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import Loading from "components/Shared/Loading/Loading";
import NullableFormItem from "components/CustomForm/Components/NullableFormItem";

//MODELS
import GetCampaignAdDisplaySettingsResponse from "model/response/campaign/getCampaignAdDisplaySettingsResponse";
import { createCampaignService } from "services/campaignService";
import getVideoMetadata from "helpers/getVideoMetadata";
import EnumAssetType from "model/enum/enumAssetType";
import getImageDimensions from "helpers/getImageDimensions";
import EditCampaignAdDisplaySettingsRequest from "model/request/campaign/editCampaignAdDisplaySettingsRequest";

interface AdDisplaySettingsProps {
  setReloadData: (value: boolean) => void;
}

const AdDisplaySettings = ({ setReloadData }: AdDisplaySettingsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const { id } = useParams();

  const [campaign, setCampaign] =
    useState<GetCampaignAdDisplaySettingsResponse | null>(null);
  const [editedCampaign, setEditedCampaign] =
    useState<GetCampaignAdDisplaySettingsResponse | null>(null);
  const [diff, setDiff] =
    useState<Partial<GetCampaignAdDisplaySettingsResponse> | null>(null);

  const campaignService = createCampaignService();

  const ALLOWED_TYPES = [
    "video/mp4",
    "video/webm",
    "image/jpeg",
    "image/png",
    "image/gif",
  ];

  const getTypes = (types: string[]) => {
    // cut the first part
    return types.map((type) => type.split("/")[1]);
  };

  const checkVideoAsset = async (
    file: File
  ): Promise<{
    duration: number;
    size: number;
    type: string;
    width: number;
    height: number;
  } | null> => {
    try {
      const videoMetadata = await getVideoMetadata(file);
      if (videoMetadata.duration > 300) {
        toast.error(
          `Video duration should not exceed 300 seconds, video selected is ${videoMetadata.duration} seconds`
        );
        return null;
      }
      return videoMetadata;
    } catch (error) {
      console.log(error);
      toast.error("Error selecting asset");
      return null;
    }
  };

  const uploadAsset = async (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      toast.error(
        `Invalid file type, allowed types are: ${getTypes(ALLOWED_TYPES).join(
          ", "
        )}`
      );
      return;
    }

    // Check if asset size is less than 20MB
    if (file.size > 100 * 1024 * 1024) {
      toast.error(
        `File size should not exceed 100MB, file selected is ${Math.round(
          file.size / (1024 * 1024)
        )}MB`
      );
      return;
    }
    try {
      if (file.type.startsWith("video")) {
        const videoMetadata = await checkVideoAsset(file);
        if (!videoMetadata) {
          toast.error("Error selecting video asset");
          return;
        }
        const assetUrl = await campaignService.uploadAsset(
          parseInt(id!),
          file,
          setUploadProgress,
          videoMetadata.duration
        );
        const response = await toast.promise(
          campaignService.updateCampaignAssetUrl({
            campaignId: parseInt(id!),
            assetType: EnumAssetType.Video,
            assetUrl,
            durationSeconds: videoMetadata.duration,
            dimensions: {
              width: videoMetadata.width,
              height: videoMetadata.height,
            },
          }),
          {
            pending: "Updating Asset...",
            success: "Updated",
            error: "Failed",
          }
        );
        setReloadData(true);
      } else {
        const dimensions = await getImageDimensions(file);
        if (!dimensions) {
          toast.error("Error getting image dimensions");
          return;
        }
        const assetUrl = await campaignService.uploadAsset(
          parseInt(id!),
          file,
          setUploadProgress
        );
        const response = await toast.promise(
          campaignService.updateCampaignAssetUrl({
            campaignId: parseInt(id!),
            assetType: EnumAssetType.Image,
            assetUrl,
            durationSeconds: 15,
            dimensions: dimensions,
          }),
          {
            pending: "Updating Asset...",
            success: "Updated",
            error: "Failed",
          }
        );
        setReloadData(true);
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 400 && error.response.data.message) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const value =
      e.target.type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;
    if (editedCampaign) {
      setEditedCampaign({
        ...editedCampaign,
        [e.target.name]: value,
      });
    }
  };

  const handleFetchBusinessSettings = async () => {
    setIsLoading(true);
    const response = await campaignService.getAdDisplaySettings({
      id: id ? parseInt(id) : 0,
    });
    setCampaign(response);
    setEditedCampaign(response);
    setIsLoading(false);
  };

  const handleSave = async () => {
    if (!diff || !id) return;
    setIsLoading(true);
    const postbody: EditCampaignAdDisplaySettingsRequest = {
      id: parseInt(id),
      asset: diff.asset,
      assetGif: diff.assetGif,
      topPadding: diff.topPadding,
      leftPadding: diff.leftPadding,
      width: diff.width,
      duration: diff.duration,
    };
    const res = await campaignService.editAdDisplaySettings(postbody);
    if (res.success) {
      toast.success("Changes saved successfully");
      setReloadData(true);
    } else {
      setIsLoading(false);
      toast.error("Error saving changes");
    }
  };

  useEffect(() => {
    handleFetchBusinessSettings();
  }, []);

  useEffect(() => {
    if (campaign && editedCampaign) {
      const changes: any = {};

      (
        Object.keys(
          editedCampaign
        ) as (keyof GetCampaignAdDisplaySettingsResponse)[]
      ).forEach((key) => {
        if (editedCampaign[key] != campaign[key]) {
          changes[key] = editedCampaign[key]!;
        }
      });

      setDiff(changes);
    } else {
      setDiff(null);
    }
  }, [campaign, editedCampaign]);

  if (!id) return null;
  if (isLoading || editedCampaign === null) return <Loading />;

  return (
    <div className={styles.adDisplaySettings}>
      <CustomForm>
        <CustomFormItem title={"Asset"}>
          <NullableFormItem<string>
            value={editedCampaign.asset}
            setValue={(value: string | null) => {
              setEditedCampaign({
                ...editedCampaign,
                asset: value,
              });
            }}
            defaultValue={"https://"}
          >
            {(value, setValue) => (
              <textarea
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                placeholder="Asset"
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title={"Asset Gif"}>
          <NullableFormItem<string>
            value={editedCampaign.assetGif}
            setValue={(value: string | null) => {
              setEditedCampaign({
                ...editedCampaign,
                assetGif: value,
              });
            }}
            defaultValue={"https://"}
          >
            {(value, setValue) => (
              <textarea
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
                placeholder="Asset Gif"
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        <CustomFormItem title={"Asset Upload"}>
          {!!(uploadProgress !== null) && <div>{uploadProgress}%</div>}
          <input
            type="file"
            accept="video/mp4,video/webm,image/jpeg,image/png,image/gif"
            onChange={(e) => {
              if (e.target.files?.length) {
                uploadAsset(e.target.files[0]);
              }
            }}
          />
        </CustomFormItem>
        <CustomFormItem title={"Top Padding"}>
          <input
            name="topPadding"
            onChange={handleInputChange}
            value={editedCampaign.topPadding}
            type="text"
            placeholder="Top Padding"
          />
        </CustomFormItem>
        {/* Left Padding */}
        <CustomFormItem title={"Left Padding"}>
          <input
            name="leftPadding"
            onChange={handleInputChange}
            value={editedCampaign.leftPadding}
            type="text"
            placeholder="Left Padding"
          />
        </CustomFormItem>
        {/* Width */}
        <CustomFormItem title={"Width"}>
          <input
            name="width"
            onChange={handleInputChange}
            value={editedCampaign.width}
            type="text"
            placeholder="Width"
          />
        </CustomFormItem>
        {/* Duration */}
        <CustomFormItem title={"Duration"}>
          <NullableFormItem<number>
            value={editedCampaign.duration}
            setValue={(value: number | null) => {
              setEditedCampaign({
                ...editedCampaign,
                duration: value,
              });
            }}
            defaultValue={0}
          >
            {(value, setValue) => (
              <input
                onChange={(e) => {
                  setValue(parseInt(e.target.value));
                }}
                value={value}
                type="number"
                placeholder="Duration"
              />
            )}
          </NullableFormItem>
        </CustomFormItem>
        {diff && Object.keys(diff).length > 0 && (
          <CustomFormItem title="">
            <button onClick={handleSave} disabled={isLoading}>
              Submit
            </button>
          </CustomFormItem>
        )}
      </CustomForm>
    </div>
  );
};

export default AdDisplaySettings;
