import FilterItemDto from "model/dto/filterItemDto";
import EnumBroadcastingSoftware from "model/enum/enumBroadcastingSoftware";
import EnumFilterType from "model/enum/enumFilterType";
import EnumGender from "model/enum/enumGender";
import EnumPlatform from "model/enum/enumPlatform";
import EnumStreamerAutoAcceptPreference from "model/enum/enumStreamerAutoAcceptPreference";

const streamersFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Platform",
    tooltip: "Streamer platform",
    results: Object.entries(EnumPlatform).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "platform",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Live",
    tooltip: "Is streamer live",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "live",
    multiSelect: false,
    style: "select",
  },
  {
    type: "static",
    title: "is Banned",
    tooltip: "Is streamer banned",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "isBanned",
    multiSelect: false,
    style: "select",
  },
  {
    type: "searchable",
    title: "Language",
    tooltip: "Language name",
    results: [],
    filterType: EnumFilterType.Languages,
    fieldName: "broadcastingLanguageId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "searchable",
    title: "Country",
    tooltip: "Country name",
    results: [],
    filterType: EnumFilterType.Country,
    fieldName: "countryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "searchable",
    title: "Currency",
    tooltip: "Currency name",
    results: [],
    filterType: EnumFilterType.Currency,
    fieldName: "currencyId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Gender",
    tooltip: "Streamer gender",
    results: Object.entries(EnumGender).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "gender",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Is Onboarded",
    tooltip: "Is streamer onboarded",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "isOnboarded",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Sign Up Completed",
    tooltip: "Is streamer sign up completed",
    results: [
      { label: "Yes", value: true, selected: false },
      { label: "No", value: false, selected: false },
    ],
    filterType: null,
    fieldName: "signUpCompleted",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Ads On",
    tooltip: "Is ads on",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "adsOn",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Automated Ads On",
    tooltip: "Is automated ads on",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "automatedAdsOn",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Broadcasting Software",
    tooltip: "Streamer broadcasting software",
    results: Object.entries(EnumBroadcastingSoftware).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "broadcastingSoftware",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Creator Manager",
    tooltip: "Creator manager name",
    results: [],
    filterType: EnumFilterType.Managers,
    fieldName: "teamManagerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Auto Accept Preference",
    tooltip: "Auto accept preference",
    results: Object.entries(EnumStreamerAutoAcceptPreference).map(
      ([key, value]) => ({
        label: key,
        value: value,
        selected: false,
      })
    ),
    filterType: null,
    fieldName: "autoAcceptPreference",
    multiSelect: true,
    style: "select",
  },
];

export default streamersFilterOptions;
