import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import { formatNumber } from "helpers";
import { toast } from "react-toastify";
import { createCampaignService } from "services/campaignService";
import PercentageCell from "components/CustomDataGrid/Components/PercentageCell/PercentageCell";

const campaignService = createCampaignService();

let onClickLoading: boolean = false;

const streamerManagementView: CustomDataGridViewDto = {
  viewName: "Streamer Management",
  id: "streamer-management",
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "__check__":
        break;
      case "addStreamers":
        break;
      default:
        navigate(`campaigns-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "name"] } },
    columns: [
      {
        field: "name",
        headerName: "Campaign",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.client.logo
                ? {
                    rounded: "standard",
                    value: params.row.client.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "id",
        headerName: "Id",
        width: 60,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "addStreamers",
        headerName: "Add Streamers",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <button
              className={styles.dataButton}
              onClick={async () => {
                try {
                  if(onClickLoading) return;
                  onClickLoading = true;
                  const alert = toast.loading("Adding streamers to campaign");
                  await campaignService.addStreamersToCampaignFromTargeting({
                    id: params.row.id,
                  });
                  toast.done(alert);
                  toast.success("Streamers added to campaign successfully, refreshing page");
                  setTimeout(() => {
                    onClickLoading = false;
                    window.location.reload();
                  }
                  , 2000);
                } catch (error) {
                  onClickLoading = false;
                  toast.error("Error while adding streamers to campaign");
                }
              }}
            >
              Add
            </button>
          );
        },
      },
      {
        field: "owner",
        headerName: "Owner",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.fullName}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "requested",
        headerName: "Requested",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "accepted",
        headerName: "Accepted",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "denied",
        headerName: "Denied",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "acceptedLive",
        headerName: "Accepted & Live",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "viewsProgress",
        headerName: "Views Progress",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return (
            <PercentageCell
              value={Math.round(
                (params.row.viewsRealized / params.row.viewsGoal) * 100
              )}
            />
          );
        },
      },
      {
        field: "dailyViewsProgress",
        headerName: "Daily Views Progress",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return (
            <PercentageCell
              value={Math.round(
                (params.row.viewsRealizedToday / params.row.dailyViewGoal) * 100
              )}
            />
          );
        },
      },
      {
        field: "viewsGoal",
        headerName: "Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "viewsRealized",
        headerName: "Views Realized",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "dailyViewGoal",
        headerName: "Daily Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "viewsRealizedToday",
        headerName: "Views Realized Today",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default streamerManagementView;
