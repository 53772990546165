import styles from "./DataCardSection.module.scss";

import DataCardItem from "./Components/DataCardItem/DataCardItem";
import { DataCardListItem } from "popups/CampaignsPopup/Components/CampaignInfo/CampaignInfo";
import { formatCurrency, formatNumber } from "helpers";

interface DataCardSectionProps {
  cardList: DataCardListItem[];
}

const DataCardSection = ({ cardList }: DataCardSectionProps) => {
  return (
    <div className={styles.dataCardSection}>
      {cardList.map((card) => (
        <DataCardItem
          key={card.key}
          title={card.title}
          value={card.value}
          image={card.image}
        />
      ))}
    </div>
  );
};

export default DataCardSection;
