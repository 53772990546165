import styles from "./CustomSelect.module.scss";

//COMPONENTS
import SelectItem from "./Components/SelectItem/SelectItem";
import customDataGridViewDto from "model/dto/customDataGridViewDto";

interface CustomSelectProps {
  activeView: string;
  views: customDataGridViewDto[];
  viewSetter: (view: string) => void;
}

const CustomSelect = (props: CustomSelectProps) => {
  return (
    <div className={styles.customSelect}>
      <SelectItem
        activeView={props.activeView}
        views={props.views}
        viewSetter={props.viewSetter}
      />
    </div>
  );
};

export default CustomSelect;
