import styles from "./CustomFilterList.module.scss";
import { toast } from "react-toastify";

//COMPONENTS
import FilterItem from "./Components/FilterItem/FilterItem";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";

//SERVICES
import { createFilterService } from "services/filterService";
import EnumFilterReferrer from "model/enum/enumFilterReferrer";

interface CustomFilterListProps {
  filterList: FilterItemDto[];
  handleSearchFilter: (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string,
    referrer?: EnumFilterReferrer
  ) => Promise<{ success: boolean; error: any }>;
  handleSelectFilterItem: (
    item: FilterItemResultsDto,
    fieldName: string
  ) => void;
}

const CustomFilterList = (props: CustomFilterListProps) => {
  return (
    <div className={styles.customFilterList}>
      {props.filterList.map((filterItem, index) => (
        <FilterItem
          key={index}
          type={filterItem.type}
          filterItem={filterItem}
          handleSearchFilter={props.handleSearchFilter}
          handleSelectFilterItem={props.handleSelectFilterItem}
        />
      ))}
    </div>
  );
};

export const searchFilter = async (
  value: string,
  filterType: EnumFilterType | null,
  fieldName: string,
  filterList: FilterItemDto[],
  setFilterList: React.Dispatch<React.SetStateAction<FilterItemDto[]>>,
  referrer?: EnumFilterReferrer,
  campaignId?: number
) => {
  const filterService = createFilterService();
  try {
    const response = await filterService.get({
      type: filterType,
      search: value,
      referrer: referrer,
      campaignId: campaignId,
    });
    const newFilterList = filterList.map((filterItem) => {
      if (filterItem.fieldName === fieldName) {
        const selectedFilters = filterItem.results.filter(
          (result) => result.selected
        );
        const filteredApiResults = response.data.filter((filterItem) => {
          return !selectedFilters.some(
            (selectedFilter) => selectedFilter.value === filterItem.value
          );
        });

        return {
          ...filterItem,
          results: [...selectedFilters, ...filteredApiResults],
        };
      }
      return filterItem;
    });
    setFilterList(newFilterList);
    return { success: true, error: null };
  } catch (error) {
    toast.error("Error fetching filter results.");
    return { success: false, error };
  }
};

export const selectFilterItem = (
  item: FilterItemResultsDto,
  fieldName: string,
  filterList: FilterItemDto[],
  setFilterList: React.Dispatch<React.SetStateAction<FilterItemDto[]>>
) => {
  const newFilterList = filterList.map((filterItem) => {
    if (filterItem.fieldName === fieldName) {
      return {
        ...filterItem,
        results: filterItem.results.map((result) => {
          if (filterItem.style === "toggle") {
            if (result.value === item.value) {
              return {
                ...result,
                selected: !result.selected,
              };
            }
          }
          if (filterItem.style === "select") {
            if (filterItem.multiSelect) {
              if (result.value === item.value) {
                return {
                  ...result,
                  selected: !result.selected,
                };
              }
            } else {
              if (result.value === item.value) {
                return {
                  ...result,
                  selected: !result.selected,
                };
              } else {
                return {
                  ...result,
                  selected: false,
                };
              }
            }
          }
          return result;
        }),
      };
    }
    return filterItem;
  });
  setFilterList(newFilterList);
};

export const clearFilterList = (
  initialFilterList: FilterItemDto[],
  setFilterList: React.Dispatch<React.SetStateAction<FilterItemDto[]>>
) => {
  setFilterList(initialFilterList);
};

export default CustomFilterList;
