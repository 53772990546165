import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

//STYLES
import styles from "./EditCampaign.module.scss";

//COMPONENTS
import SectionSwitcher from "components/SectionSwitcher/SectionSwitcher";
import MainSettings from "./Components/MainSettings/MainSettings";
import AdDisplaySettings from "./Components/AdDisplaySettings/AdDisplaySettings";
import AdformSettings from "./Components/AdformSettings/AdformSettings";

//CONFIGS
import tabs from "./config/tabs";
import PostReview from "./Components/PostReview/PostReview";

interface EditCampaignProps {
  setReloadData: (value: boolean) => void;
}

const EditCampaign = ({ setReloadData }: EditCampaignProps) => {
  const [activeTab, setActiveTab] = useState("main-settings");
  const { id, popupName, popupView, popupSectionView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  //set active tab on page load
  useEffect(() => {
    if (popupView !== "edit-campaign") return;
    const editCampaignViewExist = tabs.some(
      (tab) => tab.value === popupSectionView
    );
    if (editCampaignViewExist) {
      if (activeTab !== popupSectionView) {
        setActiveTab(popupSectionView ?? "main-settings");
      }
    } else {
      if (!id || !popupView || !activeTab) return;
      navigate(
        `/${
          location.pathname.split("/")[1]
        }/${popupName}/${id}/${popupView}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView, popupSectionView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(
      `/${
        location.pathname.split("/")[1]
      }/${popupName}/${id}/${popupView}/${tab}`
    );
  };

  return (
    <div className={styles.editCampaign}>
      <SectionSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
      {activeTab === "main-settings" && (
        <MainSettings setReloadData={setReloadData} />
      )}
      {activeTab === "post-review" && (
        <PostReview setReloadData={setReloadData} />
      )}
      {activeTab === "ad-display-settings" && (
        <AdDisplaySettings setReloadData={setReloadData} />
      )}
      {activeTab === "adform-settings" && (
        <AdformSettings setReloadData={setReloadData} />
      )}
    </div>
  );
};

export default EditCampaign;
