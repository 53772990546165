import ActionList from "./Components/ActionList/ActionList";
import GameState from "./Components/GameState/GameState";
import Leaderboard from "./Components/Leaderboard/Leaderboard";
import styles from "./EventActions.module.scss";

interface EventActionsProps {
  gameState: string;
  setGameState: React.Dispatch<React.SetStateAction<string>>;
}

const EventActions = (props: EventActionsProps) => {
  return (
    <div className={styles.eventActions}>
      <GameState
        gameState={props.gameState}
        setGameState={props.setGameState}
      />
      <hr />
      <Leaderboard />
      <hr />
      <ActionList />
    </div>
  );
};

export default EventActions;
