import GetCampaignPostReviewSettingsResponse from "model/response/campaign/getCampaignPostReviewSettingsResponse";
import styles from "./ChatMessageList.module.scss";
import { formatDate, toAbsoluteUrl } from "helpers";
import { useEffect, useState } from "react";
import { GetCampaignChatMessagesByKeywordResponseItem } from "model/response/campaign/getCampaignChatMessagesByKeywordResponse";
import Loading from "components/Shared/Loading/Loading";
import { toast } from "react-toastify";
import { createCampaignService } from "services/campaignService";
import PageOptions from "model/dto/pageOptions";
import EnumPlatform from "model/enum/enumPlatform";

interface ChatMessageListProps {
  editedCampaign: GetCampaignPostReviewSettingsResponse;
  setEditedCampaign: React.Dispatch<
    React.SetStateAction<GetCampaignPostReviewSettingsResponse | null>
  >;
  activeKeywordIndex: number;
  pageOptions: PageOptions;
  setPageOptions: (pageOptions: PageOptions) => void;
}

const ChatMessageList = ({
  editedCampaign,
  setEditedCampaign,
  activeKeywordIndex,
  pageOptions,
  setPageOptions,
}: ChatMessageListProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chatMessages, setChatMessages] = useState<
    GetCampaignChatMessagesByKeywordResponseItem[] | null
  >(null);
  const [messagePerPage, setMessagePerPage] = useState(15);
  const campaignService = createCampaignService();

  useEffect(() => {
    if (editedCampaign.keywords && editedCampaign.keywords.length > 0) {
      (async () => {
        try {
          setIsLoading(true);
          const response =
            await campaignService.getCampaignChatMessagesByKeyword({
              id: editedCampaign.id,
              keyword:
                editedCampaign.keywords[activeKeywordIndex] ??
                editedCampaign.keywords[0],
              take: messagePerPage,
              skip: pageOptions.page * messagePerPage,
            });
          setChatMessages(response.data);
          setPageOptions({
            ...pageOptions,
            hasNextPage:
              response.rowCount > (pageOptions.page + 1) * messagePerPage,
          });
          setIsLoading(false);
        } catch (error) {
          toast.error("Failed to fetch chat messages by keywords");
          setIsLoading(false);
        }
      })();
    }
  }, [editedCampaign.keywords, activeKeywordIndex, pageOptions.page]);

  if (isLoading) return <Loading />;
  if (!chatMessages) return null;

  return (
    <div className={styles.chatMessageList}>
      {chatMessages.map((message, index) => (
        <div key={message.id} className={styles.message}>
          <div className={styles.messageContent}>
            <div className={styles.messageSender}>
              <div className={styles.senderName}>
                <img
                  src={toAbsoluteUrl(
                    `/assets/icons/avatar${(index % 6) + 1}.png`
                  )}
                  alt="Sender"
                />
                {message.sender}
              </div>
              <div className={styles.keyword}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 4h-5V3h5v1zm-1 3h-2v1h2V7zm-4 0H1v1h9V7zm2 6H1v1h11v-1zm-5-3H1v1h6v-1zm8 0h-5v1h5v-1zM8 2v3H1V2h7zM7 3H2v1h5V3z" />
                </svg>
                <b>Keyword:</b> {message.keyword}
              </div>
            </div>
            <div className={styles.messageText}>{message.message}</div>
            <div className={styles.messageDetails}>
              <div className={styles.streamer}>
                <img src={message.streamer.logo ?? undefined} alt="Streamer" />
                <img
                  src={toAbsoluteUrl(
                    `/assets/icons/Platforms/${
                      message.streamer.platform === EnumPlatform.Twitch
                        ? "twitch-icon.png"
                        : message.streamer.platform === EnumPlatform.Youtube
                        ? "youtube-icon.png"
                        : message.streamer.platform === EnumPlatform.Trovo
                        ? "trovo-icon.png"
                        :message.streamer.platform === EnumPlatform.Kick ? "kick-icon.png" : undefined
                    }`
                  )}
                  alt="Platform"
                />
                {message.streamer.name}
              </div>
              <div className={styles.messageDate}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
                </svg>
                {message.date ? formatDate(new Date(message.date)) : undefined}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatMessageList;
