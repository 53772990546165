import EnumAssetType from "model/enum/enumAssetType";
import styles from "./CampaignAssetPreview.module.scss";
import { toAbsoluteUrl } from "helpers";

type Props = {
  assetUrl: string;
  assetType: EnumAssetType;
  pretext: string;
  redirectLink: string;
  displaySettings: {
    height?: string;
    width?: string;
    left: string;
    top: string;
  };
};
const CampaignAssetPreview = ({
  assetUrl,
  assetType,
  pretext,
  redirectLink,
  displaySettings,
}: Props) => {
  const convertVhToPercent = (vh: string) => {
    // check if the string is a valid vh value
    if (!vh.includes("vh")) return vh;
    // remove the vh part of the string
    const value = vh.slice(0, -2);
    // convert to percentage
    return `${value}%`;
  };

  return (
    <div className={styles.previewScreen}>
      <div className={styles.demoScreen}>
        <div className={styles.content}>
          {assetType === EnumAssetType.Image ? (
            <img
              src={assetUrl}
              alt="Asset"
              style={{
                width: displaySettings.width || "auto",
                left: displaySettings.left,
                top: convertVhToPercent(displaySettings.top),
                // transform: `translateY(-${convertVhToPercent(
                //   displaySettings.top
                // )})`,
                height: displaySettings.height || "auto",
              }}
            />
          ) : assetType === EnumAssetType.Video ? (
            <video
              key={assetUrl}
              autoPlay
              loop
              muted
              disablePictureInPicture
              playsInline
              style={{
                width: displaySettings.width || "auto",
                left: displaySettings.left,
                top: convertVhToPercent(displaySettings.top),
                height: displaySettings.height || "auto",
                // transform: `translateY(-${convertVhToPercent(
                //   displaySettings.top
                // )})`,
              }}
            >
              <source src={assetUrl} type="video/mp4" />
            </video>
          ) : null}
        </div>
      </div>
      <div className={styles.demoChat}>
        <div className={styles.demoUser}>
          <img src={toAbsoluteUrl("/assets/icons/chatBadge.png")} alt="Badge" />{" "}
          <span className={styles.username}>livadbot</span>: {pretext}
          <a target="_blank" rel="noreferrer" href={redirectLink}>
            livad.gg/r/f4thbs7
          </a>
        </div>
        <img
          className={styles.chatLoop}
          src={toAbsoluteUrl("/assets/icons/chatLoop.gif")}
          alt="Chat"
        />
      </div>
    </div>
  );
};

export default CampaignAssetPreview;
