import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "helpers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import classNames from "classnames";

//STYLES
import styles from "./FilterItem.module.scss";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import { Tooltip } from "@mui/material";
import EnumFilterReferrer from "model/enum/enumFilterReferrer";

interface FilterItemProps {
  filterItem: FilterItemDto;
  type: "searchable" | "static";
  handleSearchFilter: (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string,
    referrer?: EnumFilterReferrer
  ) => Promise<{ success: boolean; error: any }>;
  handleSelectFilterItem: (
    item: FilterItemResultsDto,
    fieldName: string
  ) => void;
}

const FilterItem = (props: FilterItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<
    FilterItemResultsDto[]
  >([]);

  const handleSearch = async () => {
    setIsLoading(true);
    const res = await props.handleSearchFilter(
      searchValue,
      props.filterItem.filterType,
      props.filterItem.fieldName,
      props.filterItem.referrer
    );
    if (!res.success) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedFilters = props.filterItem.results?.filter(
      (item) => item.selected
    );
    setSelectedFilters(selectedFilters);
  }, [props.filterItem.results]);

  const handleDropdownToggle = () => {
    if (props.filterItem.style === "toggle") return;
    setIsOpen(!isOpen);
  };

  const handleSelectToggleItem = () => {
    props.handleSelectFilterItem(
      props.filterItem.results[0],
      props.filterItem.fieldName
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.filterItem}>
        <div className={styles.filterTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"></path>
          </svg>
          {props.filterItem.title}
          {props.filterItem.tooltip && (
            <Tooltip title={props.filterItem.tooltip} arrow>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z" />
                <path d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z" />
              </svg>
            </Tooltip>
          )}
        </div>

        {props.filterItem.style === "select" && (
          <div className={styles.filterInput} onClick={handleDropdownToggle}>
            <span
              className={classNames(styles.inputPlaceholder, {
                [styles.text]: selectedFilters.length > 0,
              })}
            >
              {selectedFilters.length > 0
                ? `${selectedFilters.length} Item Selected`
                : "Select"}
            </span>
            <img
              src={toAbsoluteUrl("/assets/icons/downArrow.png")}
              alt="arrow-icon"
            />
          </div>
        )}
        {props.filterItem.style === "toggle" && (
          <div className={styles.filterToggle}>
            <ToggleSwitch
              value={props.filterItem.results[0].selected}
              setValue={handleSelectToggleItem}
            />
          </div>
        )}

        {isOpen && (
          <div className={styles.filterResult}>
            {props.type === "searchable" && (
              <div className={styles.filterSearch}>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <button className={styles.searchBtn} onClick={handleSearch}>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z"></path>
                  </svg>
                </button>
              </div>
            )}
            <div className={styles.itemList}>
              {isLoading && <Loading width="15" height="15" />}
              {!isLoading &&
                props.filterItem.results?.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <div
                      className={classNames(styles.checkbox, {
                        [styles.selected]: item.selected,
                      })}
                      onClick={() =>
                        props.handleSelectFilterItem(
                          item,
                          props.filterItem.fieldName
                        )
                      }
                    >
                      <img
                        src={toAbsoluteUrl("/assets/icons/checkIcon.png")}
                        alt="checked-icon"
                      />
                    </div>
                    {/* eslint-disable-next-line */}
                    {item.isLive == true && (
                      <svg
                        className={styles.liveIcon}
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={0}
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                    {item.logo && (
                      <img
                        className={
                          props.filterItem.iconRounded === "none"
                            ? styles.notRoundedLogo
                            : props.filterItem.iconRounded === "rounded"
                            ? styles.roundedLogo
                            : styles.logo
                        }
                        src={item.logo}
                        alt="logo"
                      />
                    )}
                    {item.platform && (
                      <img
                        className={styles.platform}
                        src={toAbsoluteUrl(
                          `/assets/icons/Platforms/${item.platform}-icon.png`
                        )}
                        alt="platform"
                      />
                    )}
                    <span className={styles.text} title={item.label}>
                      {item.label}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default FilterItem;
