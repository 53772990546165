import GetCampaignDetailResponse from "model/response/campaign/getCampaignDetailResponse";
import styles from "./CampaignInfoHeader.module.scss";
import { formatDate, formatNumber } from "helpers";
import EnumCampaignStatus from "model/enum/enumCampaignStatus";
import classNames from "classnames";

interface CampaignInfoHeaderProps {
  campaign: GetCampaignDetailResponse;
}

const CampaignInfoHeader = ({ campaign }: CampaignInfoHeaderProps) => {
  return (
    <div className={styles.campaignInfoHeader}>
      <div className={styles.campaignDetail}>
        <div className={styles.brand}>
          <div className={styles.logo}>
            <img src={campaign.client.logo || ""} alt="Logo" />
          </div>
          <div className={styles.section}>
            <div className={styles.brandName}>{campaign.client.name}</div>
            <div className={styles.campaignName}>{campaign.name}</div>
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.label}>
            <span
              className={classNames(styles.dot, {
                [styles.green]: campaign.status === EnumCampaignStatus.Active,
                [styles.orange]: campaign.status === EnumCampaignStatus.Pending,
                [styles.yellow]:
                  campaign.status === EnumCampaignStatus.In_Review,
                [styles.cyan]: campaign.status === EnumCampaignStatus.Draft,
              })}
            ></span>
            <span className={styles.statusText}>
              {campaign.status
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </span>
          </div>
          <div className={styles.date}>
            {`${
              campaign.startDate ? formatDate(campaign.startDate) : undefined
            } - ${campaign.endDate ? formatDate(campaign.endDate) : undefined}`}
            {campaign.startDate &&
              campaign.endDate &&
              campaign.status === EnumCampaignStatus.Active && (
                <span className={styles.timeLeft}>
                  {new Date(campaign.startDate) < new Date() &&
                  new Date(campaign.endDate) > new Date()
                    ? (
                        (new Date(campaign.endDate).getTime() -
                          new Date().getTime()) /
                        (1000 * 60 * 60 * 24)
                      ).toFixed(0) + " days left"
                    : new Date(campaign.endDate).getTime() <=
                      new Date().getTime()
                    ? "Last day"
                    : null}
                </span>
              )}
          </div>
        </div>
      </div>
      <div className={styles.progressList}>
        {(campaign.viewsGoal || campaign.viewsGoal === 0) &&
          (campaign.viewsRemaining || campaign.viewsRemaining === 0) && (
            <div className={styles.campaignProgress}>
              <div className={styles.progressTitles}>
                <div className={styles.title}>
                  Views Realized:{" "}
                  <span className={styles.value}>
                    {formatNumber(campaign.views)}
                  </span>
                </div>
                <div className={styles.title}>
                  Views Goal:{" "}
                  <span className={styles.value}>
                    {formatNumber(campaign.viewsGoal)}
                  </span>
                </div>
              </div>
              <div className={styles.progressBar}>
                <div
                  className={styles.progress}
                  style={{
                    width: `${
                      (campaign.views / campaign.viewsGoal) * 100 > 100
                        ? 100
                        : (campaign.views / campaign.viewsGoal) * 100
                    }%`,
                  }}
                ></div>
                <div className={styles.progressValue}>
                  {parseInt(`${(campaign.views / campaign.viewsGoal) * 100}`)}%
                </div>
              </div>
            </div>
          )}
        {(campaign.dailyViewsGoal || campaign.dailyViewsGoal === 0) &&
          (campaign.dailyViewsRealized ||
            campaign.dailyViewsRealized === 0) && (
            <div className={styles.campaignProgress}>
              <div className={styles.progressTitles}>
                <div className={styles.title}>
                  Views Realized Today:{" "}
                  <span className={styles.value}>
                    {formatNumber(campaign.dailyViewsRealized)}
                  </span>
                </div>
                <div className={styles.title}>
                  Daily View Goal:{" "}
                  <span className={styles.value}>
                    {formatNumber(campaign.dailyViewsGoal)}
                  </span>
                </div>
              </div>
              <div className={styles.progressBar}>
                <div
                  className={styles.progress}
                  style={{
                    width: `${
                      (campaign.dailyViewsRealized / campaign.dailyViewsGoal) *
                        100 >
                      100
                        ? 100
                        : (campaign.dailyViewsRealized /
                            campaign.dailyViewsGoal) *
                          100
                    }%`,
                  }}
                ></div>
                <div className={styles.progressValue}>
                  {parseInt(
                    `${
                      (campaign.dailyViewsRealized / campaign.dailyViewsGoal) *
                      100
                    }`
                  ) + "%"}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CampaignInfoHeader;
