import GetPayoutAccountsRequest from "model/request/payouts/getPayoutAccountsRequest";
import { createBaseApiService } from "./baseApiService";
import idRequest from "model/request/idRequest";
import GetInteractiveTriggersRequest from "model/request/interactiveTrigger/getInteractiveTriggersRequest";
import GetInteractiveTriggersResponse, {
  GetInteractiveTriggersResponseItem,
} from "model/response/interactiveTrigger/getInteractiveTriggersResponse";
import { EnumRequestType } from "model/enum/enumRequestType";

export const createInteractiveTriggersService = () => {
  const baseService = createBaseApiService("/interactiveTriggers");

  const get = async (req: GetInteractiveTriggersRequest) => {
    const response = await baseService.post<GetInteractiveTriggersResponse>(
      "/get",
      req
    );
    return response.data;
  };

  const getInteractiveTrigger = async (req: idRequest) => {
    const response = await baseService.post<GetInteractiveTriggersResponseItem>(
      "/getInteractiveTrigger",
      req
    );
    return response.data;
  };

  const exportInteractiveTriggersAsCSV = async (
    req: GetInteractiveTriggersRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportInteractiveTriggersAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_INTERACTIVE_TRIGGERS_${new Date()
            .toISOString()
            .replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    getInteractiveTrigger,
    exportInteractiveTriggersAsCSV,
  };
};
