enum EnumFilterType {
  Streamers = "streamers",
  Campaigns = "campaigns",
  Clients = "clients",
  Category = "categories",
  Country = "countries",
  Managers = "managers",
  Agencies = "agencies",
  Languages = "languages",
  Currency = "currencies",
  Owner = "owner",
}

export default EnumFilterType;
