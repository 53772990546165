import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";

//STYLES
import styles from "./ChatEngagementKeywords.module.scss";
import GetCampaignChatMessageCountByKeywordsResponse from "model/response/campaign/getCampaignChatMessageCountByKeywordsResponse";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import FilterItemDto from "model/dto/filterItemDto";
import GetCampaignChatMessageCountByKeywordsRequest from "model/request/campaign/getCampaignChatMessageCountByKeywordsRequest";

interface ChatEngagementKeywordsProps {
  filterList: FilterItemDto[];
  keywords: string[];
  activeKeywordIndex: number;
  setActiveKeywordIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ChatEngagementKeywords = ({
  filterList,
  keywords,
  activeKeywordIndex,
  setActiveKeywordIndex,
}: ChatEngagementKeywordsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageCountByKeywords, setMessageCountByKeywords] = useState<
    GetCampaignChatMessageCountByKeywordsResponse["keywords"] | null
  >(null);
  const { id } = useParams();

  const campaignService = createCampaignService();

  useEffect(() => {
    if (keywords && keywords.length > 0) {
      (async () => {
        if (!id) return;
        const postBody: any =
          {} as GetCampaignChatMessageCountByKeywordsRequest;
        filterList.forEach((filterItem) => {
          const selectedResults = filterItem.results.filter(
            (result) => result.selected
          );
          if (selectedResults.length > 0) {
            if (filterItem.style === "select") {
              postBody[filterItem.fieldName] = selectedResults.map(
                (result) => result.value
              );
            } else if (filterItem.style === "toggle") {
              postBody[filterItem.fieldName] = selectedResults[0].value;
            }
          }
        });
        postBody.id = parseInt(id);
        postBody.keywords = keywords;
        try {
          setIsLoading(true);
          const response =
            await campaignService.getCampaignChatMessageCountByKeywords(
              postBody
            );
          setMessageCountByKeywords(response.keywords);
          setIsLoading(false);
        } catch (error) {
          toast.error("Failed to fetch message count by keywords");
          setIsLoading(false);
        }
      })();
    }
  }, [filterList, keywords]);

  useEffect(() => {
    if (keywords && keywords.length < activeKeywordIndex + 1) {
      setActiveKeywordIndex(0);
    }
  }, [keywords]);

  if (!id) return null;

  return (
    <div className={styles.chatEngagementKeywords}>
      <div className={styles.selectedList}>
        {keywords.map((keyword, index) => (
          <div
            className={classNames(styles.listItem, {
              [styles.active]: activeKeywordIndex === index,
            })}
            key={index}
            onClick={() => setActiveKeywordIndex(index)}
          >
            {messageCountByKeywords &&
              messageCountByKeywords.find((x) => x.keyword === keyword) && (
                <span className={styles.count}>
                  {
                    messageCountByKeywords.find((x) => x.keyword === keyword)
                      ?.count
                  }
                  {!!messageCountByKeywords.find((x) => x.keyword === keyword)
                    ?.count && (
                    <span title="Unique Chatters">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                      {
                        messageCountByKeywords.find(
                          (x) => x.keyword === keyword
                        )?.uniqueChattersCount
                      }
                    </span>
                  )}
                </span>
              )}
            {keyword}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatEngagementKeywords;
