import styles from "./ContentTabs.module.scss";
import classNames from "classnames";

interface ContentTabsProps {
  contentTabs: { name: string }[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const ContentTabs = (props: ContentTabsProps) => {
  return (
    <div className={styles.contentTabs}>
      {props.contentTabs.map((tab, index) => (
        <div
          key={index}
          className={classNames(styles.tab, {
            [styles.active]: props.activeTab === tab.name,
          })}
          onClick={() => props.setActiveTab(tab.name)}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default ContentTabs;
