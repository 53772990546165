import { EnumRequestType } from "model/enum/enumRequestType";
import { createBaseApiService } from "./baseApiService";
import GetGameSessionsRequest from "model/request/gameSession/getGameSessionsRequest";
import GetGameSessionsResponse from "model/response/gameSession/getGameSessionsResponse";

export const createGameSessionService = () => {
  const baseService = createBaseApiService("/gameSessions");

  const get = async (req: GetGameSessionsRequest) => {
    const response = await baseService.post<GetGameSessionsResponse>(
      "/get",
      req
    );
    return response.data;
  };

  const exportGameSessionsAsCSV = async (
    req: GetGameSessionsRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportGameSessionsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_GAME_SESSIONS_${new Date()
            .toISOString()
            .replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    exportGameSessionsAsCSV,
  };
};
