import { useEffect, useState } from "react";

//STYLES
import styles from "./GameEventsPopup.module.scss";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";
import EventHeader from "./Components/EventHeader/EventHeader";
import EventActions from "./Components/EventActions/EventActions";
import ContentTabs from "./Components/ContentTabs/ContentTabs";

const contentTabs = [
  { name: "Questions" },
  { name: "Sessions" },
  { name: "Rankings" },
];

const GameEventsPopup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Questions");
  const [gameState, setGameState] = useState("Test");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.gameEventsPopup}>
      <EventHeader />
      <div className={styles.eventContainer}>
        <EventActions gameState={gameState} setGameState={setGameState} />
        <div className={styles.eventContent}>
          <ContentTabs
            contentTabs={contentTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === "Questions" && <div>Questions MUI Table Here</div>}
          {activeTab === "Sessions" && <div>Sessions MUI Table Here</div>}
          {activeTab === "Rankings" && <div>Rankings MUI Table Here</div>}
        </div>
      </div>
    </div>
  );
};

export default GameEventsPopup;
