import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//STYLES
import styles from "./InteractiveTriggersPopup.module.scss";

//CONFIGS
import interactiveTriggersPopupView from "./config/views/InteractiveTriggersPopupView";
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import Loading from "components/Shared/Loading/Loading";

//MODELS

//SERVICES
import { createInteractiveTriggersService } from "services/interactiveTriggersService";
import { GetInteractiveTriggersResponseItem } from "model/response/interactiveTrigger/getInteractiveTriggersResponse";

const InteractiveTriggersPopup = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoading, setIsLoading] = useState(true);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [interactiveTriggersRow, setInteractiveTriggersRow] = useState<
    Array<GetInteractiveTriggersResponseItem>
  >([]);

  const interactiveTriggersService = createInteractiveTriggersService();

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  const fetchInteractiveTrigger = async () => {
    if (!id) return;
    try {
      const interactiveTrigger =
        await interactiveTriggersService.getInteractiveTrigger({
          id: Number(id),
        });
      setInteractiveTriggersRow([interactiveTrigger]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch Interactive Trigger");
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchInteractiveTrigger();
  }, [id]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.interactiveTriggersPopup}>
      <CustomDataGrid
        view={interactiveTriggersPopupView}
        rows={interactiveTriggersRow}
        rowCount={1}
        isLoading={isLoading}
        minHeight="auto"
      />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
    </div>
  );
};

export default InteractiveTriggersPopup;
