import targetAudienceView from "./targetAudienceView";
import adAnalyticsView from "./adAnalyticsView";
import campaignSetupView from "./campaignSetupView";
import postReviewView from "./postReviewView";
import streamerManagementView from "./streamerManagementView";
import adDetectionAndScreenshotsView from "./adDetectionAndScreenshotsView";

const campaignViews = [
  adAnalyticsView,
  campaignSetupView,
  targetAudienceView,
  postReviewView,
  streamerManagementView,
  adDetectionAndScreenshotsView,
];

export default campaignViews;
