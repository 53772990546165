import { useEffect, useState } from "react";

//STYLES
import styles from "./Screenshots.module.scss";

//CONFIGS
import initialFilterList from "./config/screenshotsFilterOptions";

//COMPONENTS
import CustomFilterList, {
  searchFilter,
  selectFilterItem,
  clearFilterList,
} from "components/CustomFilterList/CustomFilterList";
import ScreenshotList from "./Components/ScreenshotList/ScreenshotList";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";
import PageOptions from "model/dto/pageOptions";
import Pagination from "components/Shared/Pagination/Pagination";

const Screenshots = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterList, setFilterList] =
    useState<FilterItemDto[]>(initialFilterList);
  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 0,
    hasNextPage: false,
  });

  useEffect(() => {
    // fetch data
    setIsLoading(false);
  }, []);

  const handleSearchFilter = async (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string
  ) => {
    return await searchFilter(
      value,
      filterType,
      fieldName,
      filterList,
      setFilterList
    );
  };

  const handleSelectFilterItem = (
    item: FilterItemResultsDto,
    fieldName: string
  ) => {
    selectFilterItem(item, fieldName, filterList, setFilterList);
  };

  return (
    <div className={styles.screenshots}>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ScreenshotList
        filterList={filterList}
        pageOptions={pageOptions}
        setPageOptions={setPageOptions}
      />
      <Pagination pageOptions={pageOptions} setPageOptions={setPageOptions} />
    </div>
  );
};

export default Screenshots;
