const tabs = [
  {
    title: "Main Settings",
    value: "main-settings",
  },
  {
    title: "Post Review",
    value: "post-review",
  },
  {
    title: "Ad Display Settings",
    value: "ad-display-settings",
  },
  {
    title: "Adform Settings",
    value: "adform-settings",
  },
];
export default tabs;
