import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
//STYLES
import styles from "./CampaignsPopup.module.scss";

//CONFIGS
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import EditCampaign from "./Components/EditCampaign/EditCampaign";
import RequestedStreamers from "./Components/RequestedStreamers/RequestedStreamers";
import OtherStreamers from "./Components/OtherStreamers/OtherStreamers";
import Screenshots from "./Components/Screenshots/Screenshots";
import PostAd from "./Components/PostAd/PostAd";
import ChatEngagement from "./Components/ChatEngagement/ChatEngagement";

//MODELS

//SERVICES
import { createCampaignService } from "services/campaignService";
import { toast } from "react-toastify";
import CampaignInfo from "./Components/CampaignInfo/CampaignInfo";
import GetCampaignDetailResponse from "model/response/campaign/getCampaignDetailResponse";
import Loading from "components/Shared/Loading/Loading";

const CampaignsPopup = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoading, setIsLoading] = useState(true);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [campaign, setCampaign] = useState<GetCampaignDetailResponse>();
  const [reloadData, setReloadData] = useState(false);

  const campaignService = createCampaignService();

  const fetchCampaignDetail = async () => {
    if (!id) return;
    try {
      setIsLoading(true);
      const campaign = await campaignService.getDetail({
        id: Number(id),
      });
      setCampaign(campaign);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch campaign detail");
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchCampaignDetail();
  }, [id]);

  useEffect(() => {
    const handleReloadData = async () => {
      await fetchCampaignDetail();
      setReloadData(false);
    };

    if (reloadData) {
      handleReloadData();
    }
  }, [reloadData]);

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  if (isLoading)
    return (
      <div className={styles.campaignsPopup}>
        <Loading />
      </div>
    );

  if (!campaign) return null;

  return (
    <div className={styles.campaignsPopup}>
      <CampaignInfo campaign={campaign} />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
      {activeTab === "edit-campaign" && (
        <EditCampaign setReloadData={setReloadData} />
      )}
      {activeTab === "requested-streamers" && (
        <RequestedStreamers setReloadData={setReloadData} />
      )}
      {activeTab === "other-streamers" && (
        <OtherStreamers setReloadData={setReloadData} />
      )}
      {activeTab === "screenshots" && <Screenshots />}
      {activeTab === "chat-engagement" && <ChatEngagement />}
      {activeTab === "post-ad" && <PostAd />}
    </div>
  );
};

export default CampaignsPopup;
