import {
  createAdsApiBaseService,
  createBaseApiService,
} from "./baseApiService";
import GetAdsRequest from "model/request/ads/getAdsRequest";
import GetAdsResponse from "model/response/ads/getAdsResponse";
import GetAdDetailResponse from "model/response/ads/getAdDetailResponse";
import IdRequest from "model/request/idRequest";
import AdChatMessageDto from "model/dto/ad/adChatMessageDto";
import AdSubtitleDto from "model/dto/ad/adSubtitleDto";
import GetAdTranscriptsResponse from "model/response/ads/getAdTranscriptsResponse";
import { EnumRequestType } from "model/enum/enumRequestType";
import PostAdRequest from "model/request/ads/postAdRequest";

export const createAdsService = () => {
  const baseService = createBaseApiService("/ads");
  const adsApiService = createAdsApiBaseService("/ads");

  const get = async (req: GetAdsRequest) => {
    const response = await baseService.post<GetAdsResponse>("/get", req);
    return response.data;
  };

  const getDetail = async (req: IdRequest) => {
    const response = await baseService.post<GetAdDetailResponse>(
      `/getDetail`,
      req
    );
    return response.data;
  };

  const getChatMessages = async (req: IdRequest) => {
    const response = await baseService.post<AdChatMessageDto[]>(
      `/getChatMessages`,
      req
    );
    return response.data;
  };

  const getAdSubtitles = async (req: IdRequest) => {
    const response = await baseService.post<AdSubtitleDto[]>(
      `/getAdSubtitles`,
      req
    );
    return response.data;
  };

  const getAdTranscripts = async (req: IdRequest) => {
    const response = await baseService.post<GetAdTranscriptsResponse>(
      `/getAdTranscripts`,
      req
    );
    return response.data;
  };

  const exportAdsAsCSV = async (req: GetAdsRequest, fileName?: string) => {
    const response = await baseService.post<string>(
      "/exportAdsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_ADS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const postAd = async (req: PostAdRequest) => {
    const response = await adsApiService.post<void>("/new", req);
    return response.data;
  };

  return {
    get,
    getDetail,
    getChatMessages,
    getAdSubtitles,
    getAdTranscripts,
    exportAdsAsCSV,
    postAd,
  };
};
