import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//STYLES
import styles from "./PageSwitcher.module.scss";

interface PageSwitcherProps {
  lastAdId: number;
}

const PageSwitcher = ({ lastAdId }: PageSwitcherProps) => {
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const url = window.location.pathname;
    const page = url.split("/")[1];
    setCurrentPage(page);
  }, [window.location.pathname]);

  function nextPage() {
    if (!id) return;
    navigate(`/${currentPage}/ads-popup/${parseInt(id) + 1}/labelling`);
  }
  function prevPage() {
    if (!id) return;
    navigate(`/${currentPage}/ads-popup/${parseInt(id) - 1}/labelling`);
  }

  if (!id) return null;
  return (
    <div className={styles.pageSwitcher}>
      <button className={styles.actionBtn} onClick={prevPage}>
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />{" "}
        </svg>
      </button>
      {lastAdId === parseInt(id) ? null : (
        <button className={styles.actionBtn} onClick={nextPage}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 448 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />{" "}
          </svg>
        </button>
      )}
    </div>
  );
};

export default PageSwitcher;
