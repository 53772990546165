import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//STYLES
import styles from "./PostAd.module.scss";

//COMPONENTS
import CustomFilterList, {
  searchFilter,
  selectFilterItem,
  clearFilterList,
} from "components/CustomFilterList/CustomFilterList";
import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import DragAndDrop from "./Components/DragAndDrop/DragAndDrop";
import ConfirmPopup from "components/ConfirmPopup/ConfirmPopup";

//CONFIGS
import initialFilterList from "./config/postAdFilterOptions";

//SERVICES
import { createCampaignService } from "services/campaignService";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";
import AdSettingDto from "model/dto/ad/AdSettingDto";
import GetCampaignPostAdSettingsResponse from "model/response/campaign/getCampaignPostAdSettingsResponse";
import EnumAdMethod from "model/enum/enumAdMethod";
import PostAdRequest from "model/request/ads/postAdRequest";
import { createAdsService } from "services/adsService";
import EnumFilterReferrer from "model/enum/enumFilterReferrer";

const PostAd = () => {
  const [filterList, setFilterList] =
    useState<FilterItemDto[]>(initialFilterList);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [assetLoading, setAssetLoading] = useState(true);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [assetData, setAssetData] =
    useState<GetCampaignPostAdSettingsResponse | null>(null);
  const [postAdSettings, setPostAdSettings] = useState<AdSettingDto>(
    {} as AdSettingDto
  );
  const { id } = useParams();
  const campaignsService = createCampaignService();

  useEffect(() => {
    (async () => {
      if (!id) return;
      setIsLoading(true);
      try {
        const response = await campaignsService.getPostAdSettings({
          id: parseInt(id),
        });
        setAssetData(response);
        if (!response.asset) {
          setAssetLoading(false);
        }
        setPostAdSettings({
          ...postAdSettings,
          leftPadding: response.leftPadding,
          topPadding: response.topPadding,
          width: response.width,
          adMethod: EnumAdMethod.ManualAdmin,
        });
      } catch {
        toast.error("Failed to fetch asset data");
      }
    })();
  }, []);

  const handleSearchFilter = async (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string,
    referrer?: EnumFilterReferrer
  ) => {
    if (!id) return { success: false, error: "No campaign id" };
    return await searchFilter(
      value,
      filterType,
      fieldName,
      filterList,
      setFilterList,
      referrer,
      parseInt(id)
    );
  };

  const handleSelectFilterItem = (
    item: FilterItemResultsDto,
    fieldName: string
  ) => {
    selectFilterItem(item, fieldName, filterList, setFilterList);
  };
  const handleClearFilterList = () => {
    clearFilterList(initialFilterList, setFilterList);
  };

  const handleSendAd = async () => {
    if (!id) return;
    setDisabled(true);
    const filters: any = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          if (filterItem.multiSelect) {
            filters[filterItem.fieldName] = selectedResults.map(
              (result) => result
            );
          } else {
            filters[filterItem.fieldName] = selectedResults[0];
          }
        } else if (filterItem.style === "toggle") {
          filters[filterItem.fieldName] = selectedResults[0];
        }
      }
    });
    if (!filters.streamerId) {
      setDisabled(false);
      return toast.error("Please select a streamer");
    }
    const toastId = toast.loading("Sending ad");
    const adsService = createAdsService();
    const failedStreamers: string[] = [];
    const successStreamers: string[] = [];
    for (const streamer of filters.streamerId) {
      const postBody: PostAdRequest = {
        adSetting: postAdSettings,
        campaignId: parseInt(id),
        streamerId: streamer.value,
      };
      try {
        await adsService.postAd(postBody);
        successStreamers.push(streamer.label);
      } catch {
        failedStreamers.push(streamer.label);
      }
    }
    toast.done(toastId);
    if (failedStreamers.length > 0) {
      toast.error(
        `Failed to send ad to streamers: ${failedStreamers.join(", ")}`
      );
      if(successStreamers.length > 0) {
        toast.success(`Ad sent to streamers: ${successStreamers.join(", ")}`);
      }
    } else {
      toast.success("Ad sent to selected streamers");
    }
    setDisabled(false);
  };

  return (
    <>
      {showConfirmPopup && (
        <ConfirmPopup
          title="Are you sure you want to send this ad to the selected streamers?"
          setShowConfirmPopup={setShowConfirmPopup}
          onConfirm={handleSendAd}
        />
      )}
      <div className={styles.postAd}>
        <CustomFilterList
          filterList={filterList}
          handleSearchFilter={handleSearchFilter}
          handleSelectFilterItem={handleSelectFilterItem}
        />
        <div className={styles.wrapper}>
          <DragAndDrop
            assetData={assetData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            assetLoading={assetLoading}
            setAssetLoading={setAssetLoading}
            postAdSettings={postAdSettings}
            setPostAdSettings={setPostAdSettings}
          />
        </div>
        <CustomForm>
          <CustomFormItem title="">
            <button
              disabled={isLoading || disabled}
              onClick={() => setShowConfirmPopup(true)}
              style={{ marginLeft: "unset" }}
            >
              Post Ad
            </button>
          </CustomFormItem>
        </CustomForm>
      </div>
    </>
  );
};

export default PostAd;
