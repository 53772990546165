import styles from "./LiveConsole.module.scss";
//COMPONENTS
import ScheduledItem from "./Components/ScheduledItem/ScheduledItem";
import { useEffect } from "react";
import { useLiveConsoleContext } from "context/LiveConsoleContext";
import Loading from "components/Shared/Loading/Loading";

const LiveConsole = () => {
  document.title = "Live Console - LIVAD for Team";

  const {
    fetchScheduledAds,
    fetchPostedAds,
    scheduledAdsLoading,
    postedAdsLoading,
    scheduledAds,
    postedAds,
    reloadData,
    setReloadData,
  } = useLiveConsoleContext();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const fetchData = async () => {
      await Promise.all([fetchScheduledAds(), fetchPostedAds()]);
      timeout = setTimeout(fetchData, 1000 * 60 * 3);
    };

    fetchData();

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (reloadData) {
      (async () => {
        await Promise.all([fetchScheduledAds(), fetchPostedAds()]);
        setReloadData(false);
      })();
    }
  }, [reloadData]);

  return (
    <div className={styles.liveConsole}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            role="img"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m8.727 1.554 2.727 4.334v16.558h1.091V5.889l2.728-4.335zm-6 4.948V9.8h7.091c.003-.83 0-1.672-.006-2.498 0-.383-.356-.732-.718-.8H2.727zm12.303.001c-.402.024-.835.41-.834.837l-.014 12.619c0 .57.767 1.065 1.207.727l8.28-6.331c.441-.335.44-1.12 0-1.455l-8.265-6.287a.553.553 0 0 0-.374-.11zM-.001 12v3.299h9.818V12zm4.363 5.497v3.3h5.455v-3.3z" />
          </svg>
          Live Console
        </div>
      </div>
      <div className={styles.containerSection}>
        <section className={styles.contentSection}>
          <div className={styles.contentTitle}>Upcoming Scheduled Ads</div>
          {scheduledAdsLoading && <Loading />}
          {scheduledAds.length > 0 && (
            <div className={styles.itemList}>
              {scheduledAds.map((item) => (
                <ScheduledItem key={item.id} item={item} />
              ))}
            </div>
          )}
        </section>
        <section className={styles.contentSection}>
          <div className={styles.contentTitle}>Posted Ads</div>
          {postedAdsLoading && <Loading />}
          {postedAds.length > 0 && (
            <div className={styles.itemList}>
              {postedAds.map((item) => (
                <ScheduledItem key={item.id} item={item} />
              ))}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default LiveConsole;
