import { createBaseApiService } from "./baseApiService";
import GetScheduledAdsRequest from "model/request/liveConsole/getScheduledAdsRequest";
import GetScheduledAdsResponse from "model/response/liveConsole/getScheduledAdsResponse";
import GetPostedAdsRequest from "model/request/liveConsole/getPostedAdsRequest";
import GetPostedAdsResponse from "model/response/liveConsole/getPostedAdsResponse";

export const createLiveConsoleService = () => {
  const baseService = createBaseApiService("/liveConsole");

  const getScheduledAds = async (req: GetScheduledAdsRequest) => {
    const response = await baseService.post<GetScheduledAdsResponse>(
      "/getScheduledAds",
      req
    );
    return response.data;
  };
  const getPostedAds = async (req: GetPostedAdsRequest) => {
    const response = await baseService.post<GetPostedAdsResponse>(
      "/getPostedAds",
      req
    );
    return response.data;
  };

  return {
    getScheduledAds,
    getPostedAds,
  };
};
