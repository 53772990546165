import EnumCampaignStatus from "model/enum/enumCampaignStatus";
import styles from "./StatusCircle.module.scss";
import classNames from "classnames";
import EnumPayoutRequestStatus from "model/enum/enumPayoutRequestStatus";
import EnumPayoutAccountStatus from "model/enum/enumPayoutAccountStatus";
import EnumGameSessionStatus from "model/enum/enumGameSessionStatus";
import EnumDonationStatus from "model/enum/enumDonationStatus";
import EnumCampaignHasStreamerStatus from "model/enum/enumCampaignHasStreamerStatus";

interface StatusCircleProps {
  status?:
    | EnumCampaignStatus
    | EnumPayoutRequestStatus
    | EnumPayoutAccountStatus
    | EnumGameSessionStatus
    | EnumDonationStatus
    | EnumCampaignHasStreamerStatus;
}

const StatusCircle = (props: StatusCircleProps) => {
  return (
    <div
      className={classNames(styles.statusCircle, {
        [styles.green]:
          props.status === EnumCampaignStatus.Active ||
          props.status === EnumPayoutRequestStatus.Completed ||
          props.status === EnumPayoutAccountStatus.Approved ||
          props.status === EnumGameSessionStatus.Ready ||
          props.status === EnumDonationStatus.APPROVED ||
          props.status === EnumCampaignHasStreamerStatus.Accepted,
        [styles.orange]:
          props.status === EnumCampaignStatus.Pending ||
          props.status === EnumPayoutRequestStatus.Requested ||
          props.status === EnumPayoutAccountStatus.WaitingForApproval ||
          props.status === EnumGameSessionStatus.InProgress ||
          props.status === EnumDonationStatus.PENDING ||
          props.status === EnumCampaignHasStreamerStatus.Requested,
        [styles.yellow]:
          props.status === EnumCampaignStatus.In_Review ||
          props.status === EnumDonationStatus.PROCESSING,
        [styles.cyan]:
          props.status === EnumCampaignStatus.Draft ||
          props.status === EnumPayoutRequestStatus.WaitingForInvoice,
        [styles.red]:
          props.status === EnumPayoutRequestStatus.Denied ||
          props.status === EnumPayoutAccountStatus.Denied ||
          props.status === EnumGameSessionStatus.Cancelled ||
          props.status === EnumDonationStatus.FAILED ||
          props.status === EnumCampaignHasStreamerStatus.Denied,
        [styles.pink]: props.status === EnumDonationStatus.REJECTED,
        [styles.primary]: props.status === EnumPayoutRequestStatus.Accepted ||
        props.status === EnumCampaignHasStreamerStatus.Excluded,
      })}
    ></div>
  );
};

export default StatusCircle;
