import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'

// COMPONENTS
// STYLES
import styles from './QuickMenu.module.scss'
//QUICK ROUTES
import {quickList} from './quickList'
// CONTEXT

const QuickMenu = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <div className={styles.quickMenu}>
      {/*QUICK MENU ITEMS*/}
      {quickList.map(
        (item, index) =>
          item.isEnabled && (
            <div className={styles.quickMenuItem} key={index} onClick={() => navigate(item.path)}>
              <img src={toAbsoluteUrl(`/assets/icons/Header/${item.icon}.png`)} alt='icon' />
            </div>
          )
      )}
    </div>
  )
}

export default QuickMenu
