import GetGameEventsRequest from "model/request/gameEvent/getGameEventsRequest";
import { createFansApiBaseService } from "./baseApiService";
import GetGameEventsResponse from "model/response/gameEvent/getGameEventsResponse";
import { EnumRequestType } from "model/enum/enumRequestType";

export const createGameEventsService = () => {
  const baseService = createFansApiBaseService("/team/integration/gameEvent");

  const get = async (req: GetGameEventsRequest) => {
    const response = await baseService.post<GetGameEventsResponse>(
      "/getGameEvents",
      req
    );
    return response.data;
  };

  const exportGameEventsAsCSV = async (
    req: GetGameEventsRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportGameEventsAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_GAME_EVENTS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    exportGameEventsAsCSV,
  };
};
