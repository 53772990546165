import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import styles from "./ExportCsvPopup.module.scss";
import { useContext, useState } from "react";
import { AdsContext } from "context/AdsContext";
import { CampaignsContext } from "context/CampaignsContext";
import { AgenciesContext } from "context/AgenciesContext";
import { StreamersContext } from "context/StreamersContext";
import { PayoutsContext } from "context/PayoutsContext";
import { PayoutAccountsContext } from "context/PayoutAccountsContext";
import { InteractiveTriggersContext } from "context/InteractiveTriggersContext";
import { GameSessionsContext } from "context/GameSessionsContext";
import { ClientsContext } from "context/ClientsContext";
import { DonationsContext } from "context/DonationsContext";
import { GameEventsContext } from "context/GameEventsContext";

const ExportCsvPopup = () => {
  const [fileName, setFileName] = useState("");
  const [rowCount, setRowCount] = useState(100);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const adsContext = useContext(AdsContext);
  const campaignsContext = useContext(CampaignsContext);
  const agenciesContext = useContext(AgenciesContext);
  const streamersContext = useContext(StreamersContext);
  const payoutsContext = useContext(PayoutsContext);
  const payoutAccountsContext = useContext(PayoutAccountsContext);
  const interactiveTriggersContext = useContext(InteractiveTriggersContext);
  const gameSessionsContext = useContext(GameSessionsContext);
  const clientsContext = useContext(ClientsContext);
  const donationsContext = useContext(DonationsContext);
  const gameEventsContext = useContext(GameEventsContext);

  const handleExport = async () => {
    setLoading(true);
    if (!id)
      return toast.error(
        "Please provide a valid 'from' state when navigating to this page"
      );
    switch (id) {
      case "ads":
        await adsContext?.exportAds(rowCount, fileName);
        break;
      case "campaigns":
        await campaignsContext?.exportCampaigns(rowCount, fileName);
        break;
      case "agencies":
        await agenciesContext?.exportAgencies(rowCount, fileName);
        break;
      case "streamers":
        await streamersContext?.exportStreamers(rowCount, fileName);
        break;
      case "payouts":
        await payoutsContext?.exportPayouts(rowCount, fileName);
        break;
      case "payout-accounts":
        await payoutAccountsContext?.exportPayoutAccounts(rowCount, fileName);
        break;
      case "interactive-triggers":
        await interactiveTriggersContext?.exportInteractiveTriggers(
          rowCount,
          fileName
        );
        break;
      case "game-sessions":
        await gameSessionsContext?.exportGameSessions(rowCount, fileName);
        break;
      case "clients":
        await clientsContext?.exportClients(rowCount, fileName);
        break;
      case "donations":
        await donationsContext?.exportDonations(rowCount, fileName);
        break;
      case "game-events":
        await gameEventsContext?.exportGameEvents(rowCount, fileName);
        break;
      default:
        toast.error(
          "Please provide a valid 'from' state when navigating to this page"
        );
        break;
    }
    setLoading(false);
  };

  return (
    <div className={styles.exportCsvPopup}>
      <div className={styles.pageTitle}>Export as CSV</div>
      <div className={styles.line}>
        <div className={styles.formItem} style={{ width: "fit-content" }}>
          <div className={styles.title}>Rows</div>
          <select
            style={{ width: "fit-content" }}
            onChange={(e) => {
              setRowCount(parseInt(e.target.value));
            }}
          >
            {[100, 200, 500, 1000, 2000, 5000, 10000].map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.formItem}>
          <div className={styles.title}>
            File Name
            <span className={styles.optional}>(optional)</span>
          </div>
          <input
            type="text"
            placeholder="Optional"
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            value={fileName}
          />
        </div>
      </div>
      <div className={styles.line}>
        <button
          className={styles.downloadBtn}
          onClick={handleExport}
          disabled={loading}
        >
          Download CSV
        </button>
      </div>
    </div>
  );
};

export default ExportCsvPopup;
