import CustomForm from "components/CustomForm/CustomForm";
import CustomFormItem from "components/CustomForm/Components/CustomFormItem/CustomFormItem";
import GetStreamerDetailResponse from "model/response/streamers/getStreamerDetailResponse";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createStreamersService } from "services/streamersService";
import UpdateStreamerRequest from "model/request/streamer/updateStreamerRequest";
import { createFilterService } from "services/filterService";
import EnumFilterType from "model/enum/enumFilterType";
import { SearchFilterResponseItem } from "model/response/filter/searchFilterResponse";
import Loading from "components/Shared/Loading/Loading";
import AddableDataList from "components/AddableDataList/AddableDataList";

interface EditStreamerProps {
  streamer: GetStreamerDetailResponse;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditStreamer = ({ streamer, setRefreshData }: EditStreamerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatedStreamer, setUpdatedStreamer] =
    useState<GetStreamerDetailResponse>(streamer);
  const [isUpdated, setIsUpdated] = useState(false);
  const streamerService = createStreamersService();
  const [currencies, setCurrencies] = useState<SearchFilterResponseItem[]>([]);
  const filterService = createFilterService();

  const handleValueChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    let value: any = e.target.value;
    if (e.target.value === "default-select-value") value = null;
    else if (e.target.type === "number") {
      value = parseInt(e.target.value);
    } else {
      value =
        e.target.value === "yes"
          ? 1
          : e.target.value === "no"
          ? 0
          : e.target.value;
    }
    setUpdatedStreamer({
      ...updatedStreamer,
      [e.target.name]: value,
    });
  };

  const createSerializedPostBody = (
    updatedStreamer: GetStreamerDetailResponse
  ): UpdateStreamerRequest => {
    let postBody: any = {};
    Object.entries(updatedStreamer).forEach(([key, value]) => {
      if (key === "automatedStartDelay") {
        return;
      } else if (
        typeof value === "number" &&
        value !== null &&
        value !== undefined
      ) {
        postBody = {
          ...postBody,
          [key]: value === 1 ? true : value === 0 ? false : value,
        };
      } else {
        postBody = {
          ...postBody,
          [key]: value,
        };
      }
    });
    postBody = {
      id: streamer.id,
      adsOn:
        streamer.adsOn !== updatedStreamer.adsOn ? postBody.adsOn : undefined,
      automatedAdsOn:
        streamer.automatedAdsOn !== updatedStreamer.automatedAdsOn
          ? postBody.automatedAdsOn
          : undefined,
      automatedStartDelay:
        streamer.automatedStartDelay !== updatedStreamer.automatedStartDelay
          ? postBody.automatedStartDelay
          : undefined,
      currencyId:
        streamer.currencyId !== updatedStreamer.currencyId
          ? postBody.currencyId
          : undefined,
      isBanned:
        streamer.isBanned !== updatedStreamer.isBanned
          ? postBody.isBanned
          : undefined,
      banNotes:
        streamer.banNotes !== updatedStreamer.banNotes
          ? postBody.banNotes
          : undefined,
      signUpCompleted:
        streamer.signUpCompleted !== updatedStreamer.signUpCompleted
          ? postBody.signUpCompleted
          : undefined,
      isManager:
        streamer.isManager !== updatedStreamer.isManager
          ? postBody.isManager
          : undefined,
      managedStreamers: streamer.managedStreamers.map((x)=> x.value) !== updatedStreamer.managedStreamers.map((x) => x.value) ? postBody.managedStreamers.map((x: SearchFilterResponseItem) => x.value) : undefined,
    };
    Object.keys(postBody).forEach(
      (key) => postBody[key] === undefined && delete postBody[key]
    );
    return postBody;
  };

  const handleUpdateStreamer = async () => {
    setIsLoading(true);
    const postBody = createSerializedPostBody(updatedStreamer);
    let error = false;
    Object.entries(postBody).forEach(([key, value]) => {
      if (value === null) {
        error = true;
        return toast.error("Please fill all fields to update Streamer");
      }
    });
    if (error) {
      setIsLoading(false);
      return;
    }
    try {
      await streamerService.updateStreamer(postBody);
      toast.success("Streamer updated successfully");
      setIsLoading(false);
      setRefreshData(true);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to update Streamer");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [currencyData] = await Promise.all([
        filterService.get({
          type: EnumFilterType.Currency,
          search: "",
        }),
      ]);
      setCurrencies(currencyData.data);
      setIsLoading(false);
    };
    if (isLoading) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(streamer) !== JSON.stringify(updatedStreamer)) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  }, [updatedStreamer]);

  if (!streamer) return null;
  if (isLoading) return <Loading />;
  return (
    <CustomForm>
      <CustomFormItem title="Ads On">
        <select
          name="adsOn"
          value={
            updatedStreamer.adsOn !== null
              ? updatedStreamer.adsOn === 1
                ? "yes"
                : updatedStreamer.adsOn === 0
                ? "no"
                : undefined
              : undefined
          }
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </CustomFormItem>
      <CustomFormItem title="Automated Ads On">
        <select
          name="automatedAdsOn"
          value={
            updatedStreamer.automatedAdsOn !== null
              ? updatedStreamer.automatedAdsOn === 1
                ? "yes"
                : updatedStreamer.automatedAdsOn === 0
                ? "no"
                : undefined
              : undefined
          }
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </CustomFormItem>
      <CustomFormItem title="Automated Start Delay">
        <input
          type="number"
          name="automatedStartDelay"
          placeholder="Automated Start Delay"
          value={updatedStreamer.automatedStartDelay}
          onChange={handleValueChange}
        />
      </CustomFormItem>
      <CustomFormItem title="Currency">
        <select
          name="currencyId"
          value={updatedStreamer.currencyId}
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          {currencies.map((currency) => (
            <option key={currency.value} value={currency.value}>
              {currency.label}
            </option>
          ))}
        </select>
      </CustomFormItem>
      <CustomFormItem title="Is Banned">
        <select
          name="isBanned"
          value={
            updatedStreamer.isBanned !== null
              ? updatedStreamer.isBanned === 1
                ? "yes"
                : updatedStreamer.isBanned === 0
                ? "no"
                : undefined
              : undefined
          }
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </CustomFormItem>
      <CustomFormItem title="Ban Note">
        <textarea
          name="banNotes"
          placeholder="Ban Note"
          value={
            updatedStreamer.banNotes ? updatedStreamer.banNotes : undefined
          }
          onChange={handleValueChange}
        ></textarea>
      </CustomFormItem>
      <CustomFormItem title="Signup Completed">
        <select
          name="signUpCompleted"
          value={
            updatedStreamer.signUpCompleted !== null
              ? updatedStreamer.signUpCompleted === 1
                ? "yes"
                : updatedStreamer.signUpCompleted === 0
                ? "no"
                : undefined
              : undefined
          }
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </CustomFormItem>
      <CustomFormItem title="Is Manager">
        <select
          name="isManager"
          value={
            updatedStreamer.isManager !== null
              ? updatedStreamer.isManager === 1
                ? "yes"
                : updatedStreamer.isManager === 0
                ? "no"
                : undefined
              : undefined
          }
          onChange={handleValueChange}
        >
          <option value="default-select-value">Select</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </CustomFormItem>
      {updatedStreamer.isManager === 1 && (
        <AddableDataList
          entityList={updatedStreamer.managedStreamers}
          searchType={EnumFilterType.Streamers}
          onSelect={(entity) => {
            if (
              !updatedStreamer.managedStreamers.find(
                (x) => x.value === entity.value
              )
            ) {
              setUpdatedStreamer({
                ...updatedStreamer,
                managedStreamers: [...updatedStreamer.managedStreamers, entity],
              });
            }
          }}
          onRemove={(entity) => {
            setUpdatedStreamer({
              ...updatedStreamer,
              managedStreamers: updatedStreamer.managedStreamers.filter(
                (x) => x.value !== entity.value
              ),
            });
          }}
        />
      )}
      {isUpdated && (
        <button disabled={isLoading} onClick={handleUpdateStreamer}>
          Save Changes
        </button>
      )}
    </CustomForm>
  );
};

export default EditStreamer;
