import IdRequest from "model/request/idRequest";
import { createBaseApiService } from "./baseApiService";
import GetStreamersRequest from "model/request/streamer/getStreamersRequest";
import GetStreamersResponse from "model/response/streamers/getStreamersResponse";
import GetStreamerDetailResponse from "model/response/streamers/getStreamerDetailResponse";
import UpdateStreamerRequest from "model/request/streamer/updateStreamerRequest";
import UpdateResponse from "model/response/updateResponse";
import { EnumRequestType } from "model/enum/enumRequestType";

export const createStreamersService = () => {
  const baseService = createBaseApiService("/streamers");

  const get = async (req: GetStreamersRequest) => {
    const response = await baseService.post<GetStreamersResponse>("/get", req);
    return response.data;
  };
  const getDetail = async (req: IdRequest<string>) => {
    const response = await baseService.post<GetStreamerDetailResponse>(
      "/getStreamerDetail",
      req
    );
    return response.data;
  };
  const updateStreamer = async (req: UpdateStreamerRequest) => {
    const response = await baseService.post<UpdateResponse>("/update", req);
    return response.data;
  };

  const exportStreamersAsCSV = async (
    req: GetStreamersRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportStreamersAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_STREAMERS_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    getDetail,
    updateStreamer,
    exportStreamersAsCSV,
  };
};
