import styles from "./ChangeStatus.module.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import AddStreamersToCampaignRequest from "model/request/campaign/addStreamersToCampaignRequest";
import EnumCampaignHasStreamerStatus from "model/enum/enumCampaignHasStreamerStatus";
import { createCampaignService } from "services/campaignService";

interface ChangeStatusProps {
  setReloadData: (value: boolean) => void;
  rowSelectionModel: string[];
  setRowSelectionModel: (rowSelectionModel: number[]) => void;
}

const ChangeStatus = ({
  setReloadData,
  rowSelectionModel,
  setRowSelectionModel,
}: ChangeStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const campaignService = createCampaignService();

  const handleAction = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    if (!id) return;
    switch (e.currentTarget.name) {
      case "add":
        const postBody: AddStreamersToCampaignRequest =
          {} as AddStreamersToCampaignRequest;
        postBody.id = parseInt(id);
        postBody.streamers = rowSelectionModel.map((id) => {
          return {
            id: id,
            status: EnumCampaignHasStreamerStatus.Requested,
          };
        });
        try {
          setIsLoading(true);
          const response = await toast.promise(
            campaignService.addStreamersToCampaign(postBody),
            {
              pending: "Sending request...",
            }
          );
          if (response.success) {
            toast.success("Request sent successfully");
            setReloadData(true);
            setRowSelectionModel([]);
          } else {
            toast.error("Failed to send request");
          }
        } catch {
          toast.error("Failed to send request");
        } finally {
          setIsLoading(false);
        }
        break;
      case "exclude":
        const postBody2: AddStreamersToCampaignRequest =
          {} as AddStreamersToCampaignRequest;
        postBody2.id = parseInt(id);
        postBody2.streamers = rowSelectionModel.map((id) => {
          return {
            id: id,
            status: EnumCampaignHasStreamerStatus.Excluded,
          };
        });
        try {
          setIsLoading(true);
          const response = await toast.promise(
            campaignService.addStreamersToCampaign(postBody2),
            {
              pending: "Excluding streamers...",
            }
          );
          if (response.success) {
            toast.success("Streamers excluded successfully");
            setReloadData(true);
          } else {
            toast.error("Failed to exclude streamers");
          }
        } catch {
          toast.error("Failed to exclude streamers");
        } finally {
          setIsLoading(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.changeStatus}>
      <button
        name="add"
        className={styles.actionBtn}
        disabled={isLoading}
        onClick={handleAction}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M435.9 64.9l-367.1 160c-6.5 3.1-6.3 12.4.3 15.3l99.3 56.1c5.9 3.3 13.2 2.6 18.3-1.8l195.8-168.8c1.3-1.1 4.4-3.2 5.6-2 1.3 1.3-.7 4.3-1.8 5.6L216.9 320.1c-4.7 5.3-5.4 13.1-1.6 19.1l64.9 104.1c3.2 6.3 12.3 6.2 15.2-.2L447.2 76c3.3-7.2-4.2-14.5-11.3-11.1z" />
        </svg>
        Send Request
      </button>
      <button
        name="exclude"
        className={styles.actionBtn}
        disabled={isLoading}
        onClick={handleAction}
      >
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth={2}
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x={14} y={14} width={8} height={8} rx={2} />
          <rect x={2} y={2} width={8} height={8} rx={2} />
          <path d="M7 14v1a2 2 0 0 0 2 2h1" />
          <path d="M14 7h1a2 2 0 0 1 2 2v1" />
        </svg>
        Exclude Streamers
      </button>
    </div>
  );
};

export default ChangeStatus;
