//STYLES
import DataCardSection from "components/Shared/DataCardSection/DataCardSection";
import styles from "./CampaignInfo.module.scss";

//COMPONENTS
import CampaignInfoHeader from "./CampaignInfoHeader/CampaignInfoHeader";
import GetCampaignDetailResponse from "model/response/campaign/getCampaignDetailResponse";
import { formatCurrency, formatNumber } from "helpers";

export interface DataCardListItem {
  key: keyof GetCampaignDetailResponse;
  title: string;
  image?: string;
  value?: string | number;
}

const dataCardList: DataCardListItem[] = [
  {
    key: "agency",
    title: "Agency",
  },
  {
    key: "views",
    title: "Views",
  },
  {
    key: "clicks",
    title: "Clicks",
  },
  {
    key: "CTR",
    title: "CTR",
  },
  {
    key: "revenue",
    title: "Revenue",
  },
  {
    key: "CPM",
    title: "CPM",
  },
  {
    key: "createdThrough",
    title: "Created Through",
  },
  {
    key: "createdByAdmin",
    title: "Created by Admin",
  },
];

function populateValues(
  dataCardList: DataCardListItem[],
  campaign: GetCampaignDetailResponse
) {
  dataCardList.forEach((card) => {
    if (campaign.hasOwnProperty(card.key)) {
      if (card.key === "agency") {
        card.value = campaign.agency?.name ? campaign.agency.name : "-";
        card.image = campaign.agency?.logo ? campaign.agency.logo : undefined;
      } else if (card.key === "views") {
        card.value = formatNumber(campaign.views) ?? "-";
      } else if (card.key === "clicks") {
        card.value = campaign.clicks ? formatNumber(campaign.clicks) : "0";
      } else if (card.key === "CTR") {
        card.value = campaign.CTR ? `${campaign.CTR}%` : "0";
      } else if (card.key === "revenue") {
        card.value = campaign.revenue
          ? `${campaign.currencySign} ${formatCurrency(campaign.revenue)}`
          : "-";
      } else if (card.key === "CPM") {
        card.value = campaign.CPM
          ? `${campaign.CPM.currencySign} ${formatCurrency(campaign.CPM.value)}`
          : "-";
      } else if (card.key === "createdThrough") {
        card.value = campaign.createdThrough ? campaign.createdThrough : "-";
      } else if (card.key === "createdByAdmin") {
        card.value = campaign.createdByAdmin ? campaign.createdByAdmin : "-";
      }
    }
  });
}

interface CampaignInfoProps {
  campaign: GetCampaignDetailResponse;
}

const CampaignInfo = ({ campaign }: CampaignInfoProps) => {
  populateValues(dataCardList, campaign);
  return (
    <div className={styles.campaignInfo}>
      <CampaignInfoHeader campaign={campaign} />
      <DataCardSection cardList={dataCardList} />
    </div>
  );
};

export default CampaignInfo;
