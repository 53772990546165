import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import { GridPaginationModel } from "@mui/x-data-grid-pro";
import {
  clearFilterList,
  searchFilter,
  selectFilterItem,
} from "components/CustomFilterList/CustomFilterList";

interface FilterContextProps {
  children: React.ReactNode;
  initialFilterList: FilterItemDto[];
}
type FilterContextInterface = {
  initialFilterList: FilterItemDto[];
  filterList: FilterItemDto[];
  handleSearchFilter: (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string
  ) => Promise<{ success: boolean; error: any }>;
  handleSelectFilterItem: (
    item: FilterItemResultsDto,
    fieldName: string
  ) => void;
  handleClearFilterList: () => void;
  paginationModel: GridPaginationModel;
  handlePaginationModelChange: (model: GridPaginationModel) => void;
  query: string | null;
  setQuery: React.Dispatch<React.SetStateAction<string | null>>;
};

export const FilterContext = createContext<FilterContextInterface | undefined>(
  undefined
);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};

export const FilterProvider = ({
  children,
  initialFilterList,
}: FilterContextProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterList, setFilterList] =
    useState<FilterItemDto[]>(initialFilterList);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const [queryParsing, setQueryParsing] = useState(true);
  const [query, setQuery] = useState<string | null>(null);

  const handleSearchFilter = async (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string
  ) => {
    return await searchFilter(
      value,
      filterType,
      fieldName,
      filterList,
      setFilterList
    );
  };

  useEffect(() => {
    handleClearFilterList();
  }, [initialFilterList]);

  const handleSelectFilterItem = (
    item: FilterItemResultsDto,
    fieldName: string
  ) => {
    selectFilterItem(item, fieldName, filterList, setFilterList);
  };

  const handleClearFilterList = () => {
    clearFilterList(initialFilterList, setFilterList);
    setPaginationModel({ page: 0, pageSize: 50 });
  };

  const handlePaginationModelChange = async (model: GridPaginationModel) => {
    setPaginationModel(model);
  };

  //query params to filterList
  useEffect(() => {
    const filters = searchParams.get("filters");
    if (!filters) return setQueryParsing(false);
    const selectedFilters = JSON.parse(decodeURIComponent(filters));
    const newFilterList = filterList.map((filterItem) => {
      const selectedResults: FilterItemResultsDto[] =
        selectedFilters[filterItem.fieldName];
      if (selectedResults) {
        return {
          ...filterItem,
          results: [
            ...filterItem.results.map((result) => {
              if (
                selectedResults.some(
                  (selectedResult: FilterItemResultsDto) =>
                    selectedResult.value === result.value
                )
              ) {
                selectedResults.splice(
                  selectedResults.findIndex(
                    (selectedResult: FilterItemResultsDto) =>
                      selectedResult.value === result.value
                  ),
                  1
                );
                return {
                  ...result,
                  selected: true,
                };
              }
              return result;
            }),
            ...selectedResults.map((selectedResult: FilterItemResultsDto) => {
              return {
                label: selectedResult.label,
                value: selectedResult.value,
                selected: true,
              };
            }),
          ],
        };
      }
      return filterItem;
    });
    setFilterList(newFilterList);
    setQueryParsing(false);
  }, []);

  const contextValue = {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    handleClearFilterList,
    paginationModel,
    handlePaginationModelChange,
    query,
    setQuery,
  };
  if (queryParsing) return null;
  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};
