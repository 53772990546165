import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//STYLES
import styles from "./AdsPopup.module.scss";

//CONFIGS
import adsPopupView from "./config/views/adsPopupView";
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import Labelling from "./Components/Labelling/Labelling";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import Loading from "components/Shared/Loading/Loading";
import PageSwitcher from "./Components/PageSwitcher/PageSwitcher";

//SERVICES
import { createAdsService } from "services/adsService";
import GetAdDetailResponse from "model/response/ads/getAdDetailResponse";

const AdsPopup = () => {
  const [activeTab, setActiveTab] = useState("labelling");
  const [isLoading, setIsLoading] = useState(true);
  const [lastAdId, setLastAdId] = useState<number>(0);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [adsRow, setAdsRow] = useState<Array<GetAdDetailResponse>>([]);

  const adsService = createAdsService();

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  useEffect(() => {
    (async () => {
      if (!id) return;
      const lastAd = await adsService.get({ take: 1, skip: 0 });
      setLastAdId(lastAd.data[0].id);
    })();
  }, []);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  const fetchAdDetail = async () => {
    if (!id) return;
    try {
      const ad = await adsService.getDetail({ id: Number(id) });
      setAdsRow([ad]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch ad detail");
    }
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    fetchAdDetail();
  }, [id]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.adsPopup}>
      <CustomDataGrid
        view={adsPopupView}
        rows={adsRow}
        rowCount={1}
        isLoading={isLoading}
        minHeight="auto"
      />
      <PageSwitcher lastAdId={lastAdId} />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
      {activeTab === "labelling" && <Labelling adDetail={adsRow[0]} />}
    </div>
  );
};

export default AdsPopup;
