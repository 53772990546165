import { useEffect } from "react";
//STYLES
import styles from "./GameEvents.module.scss";

//CONFIG
import gameEventViews from "./config/views";
//CONTEXT
import { useGameEventsContext } from "context/GameEventsContext";
import { useFilterContext } from "context/FilterContext";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useSearchParams } from "react-router-dom";

const GameEvents = () => {
  document.title = "Game Events - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchGameEvents,
    gridIsLoading,
  } = useGameEventsContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (initialFilterList === filterList) {
      fetchGameEvents();
    } else if (searchParams.get("filters")) {
      fetchGameEvents();
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.gameEvents}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 576 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z" />
          </svg>
          Game Events
        </div>
      </div>
      <ActionSection
        search={false}
        export={true}
        from="game-events"
        clear={false}
      />
      {gameEventViews.length > 1 && (
        <CustomSelect
          views={gameEventViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          gameEventViews.find((view) => view.id === activeView) ??
          gameEventViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default GameEvents;
