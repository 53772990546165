import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatNumber } from "helpers";

const requestedStreamersView: CustomDataGridViewDto = {
  viewName: "Requested Streamers",
  id: "requestedStreamersView",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__"] } },
    columns: [
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 180,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "circle",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "platform",
        headerName: "Platform",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return <CustomComponentCell platform={params.value} />;
        },
      },
      {
        field: "language",
        headerName: "Language",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.logo
                  ? {
                      rounded: "standard",
                      value: params.value?.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "currency",
        headerName: "Currency",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value.id : "-",
      },
      {
        field: "gender",
        headerName: "Gender",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "live",
        headerName: "Live",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "Yes" : "No",
      },
      {
        field: "viewerCount",
        headerName: "Viewer Count",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "-",
      },
      {
        field: "followerCount",
        headerName: "Followers",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? formatNumber(params.value) : "-",
      },
      {
        field: "adsOn",
        headerName: "Ads On",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "On" : "Off",
      },
      {
        field: "automatedAdsOn",
        headerName: "Automated Ads On",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "On" : "Off",
      },
      {
        field: "campaignAdsOn",
        headerName: "Campaign Ads On",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "On" : "Off",
      },
      {
        field: "campaignAutomatedAdsOn",
        headerName: "Campaign Automated Ads On",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? "On" : "Off",
      },
      {
        field: "adFrequency",
        headerName: "Ad Frequency",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? `${params.value} minutes` : "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default requestedStreamersView;
