import { EnumRequestType } from "model/enum/enumRequestType";
import { createBaseApiService } from "./baseApiService";
import GetAgencyRequest from "model/request/agency/getAgencyRequest";
import IdRequest from "model/request/idRequest";
import GetAgencyBillingDetailsResponse from "model/response/agency/getAgencyBillingDetailsResponse";
import GetAgencyResponse from "model/response/agency/getAgencyResponse";

export const createAgencyService = () => {
  const baseService = createBaseApiService("/agency");

  const get = async (req: GetAgencyRequest) => {
    const response = await baseService.post<GetAgencyResponse>("/get", req);
    return response.data;
  };

  const getBillingDetails = async (req: IdRequest) => {
    const response = await baseService.post<GetAgencyBillingDetailsResponse>(
      "/getBillingDetails",
      req
    );
    return response.data;
  };

  const exportAgeciesAsCSV = async (
    req: GetAgencyRequest,
    fileName?: string
  ) => {
    const response = await baseService.post<string>(
      "/exportAgenciesAsCSV",
      req,
      EnumRequestType.Json,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      fileName
        ? `${fileName}.csv`
        : `LIVAD_AGENCIES_${new Date().toISOString().replace(/:/g, "-")}.csv`
    );
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return {
    get,
    getBillingDetails,
    exportAgeciesAsCSV,
  };
};
