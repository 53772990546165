import { formatDate, toAbsoluteUrl } from "helpers";
import styles from "./ScreenshotListItem.module.scss";
import classNames from "classnames";
import { GetCampaignScreenshotsResponseItem } from "model/response/campaign/getCampaignScreenshotsResponse";
import { useState } from "react";

interface ScreenshotListItemProps {
  screenshot: GetCampaignScreenshotsResponseItem;
  handleScreenshotAction: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    screenshot: GetCampaignScreenshotsResponseItem
  ) => Promise<void>;
}
const ScreenshotListItem = ({
  screenshot,
  handleScreenshotAction,
}: ScreenshotListItemProps) => {
  const [disabled, setDisabled] = useState(false);

  const handleAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDisabled(true);
    await handleScreenshotAction(e, screenshot);
    setDisabled(false);
  };

  return (
    <div className={styles.screenshotListItem}>
      <div className={styles.screenshotDetails}>
        <div className={styles.streamer}>
          <img src={screenshot.streamer.logo ?? undefined} alt="Streamer" />
          <img
            src={toAbsoluteUrl(
              `/assets/icons/Platforms/${screenshot.streamer.platform}-icon.png`
            )}
            alt="Platform"
          />
          {screenshot.streamer.name}
        </div>
        <div className={styles.screenshotDate}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"></path>
          </svg>
          {formatDate(screenshot.timestamp) +
            " " +
            new Date(screenshot.timestamp).toLocaleTimeString()}
        </div>
      </div>
      <div className={styles.screenshot}>
        <img src={screenshot.screenshotUrl} alt="Screenshot" />
      </div>
      <div className={styles.actionSection}>
        <button
          className={classNames(styles.reject, {
            [styles.selected]: screenshot.isApproved == false,
          })}
          name="reject"
          onClick={handleAction}
          disabled={disabled}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M24 24H0V0h24v24z" />
            <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z" />
          </svg>
          Reject
        </button>
        <button
          className={classNames(styles.approve, {
            [styles.selected]: screenshot.isApproved == true,
          })}
          name="approve"
          onClick={handleAction}
          disabled={disabled}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M24 24H0V0h24v24z" />
            <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66a4.8 4.8 0 0 0-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84A2.34 2.34 0 0 0 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z" />
          </svg>
          Approve
        </button>
      </div>
    </div>
  );
};
export default ScreenshotListItem;
