import { useState, useEffect } from "react";

//STYLES
import styles from "./OtherStreamers.module.scss";

//CONFIGS
import initialFilterList from "./config/otherStreamersFilterOptions";
import otherStreamersView from "./config/view/otherStreamersView";

//COMPONENTS
import CustomFilterList, {
  searchFilter,
  selectFilterItem,
  clearFilterList,
} from "components/CustomFilterList/CustomFilterList";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";
import {
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import { createCampaignService } from "services/campaignService";
import GetCampaignOtherStreamersRequest from "model/request/campaign/getCampaignOtherStreamersRequest";
import { toast } from "react-toastify";
import ChangeStatus from "./Components/ChangeStatus/ChangeStatus";
import { GetCampaignOtherStreamersResponseItem } from "model/response/campaign/getCampaignOtherStreamersResponse";

interface OtherStreamersProps {
  setReloadData: (value: boolean) => void;
}

const OtherStreamers = ({ setReloadData }: OtherStreamersProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [streamers, setStreamers] = useState<GridRowsProp>([]);
  const [selectedStreamers, setSelectedStreamers] = useState<
    GetCampaignOtherStreamersResponseItem[]
  >([]);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [filterList, setFilterList] =
    useState<FilterItemDto[]>(initialFilterList);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 250,
  });
  const [pageSizeOptions, setPageSizeOptions] = useState<number[]>([250]);
  const { id } = useParams();

  const campaignService = createCampaignService();

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setRowSelectionModel(newRowSelectionModel);
    setSelectedStreamers(
      streamers.filter((streamer) =>
        newRowSelectionModel.includes(streamer.id)
      ) as GetCampaignOtherStreamersResponseItem[]
    );
  };

  const handleSearchFilter = async (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string
  ) => {
    return await searchFilter(
      value,
      filterType,
      fieldName,
      filterList,
      setFilterList
    );
  };

  const handleSelectFilterItem = (
    item: FilterItemResultsDto,
    fieldName: string
  ) => {
    selectFilterItem(item, fieldName, filterList, setFilterList);
  };

  const handleClearFilterList = () => {
    clearFilterList(initialFilterList, setFilterList);
  };

  const handleFetchStreamers = async () => {
    if (!id) return;
    const postBody: any = {} as GetCampaignOtherStreamersRequest;
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          if (filterItem.multiSelect) {
            postBody[filterItem.fieldName] = selectedResults.map(
              (result) => result.value
            );
          } else {
            postBody[filterItem.fieldName] = selectedResults[0].value;
          }
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });
    postBody.id = parseInt(id);
    postBody.take = paginationModel.pageSize;
    postBody.skip = paginationModel.page * paginationModel.pageSize;
    try {
      setIsLoading(true);
      const response = await campaignService.getCampaignOtherStreamers(
        postBody
      );
      setStreamers([
        ...selectedStreamers,
        ...response.data.filter(
          (streamer) =>
            !selectedStreamers.find(
              (selectedStreamer) => selectedStreamer.id === streamer.id
            )
        ),
      ]);
      setRowCount(response.rowCount);
    } catch {
      toast.error("Failed to fetch streamers");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleFetchStreamers();
  }, [paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    setPageSizeOptions([paginationModel.pageSize]);
  }, [paginationModel.pageSize]);

  return (
    <div className={styles.otherStreamers}>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <div className={styles.section}>
        <button className={styles.searchBtn} onClick={handleFetchStreamers}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z" />
          </svg>
          Search
        </button>
        <button onClick={handleClearFilterList}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M10.83 8H21V6H8.83l2 2zm5 5H18v-2h-4.17l2 2zM14 16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h.17L1.39 4.22 2.8 2.81l18.38 18.38-1.41 1.41L14 16.83z"></path>
          </svg>
          Clear Filters
        </button>
        <select
          value={paginationModel.pageSize}
          onChange={(e) => {
            setPaginationModel({
              ...paginationModel,
              pageSize: parseInt(e.target.value),
            });
          }}
        >
          <option value={10}>10</option>
          <option value={100}>100</option>
          <option value={250}>250</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
        </select>
      </div>
      {rowSelectionModel.length > 0 && (
        <ChangeStatus
          setReloadData={setReloadData}
          rowSelectionModel={rowSelectionModel as string[]}
          setRowSelectionModel={setRowSelectionModel}
        />
      )}
      <CustomDataGrid
        view={otherStreamersView}
        rowCount={rowCount}
        rows={streamers}
        isLoading={isLoading}
        checkboxSelection={true}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
      />
    </div>
  );
};

export default OtherStreamers;
