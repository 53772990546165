import styles from "./TabItem.module.scss";
import classNames from "classnames";

interface TabItemProps {
  title: string;
  value: string;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabItem = (props: TabItemProps) => {
  return (
    <div
      className={classNames(styles.tabItem, {
        [styles.active]: props.activeTab === props.value,
      })}
      onClick={() => props.setActiveTab(props.value)}
    >
      {props.title}
    </div>
  );
};

export default TabItem;
