import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink } from "helpers";
const payoutAccountsView: CustomDataGridViewDto = {
  viewName: "Payout Accounts",
  id: "payout-accounts",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "streamer":
        navigate(`streamer-popup/${params.row.streamer.id}/overview`);
        break;
      default:
        navigate(`payout-accounts-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 100,
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "createdAt",
        headerName: "Created At",
        resizable: true,
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "billingCountry",
        headerName: "Country",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.logo
                  ? {
                      rounded: "standard",
                      value: params.value?.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "payingEntity",
        headerName: "Paying Entity",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "paymentMethod",
        headerName: "Payment Method",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "billingPreference",
        headerName: "Billing Preference",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "billingAccountType",
        headerName: "Billing Account Type",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "taxExemptionType",
        headerName: "Tax Exemption Type",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "withholdingRate",
        headerName: "Withholding Rate",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "vatRate",
        headerName: "VAT Rate",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "vatWithholdingRate",
        headerName: "VAT Withholding Rate",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "customWithholdingRate",
        headerName: "Custom Withholding Rate",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "districtName",
        headerName: "District",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "cityName",
        headerName: "City",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default payoutAccountsView;
