import FilterItemDto from "model/dto/filterItemDto";
import EnumCampaignStatus from "model/enum/enumCampaignStatus";
import EnumFilterType from "model/enum/enumFilterType";
import EnumFilterCurrencies from "model/enum/enumFilterCurrencies";
import EnumCampaignType from "model/enum/enumCampaignType";

const campaignFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Campaign",
    tooltip: "Campaign name",
    results: [],
    filterType: EnumFilterType.Campaigns,
    fieldName: "campaignId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Client",
    tooltip: "Client name",
    results: [],
    filterType: EnumFilterType.Clients,
    fieldName: "clientId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Campaign Status",
    tooltip: "Campaign status",
    results: Object.entries(EnumCampaignStatus).map(([key, value]) => ({
      label: value,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "campaignStatus",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Campaign Type",
    tooltip: "Campaign type",
    results: Object.entries(EnumCampaignType).map(([key, value]) => ({
      label: value,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "campaignType",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Campaign Owner",
    tooltip: "Campaign owner",
    results: [],
    filterType: EnumFilterType.Owner,
    fieldName: "campaignOwner",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Language",
    tooltip: "Stream language",
    results: [],
    filterType: EnumFilterType.Languages,
    fieldName: "languageId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "searchable",
    title: "Currency",
    tooltip: "Campaign currency",
    results: [],
    filterType: EnumFilterType.Currency,
    fieldName: "currencyId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Include Archived",
    tooltip: "Include archived campaigns",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "includeArchived",
    multiSelect: false,
    style: "toggle",
  },
];

export default campaignFilterOptions;
