const tabs = [
  {
    title: "Overview",
    value: "overview",
  },
  {
    title: "Edit Campaign",
    value: "edit-campaign",
  },
  {
    title: "Requested Streamers",
    value: "requested-streamers",
  },
  {
    title: "Other Streamers",
    value: "other-streamers",
  },
  {
    title: "Screenshots",
    value: "screenshots",
  },
  {
    title: "Chat Engagement",
    value: "chat-engagement",
  },
  {
    title: "Post Ad",
    value: "post-ad",
  },
];
export default tabs;
