import { Answer } from "model/response/campaign/getCampaignBusinessSettingsResponse";
import styles from "./CampaignPollPreview.module.scss";
import { toAbsoluteUrl } from "helpers";

type Props = {
  question: string;
  options: Array<Answer>;
};
const CampaignPollPreview = ({ question, options }: Props) => {
  const convertVhToPercent = (vh: string) => {
    // check if the string is a valid vh value
    if (!vh.includes("vh")) return vh;
    // remove the vh part of the string
    const value = vh.slice(0, -2);
    // convert to percentage
    return `${value}%`;
  };
  return (
    <div className={styles.pollDemo}>
      <div className={styles.demoScreen}>
        <div className={styles.content}>
          {options && options.length && options[0].assetType === "video" ? (
            <video
              key={options && options.length && options[0].asset}
              autoPlay
              loop
              muted
              disablePictureInPicture
              playsInline
              style={{
                width:
                  (options && options.length ? options[0].width : "auto") ||
                  "auto",
                left:
                  (options && options.length
                    ? options[0].leftPadding
                    : undefined) || undefined,
                top:
                  (options && options.length && options[0].topPadding
                    ? convertVhToPercent(options[0].topPadding)
                    : undefined) || undefined,
                height:
                  options && options.length && options[0].width === "auto"
                    ? "100%"
                    : "auto",
              }}
            >
              <source
                src={
                  options && options.length && options[0].asset
                    ? options[0].asset
                    : undefined
                }
                type="video/webm"
              />
              Your browser does not support the video.
            </video>
          ) : options && options.length && options[0].assetType === "image" ? (
            <img
              src={
                options && options.length && options[0].asset
                  ? options[0].asset
                  : undefined
              }
              alt="campaign"
              style={{
                width:
                  (options && options.length ? options[0].width : "auto") ||
                  "auto",
                left:
                  (options && options.length
                    ? options[0].leftPadding
                    : undefined) || undefined,
                top:
                  (options && options.length && options[0].topPadding
                    ? convertVhToPercent(options[0].topPadding)
                    : undefined) || undefined,
                height:
                  options && options.length && options[0].width === "auto"
                    ? "100%"
                    : "auto",
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.previewChat}>
        <div className={styles.pollSession}>
          <div className={styles.title}>{question}</div>
          <div className={styles.pollOptions}>
            {options?.map((answer, index) => {
              return (
                <div key={index} className={styles.option}>
                  <div className={styles.optionText}>{answer?.text}</div>
                </div>
              );
            })}
          </div>
          <div className={styles.voteBtn}>Vote</div>
          <div className={styles.timerBar}></div>
        </div>
        <div className={styles.chatLoop}>
          <img
            className={styles.chatLoop}
            src={toAbsoluteUrl("/assets/icons/chatLoop.gif")}
            alt="chat"
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignPollPreview;
