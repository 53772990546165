import styles from "./PercentageCell.module.scss";

const PercentageCell = ({ value = 0 }: { value: number }) => {
  let percentageValue = !isNaN(value) ? `${value}%` : "0%";
  if(value < 0) percentageValue = "0%";
  return (
    <div className={styles.percentageCell}>
      <div
        className={styles.valueContainer}
        style={{ width: `${percentageValue}` }}
      >
        {percentageValue}
      </div>
    </div>
  );
};

export default PercentageCell;
