import styles from "./LabellingChat.module.scss";

//COMPONENTS
import Loading from "components/Shared/Loading/Loading";

//MODELS
import AdChatMessageDto from "model/dto/ad/adChatMessageDto";

interface LabellingChatProps {
  chatMessages?: AdChatMessageDto[];
  loading: boolean;
}

const LabellingChat = ({ chatMessages, loading }: LabellingChatProps) => {
  if (loading)
    return (
      <div className={styles.chatContainer}>
        <Loading />
      </div>
    );
  return (
    <div className={styles.chatContainer}>
      {chatMessages?.length === 0 && (
        <div className={styles.notAvailable}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="200px"
            width="200px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1" />
            <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905" />
            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
          </svg>
          Chat is not available
        </div>
      )}
      {chatMessages?.map((message) => (
        <div key={message.id} className={styles.chatItem}>
          <span className={styles.username}>{message.sender}</span>:{" "}
          {message.message}
        </div>
      ))}
    </div>
  );
};

export default LabellingChat;
