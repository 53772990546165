import React from "react";
import { Drawer } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//STYLES
import styles from "./SidePopup.module.scss";
import { toAbsoluteUrl } from "helpers";

//CONTEXTS
import { useFilterContext } from "context/FilterContext";

interface SidePopupProps {
  children: React.ReactNode;
  popupRoute?: string; // Optional base route override
  width?: string;
  customClose?: () => void;
}

const SidePopup: React.FC<SidePopupProps> = ({
  children,
  popupRoute,
  width = 600,
  customClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { query: filterQuery, setQuery } = useFilterContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const closePopup = () => {
    if (customClose) {
      customClose();
      return;
    }
    if (!popupRoute) {
      navigate(-1); // Default to navigate back
      return;
    }
    let baseRoute = location.pathname.split(popupRoute)[0];
    if (baseRoute[baseRoute.length - 1] === "/") {
      baseRoute = baseRoute.slice(0, -1);
    }
    const query = filterQuery || ""; // Capture existing query parameters
    setQuery(null);
    navigate(`${baseRoute}${query}`); // Navigate while preserving query parameters
  };

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={closePopup}
      variant="temporary"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: isMobile ? "100%" : width,
          background: "transparent",
        },
      }}
    >
      <div
        className={styles.sidePopup}
        role="presentation"
        style={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        <button onClick={closePopup} className={styles.closeButton}>
          <img src={toAbsoluteUrl("/assets/icons/closeIcon.png")} alt="Close" />
        </button>
        {children}
      </div>
    </Drawer>
  );
};

export default SidePopup;
