//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterReferrer from "model/enum/enumFilterReferrer";
import EnumFilterType from "model/enum/enumFilterType";

const postAdFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    results: [],
    referrer: EnumFilterReferrer.postAd,
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
];

export default postAdFilterOptions;
