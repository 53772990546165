import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//STYLES
import styles from "./PayoutAccountsPopup.module.scss";

//CONFIGS
import payoutAccountsPopupView from "./config/views/PayoutAccountsPopupView";
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import Loading from "components/Shared/Loading/Loading";

//MODELS

//SERVICES
import { createPayoutsService } from "services/payoutsService";
import GetPayoutAccountDetailResponse from "model/response/payouts/getPayoutAccountDetailResponse";

const PayoutAccountsPopup = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoading, setIsLoading] = useState(true);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [payoutAccountsRow, setPayoutAccountsRow] = useState<
    Array<GetPayoutAccountDetailResponse>
  >([]);

  const payoutsService = createPayoutsService();

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  const fetchPayoutAccountDetail = async () => {
    if (!id) return;
    try {
      const payoutAccount = await payoutsService.getPayoutAccountDetail({
        id: Number(id),
      });
      setPayoutAccountsRow([payoutAccount]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch payout account detail");
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchPayoutAccountDetail();
  }, [id]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.payoutAccountsPopup}>
      <CustomDataGrid
        view={payoutAccountsPopupView}
        rows={payoutAccountsRow}
        rowCount={1}
        isLoading={isLoading}
        minHeight="auto"
      />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
    </div>
  );
};

export default PayoutAccountsPopup;
