import styles from "./Sidebar.module.scss";
import headerStyles from "../Header/Header.module.scss";
import { toAbsoluteUrl } from "helpers";
import classNames from "classnames";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";
// COMPONENTS
import Navigation from "./Navigation/Navigation";

type SidebarProps = {
  sidebarToggle: boolean;
  setSidebarToggle: (value: boolean) => void;
  isSidebarMinified: boolean;
  setSidebarMinified: (value: boolean) => void;
};

const Sidebar = ({
  sidebarToggle,
  setSidebarToggle,
  isSidebarMinified,
  setSidebarMinified,
}: SidebarProps) => {
  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (
          !(
            e.target instanceof HTMLElement &&
            e.target.classList.contains(headerStyles.hamburgerIcon)
          )
        ) {
          setSidebarToggle(false);
        }
      }}
    >
      <div
        className={classNames(styles.sidebar, {
          [styles.active]: sidebarToggle,
        })}
        style={{
          width: isSidebarMinified ? "80px" : undefined,
          minWidth: isSidebarMinified ? "80px" : undefined,
        }}
      >
        <div className={styles.logoSection}>
          {isSidebarMinified ? (
            <>
              <img
                className={classNames(styles.logo, styles.logoMark)}
                src={toAbsoluteUrl("/assets/icons/logoMark.png")}
                alt="Logo"
                style={
                  isSidebarMinified
                    ? { width: "36px", height: "36px" }
                    : undefined
                }
              />
              <img
                className={(styles.logo, styles.hiddenLogo)}
                src={toAbsoluteUrl("/assets/icons/logo.png")}
                alt="Logo"
              />
            </>
          ) : (
            <img
              className={styles.logo}
              src={toAbsoluteUrl("/assets/icons/logo.png")}
              alt="Logo"
            />
          )}
          <button
            className={classNames(styles.toggleSidebarSize, {
              [styles.active]: isSidebarMinified,
            })}
            onClick={() => {
              setSidebarMinified(!isSidebarMinified);
              localStorage.setItem(
                "isSidebarMinified",
                JSON.stringify(!isSidebarMinified)
              );
            }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 20 20"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className={styles.close} onClick={() => setSidebarToggle(false)}>
          <img src={toAbsoluteUrl("/assets/icons/closeIcon.png")} alt="" />
        </div>
        {/* NAVIGATION */}
        <Navigation isSidebarMinified={isSidebarMinified} />
      </div>
    </ClickAwayListener>
  );
};

export default Sidebar;
