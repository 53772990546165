import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";

const clientsFilterOptions: FilterItemDto[] = [
  {
    title: "Agency",
    type: "searchable",
    results: [],
    filterType: EnumFilterType.Agencies,
    fieldName: "agencyId",
    multiSelect: true,
    style: "select",
  },
];

export default clientsFilterOptions;
