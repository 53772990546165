import styles from "./Leaderboard.module.scss";

const Leaderboard = () => {
  return (
    <div className={styles.leaderboard}>
      <div className={styles.title}>Leaderboard</div>
      <div className={styles.description}>
        How many seconds you want it to be visible on the screen; this is
        optional.
      </div>
      <div className={styles.inputLine}>
        <input type="number" placeholder="Seconds" />
        <button className={styles.showBtn}>Show</button>
      </div>
    </div>
  );
};

export default Leaderboard;
