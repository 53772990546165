import styles from "./ChatEngagement.module.scss";
import { useState, useEffect } from "react";

//CONFIGS
import initialFilterList from "./config/chatEngagementFilterOptions";

//COMPONENT
import CustomFilterList, {
  searchFilter,
  selectFilterItem,
  clearFilterList,
} from "components/CustomFilterList/CustomFilterList";
import ChatMessageList from "./Components/ChatMessageList/ChatMessageList";

//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import FilterItemResultsDto from "model/dto/filterItemResultsDto";
import EnumFilterType from "model/enum/enumFilterType";
import ChatEngagementKeywords from "./Components/ChatEngagementKeywords/ChatEngagementKeywords";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "components/Shared/Loading/Loading";
import Pagination from "components/Shared/Pagination/Pagination";
import PageOptions from "model/dto/pageOptions";

const ChatEngagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterList, setFilterList] =
    useState<FilterItemDto[]>(initialFilterList);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [activeKeywordIndex, setActiveKeywordIndex] = useState<number>(0);
  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 0,
    hasNextPage: false,
  });
  const campaignService = createCampaignService();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      if (!id) return;
      setIsLoading(true);
      try {
        const response = await campaignService.getCampaignKeywords({
          id: parseInt(id),
        });
        setKeywords(response.keywords);
        setIsLoading(false);
      } catch {
        toast.error("Failed to fetch keywords");
      }
    })();
  }, []);

  const handleSearchFilter = async (
    value: string,
    filterType: EnumFilterType | null,
    fieldName: string
  ) => {
    return await searchFilter(
      value,
      filterType,
      fieldName,
      filterList,
      setFilterList
    );
  };

  const handleSelectFilterItem = (
    item: FilterItemResultsDto,
    fieldName: string
  ) => {
    selectFilterItem(item, fieldName, filterList, setFilterList);
  };

  const handleClearFilterList = () => {
    clearFilterList(initialFilterList, setFilterList);
  };

  if (isLoading) return <Loading />;

  return (
    <div className={styles.chatEngagement}>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ChatEngagementKeywords
        filterList={filterList}
        keywords={keywords}
        activeKeywordIndex={activeKeywordIndex}
        setActiveKeywordIndex={setActiveKeywordIndex}
      />
      <ChatMessageList
        filterList={filterList}
        activeKeywordIndex={activeKeywordIndex}
        keywords={keywords}
        pageOptions={pageOptions}
        setPageOptions={setPageOptions}
      />
      <Pagination pageOptions={pageOptions} setPageOptions={setPageOptions} />
    </div>
  );
};

export default ChatEngagement;
