import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
//STYLES
import styles from "./Payouts.module.scss";
//CONFIG
import payoutViews from "./config/views";
//CONTEXT
import { usePayoutsContext } from "context/PayoutsContext";
import { useFilterContext } from "context/FilterContext";
//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import ChangeStatus from "./Components/ChangeStatus/ChangeStatus";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-pro";
import BulkPayoutInput from "./Components/BulkPayoutInput/BulkPayoutInput";

const Payouts = () => {
  document.title = "Payouts - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchPayouts,
    gridIsLoading,
  } = usePayoutsContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchPayouts();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchPayouts();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  const dataGridRef = useGridApiRef();

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  return (
    <div className={styles.payouts}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 576 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm48 160H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zM96 336c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zM376 160h80c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H376c-13.3 0-24-10.7-24-24V184c0-13.3 10.7-24 24-24z" />
          </svg>
          Payouts
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchPayouts}
        export={true}
        from="payouts"
        clear={true}
      />
      <BulkPayoutInput
        rowSelectionModel={rowSelectionModel as number[]}
        setRowSelectionModel={setRowSelectionModel}
      />
      {rowSelectionModel.length > 0 && (
        <ChangeStatus
          rowSelectionModel={rowSelectionModel as number[]}
          setRowSelectionModel={setRowSelectionModel}
        />
      )}
      {payoutViews.length > 1 && (
        <CustomSelect
          views={payoutViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          payoutViews.find((view) => view.id === activeView) ?? payoutViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        checkboxSelection={true}
        apiRef={dataGridRef}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default Payouts;
