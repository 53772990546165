import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatLink } from "helpers";
import AgenciesDetailPanel from "pages/Agencies/Components/AgenciesDetailPanel/AgenciesDetailPanel";
const clientsView: CustomDataGridViewDto = {
  viewName: "Agencies",
  id: "agencies",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  gridView: {
    disableVirtualization: true,
    initialState: {
      pinnedColumns: { left: ["__detail_panel_toggle__", "id"] },
    },
    columns: [
      {
        field: "id",
        headerName: "ID",
        resizable: true,
        width: 100,
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.logo
                ? {
                    rounded: "standard",
                    value: params.row.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "website",
        headerName: "Website",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "paymentTermDays",
        headerName: "Payment Term Days",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "comissionDeal",
        headerName: "Comission Deal",
        width: 100,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
    ],
    getDetailPanelContent: ({ row }) => {
      return <AgenciesDetailPanel id={row.id} />;
    },
    getDetailPanelHeight: () => "auto",
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default clientsView;
