import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatLink } from "helpers";

const billingDetailsView: CustomDataGridViewDto = {
  viewName: "Clients",
  id: "clients",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  gridView: {
    initialState: { pinnedColumns: { left: ["id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 100,
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "country",
        headerName: "Country",
        width: 100,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "currencySign",
        headerName: "Currency Sign",
        width: 100,
      },
      {
        field: "vatNumber",
        headerName: "VAT Number",
        width: 160,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "financeContactEmail",
        headerName: "Finance Email",
        width: 250,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "financeContactPhone",
        headerName: "Finance Phone",
        width: 200,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value.code && params.value.number
            ? "+" + params.value.code + params.value.number
            : "-",
      },
      {
        field: "commercialContactEmail",
        headerName: "Commercial Email",
        width: 250,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? params.value : "-",
      },
      {
        field: "commercialContactPhone",
        headerName: "Commercial Phone",
        width: 200,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value.code && params.value.number
            ? "+" + params.value.code + params.value.number
            : "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default billingDetailsView;
