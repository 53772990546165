import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

//STYLES
import styles from "./StreamersPopup.module.scss";

//CONFIGS
import streamersPopupView from "./config/views/streamersPopupView";
import tabs from "./config/tabs";

//COMPONENTS
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import Loading from "components/Shared/Loading/Loading";
import EditStreamer from "./Components/EditStreamer/EditStreamer";

//MODELS

//SERVICES
import { createStreamersService } from "services/streamersService";
import GetStreamerDetailResponse from "model/response/streamers/getStreamerDetailResponse";

const StreamersPopup = () => {
  const [activeTab, setActiveTab] = useState("edit-streamer");
  const [isLoading, setIsLoading] = useState(true);
  const { id, popupName, popupView } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [streamerRow, setStreamerRow] = useState<
    Array<GetStreamerDetailResponse>
  >([]);
  const [refreshData, setRefreshData] = useState(false);

  const streamersService = createStreamersService();

  //set active tab on page load
  useEffect(() => {
    if (!popupView) return;
    const popupViewExist = tabs.some((tab) => tab.value === popupView);
    if (popupViewExist) {
      if (activeTab !== popupView) {
        setActiveTab(popupView);
      }
    } else {
      if (!id || !activeTab) return;
      navigate(
        `/${location.pathname.split("/")[1]}/${popupName}/${id}/${activeTab}`
      );
    }
  }, [activeTab, id, popupView]);

  //navigate to the active tab when active tab changes

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/${location.pathname.split("/")[1]}/${popupName}/${id}/${tab}`);
  };

  const fetchStreamerDetail = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const streamer = await streamersService.getDetail({ id: id });
      setStreamerRow([streamer]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch Streamer detail");
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchStreamerDetail();
  }, [id]);

  useEffect(() => {
    if (refreshData) {
      fetchStreamerDetail();
      setRefreshData(false);
    }
  }, [refreshData]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles.streamersPopup}>
      <CustomDataGrid
        view={streamersPopupView}
        rows={streamerRow}
        rowCount={1}
        isLoading={isLoading}
        minHeight="auto"
      />
      <TabSwitcher
        activeTab={activeTab}
        setActiveTab={handleTabChange}
        tabs={tabs}
      />
      {activeTab === "edit-streamer" && (
        <EditStreamer
          streamer={streamerRow[0]}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
};

export default StreamersPopup;
