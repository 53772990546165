import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";

const donationFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Has Clip",
    tooltip: "Has a clip",
    results: [
      { label: "Yes", value: true, selected: false },
    ],
    filterType: null,
    fieldName: "hasClip",
    multiSelect: false,
    style: "toggle",
  }
];

export default donationFilterOptions;
