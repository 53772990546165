import { useContext, useEffect, useState } from "react";
import styles from "./Login.module.scss";
import { toAbsoluteUrl } from "helpers";
//import gsap
import { gsap } from "gsap";
import { AppLevelContext } from "context/AppLevelContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { toast } from "react-toastify";

const Login = () => {
  const { user, signIn, resetPassword } = useContext(AppLevelContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<"login" | "forgotPassword">(
    "login"
  );
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>("");

  const location = useLocation();
  const from = location.state?.return_url || "/";

  const checkLoginEnabled = () => {
    if (email && password) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    if (!checkLoginEnabled()) {
      toast.error("Please enter email and password");
      return;
    }
    setIsLoading(true);
    const data = await signIn(email, password);
    setIsLoading(false);
    if (data.success) {
      setEmail("");
      setPassword("");
    } else {
      toast.error(data.error);
    }
  };

  const handlePasswordReset = async (e: any) => {
    e.preventDefault();
    if (isLoading) return;
    if (!forgotPasswordEmail) {
      toast.error("Please enter email");
      return;
    }
    setIsLoading(true);
    const data = await resetPassword(forgotPasswordEmail);
    setIsLoading(false);
    if (data.success) {
      toast.success("Password reset email sent");
    } else {
      toast.error(data.error);
    }
  };

  useEffect(() => {
    const lvd = gsap.timeline({
      defaults: { duration: 0.75, ease: "power1.out" },
    });

    lvd.fromTo(
      "." + styles.livadAuth,
      { scale: 0 },
      { scale: 1, ease: "elastic.out(0.6, 0.4)", duration: 1.5 }
    );
    lvd.fromTo(
      "." + styles.bannerTitle,
      { opacity: 0, x: -50 },
      { opacity: 1, x: 0 },
      "<"
    );
    lvd.fromTo(
      "." + styles.authBanner,
      { opacity: 0, x: 50 },
      { opacity: 1, x: 0 },
      "<"
    );

    var livadAuthBg = gsap.fromTo(
      "." + styles.authBanner,
      1.5,
      { backgroundColor: "#869ef5" },
      {
        backgroundColor: "#5d7fff",
        ease: "slow(0.7, 0.7, false)",
        repeat: -1,
        yoyo: true,
      }
    );
  }, []);

  if (user) {
    return <Navigate to={from} />;
  }
  return (
    <>
      <div className={styles.authBody}>
        <div className={styles.livadAuth}>
          <div className={styles.authBanner}>
            <span className={styles.bannerTitle}>
              Leverage the power of <b>smart</b>
              <br />
              <b>Livestream Marketing</b>
            </span>
          </div>
          <div className={styles.authForm}>
            <div className={styles.formContent}>
              <img
                id={styles.logo}
                src={toAbsoluteUrl("/assets/icons/logo.png")}
                alt="LIVAD"
                draggable="false"
              />
              <h1 className={styles.authH1}>for Team</h1>
              {currentView === "login" && (
                <div className={styles.loginView}>
                  <div className={styles.authFormWrap}>
                    <div className={styles.inputLine}>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email"
                        id="email"
                      />
                      <span className={styles.inputIcon}>
                        <img
                          src="https://storage.googleapis.com/livad-ads/Images/Auth/email-icon.png"
                          alt=""
                          draggable="false"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </span>
                    </div>
                    <div className={styles.inputLine}>
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        id="password"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleLogin(e);
                          }
                        }}
                      />
                      <span className={styles.inputIcon}>
                        <img
                          src="https://storage.googleapis.com/livad-ads/Images/Auth/password-icon.png"
                          alt=""
                          draggable="false"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </span>
                    </div>
                    <div className={styles.authActionLine}>
                      <span
                        className={styles.btnForget}
                        onClick={() => {
                          setCurrentView("forgotPassword");
                        }}
                      >
                        Forgot password?
                      </span>
                      <button
                        onClick={handleLogin}
                        className={classNames(styles.btnLogin, {
                          [styles.disabled]: isLoading,
                        })}
                      >
                        {!isLoading ? (
                          <span className={styles.btnText}>Login</span>
                        ) : (
                          <span className={styles.isLoading}>
                            <img
                              src={toAbsoluteUrl("/assets/icons/loading.gif")}
                              alt="Loading"
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>

                  <p className={styles.authAgree}>
                    By proceeding, you confirm that you have read and <br />
                    agree to our{" "}
                    <a
                      href="https://livad.stream/terms-and-conditions"
                      target="_blank"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.livad.stream/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              )}
              {currentView === "forgotPassword" && (
                <div className={styles.forgetPasswordView}>
                  <div className={styles.authFormWrap}>
                    <div className={styles.inputLine}>
                      <button
                        className={styles.backBtn}
                        onClick={() => {
                          setCurrentView("login");
                        }}
                      >
                        <img
                          src={toAbsoluteUrl("/assets/icons/Auth/backIcon.png")}
                          alt="Back"
                        />{" "}
                        Back to Login
                      </button>
                    </div>

                    <div className={styles.inputLine}>
                      <input
                        value={forgotPasswordEmail}
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handlePasswordReset(e);
                          }
                        }}
                        type="text"
                        placeholder="Email"
                        id="email"
                      />
                      <span className={styles.inputIcon}>
                        <img
                          src="https://storage.googleapis.com/livad-ads/Images/Auth/email-icon.png"
                          alt=""
                          draggable="false"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </span>
                    </div>

                    <div className={styles.inputLine}>
                      <button
                        onClick={handlePasswordReset}
                        className={classNames(styles.resetPasswordBtn, {
                          [styles.disabled]: isLoading,
                        })}
                      >
                        {!isLoading ? (
                          <span className={styles.btnText}>
                            Request Password Reset
                          </span>
                        ) : (
                          <span className={styles.isLoading}>
                            <img
                              src={toAbsoluteUrl("/assets/icons/loading.gif")}
                              alt="Loading"
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
