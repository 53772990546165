import { toast } from "react-toastify";
import { createContext, useContext, useEffect, useState } from "react";
import { useFilterContext } from "./FilterContext";
import { GetPostedAdsResponseItem } from "model/response/liveConsole/getPostedAdsResponse";
import { GetScheduledAdsResponseItem } from "model/response/liveConsole/getScheduledAdsResponse";
import { createLiveConsoleService } from "services/liveConsoleService";

interface LiveConsoleContextProps {
  children: React.ReactNode;
}

type LiveConsoleContextInterface = {
  scheduledAds: GetScheduledAdsResponseItem[];
  postedAds: GetPostedAdsResponseItem[];
  scheduledAdsLoading: boolean;
  postedAdsLoading: boolean;
  fetchScheduledAds: () => Promise<void>;
  fetchPostedAds: () => Promise<void>;
  reloadData: boolean;
  setReloadData: (value: boolean) => void;
};

export const LiveConsoleContext = createContext<
  LiveConsoleContextInterface | undefined
>(undefined);

export const useLiveConsoleContext = () => {
  const context = useContext(LiveConsoleContext);
  if (context === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error(
      "useLiveConsoleContext must be used within a LiveConsoleProvider"
    );
  }
  const filterContext = useFilterContext();
  if (filterContext === undefined) {
    // This error will only be thrown if the hook is used outside of the FilterProvider
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const LiveConsoleProvider = ({ children }: LiveConsoleContextProps) => {
  const [scheduledAdsLoading, setScheduledAdsLoading] = useState(true);
  const [postedAdsLoading, setPostedAdsLoading] = useState(true);
  const [scheduledAds, setScheduledAds] = useState<
    GetScheduledAdsResponseItem[]
  >([]);
  const [postedAds, setPostedAds] = useState<GetPostedAdsResponseItem[]>([]);
  const [reloadData, setReloadData] = useState(false);

  const liveConsoleService = createLiveConsoleService();

  const fetchScheduledAds = async () => {
    setScheduledAdsLoading(true);
    setScheduledAds([]);
    try {
      const response = await liveConsoleService.getScheduledAds({ take: 102 });
      setScheduledAds(response.data);
    } catch {
      toast.error("Failed to fetch scheduled ads");
    } finally {
      setScheduledAdsLoading(false);
    }
  };
  const fetchPostedAds = async () => {
    setPostedAdsLoading(true);
    setPostedAds([]);
    try {
      const response = await liveConsoleService.getPostedAds({ take: 102 });
      setPostedAds(response.data);
    } catch {
      toast.error("Failed to fetch posted ads");
    } finally {
      setPostedAdsLoading(false);
    }
  };

  const contextValue = {
    scheduledAds,
    postedAds,
    scheduledAdsLoading,
    postedAdsLoading,
    fetchScheduledAds,
    fetchPostedAds,
    reloadData,
    setReloadData,
  };
  return (
    <LiveConsoleContext.Provider value={contextValue}>
      {children}
    </LiveConsoleContext.Provider>
  );
};
