import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

//STYLES
import styles from "./Labelling.module.scss";

//COMPONENTS
import LabellingControls from "./Components/LabellingControls/LabellingControls";
import LabellingChat from "./Components/LabellingChat/LabellingChat";
import GetAdDetailResponse from "model/response/ads/getAdDetailResponse";
import TabSwitcher from "components/TabSwitcher/TabSwitcher";
import TranscriptText from "./Components/TranscriptText/TranscriptText";
import TranscriptSubtitle from "./Components/TranscriptSubtitle/TranscriptSubtitle";

//SERVICES
import { createAdsService } from "services/adsService";

//MODELS
import AdChatMessageDto from "model/dto/ad/adChatMessageDto";
import AdSubtitleDto from "model/dto/ad/adSubtitleDto";
import GetAdTranscriptsResponse from "model/response/ads/getAdTranscriptsResponse";

interface LabellingProps {
  adDetail: GetAdDetailResponse;
}

type LoadingType = {
  chat: boolean;
  transcriptSubtitle: boolean;
  transcriptText: boolean;
};

const Labelling = ({ adDetail }: LabellingProps) => {
  const tabs = [
    {
      title: "Chat",
      value: "chat",
    },
    {
      title: "Transcript Subtitle",
      value: "transcript-subtitle",
    },
    {
      title: "Transcript Text",
      value: "transcript-text",
    },
  ];

  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [clipType, setClipType] = useState<"main" | "promotion">("main");
  const [activeTab, setActiveTab] = useState("chat");
  const [chatMessages, setChatMessages] = useState<AdChatMessageDto[]>([]);
  const [adSubtitles, setAdSubtitles] = useState<AdSubtitleDto[]>([]);
  const [adTranscripts, setAdTranscripts] =
    useState<GetAdTranscriptsResponse>();
  const [loading, setLoading] = useState<LoadingType>({
    chat: true,
    transcriptSubtitle: true,
    transcriptText: true,
  });

  const adService = createAdsService();

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        setCurrentVideoTime(videoRef.current.currentTime);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const fetchLabellingData = async () => {
    if (!adDetail?.id) return;
    try {
      const chatResponse = await adService.getChatMessages({ id: adDetail.id });
      setChatMessages(chatResponse);
      setLoading((prev) => ({ ...prev, chat: false }));
    } catch (error) {
      setLoading((prev) => ({ ...prev, chat: false }));
      toast.error("Error while fetching chat messages");
    }
    try {
      const subtitleResponse = await adService.getAdSubtitles({
        id: adDetail.id,
      });
      setAdSubtitles(subtitleResponse);
      setLoading((prev) => ({ ...prev, transcriptSubtitle: false }));
    } catch {
      setLoading((prev) => ({ ...prev, transcriptSubtitle: false }));
      toast.error("Error while fetching subtitles");
    }
    try {
      const transcriptResponse = await adService.getAdTranscripts({
        id: adDetail.id,
      });
      setAdTranscripts(transcriptResponse);
      setLoading((prev) => ({ ...prev, transcriptText: false }));
    } catch (error) {
      setLoading((prev) => ({ ...prev, transcriptText: false }));
      toast.error("Error while fetching transcripts");
    }
  };

  useEffect(() => {
    fetchLabellingData();
  }, []);

  return (
    <div className={styles.labelling}>
      {adDetail?.promotionClipLink && (
        <div className={styles.clipTypeSwitcher}>
          <div
            className={classNames(styles.typeItem, {
              [styles.active]: clipType === "main",
            })}
            onClick={() => setClipType("main")}
          >
            Main Clip
          </div>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 20 20"
            aria-hidden="true"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
          </svg>
          <div
            className={classNames(styles.typeItem, {
              [styles.active]: clipType === "promotion",
            })}
            onClick={() => setClipType("promotion")}
          >
            Promotion Clip
          </div>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.videoSection}>
          <div className={styles.videoContainer}>
            <video
              ref={videoRef}
              src={
                clipType === "main"
                  ? adDetail?.clipLink
                    ? adDetail?.clipLink
                    : undefined
                  : adDetail?.promotionClipLink
                  ? adDetail?.promotionClipLink
                  : undefined
              }
              autoPlay
              controls
              muted
              crossOrigin="anonymous"
            >
              <track
                src={
                  clipType === "main"
                    ? adDetail?.subtitleLink
                      ? adDetail?.subtitleLink
                      : undefined
                    : adDetail?.promotionSubtitleLink
                    ? adDetail?.promotionSubtitleLink
                    : undefined
                }
                kind="subtitles"
                srcLang="en"
                label="English"
                default
              />
            </video>
          </div>
          {/* <LabellingControls
            isDisplayed={adDetail?.isDisplayed}
            isGreatPromo={adDetail?.isGreatPromo}
            isClickbait={adDetail?.isClickbait}
            isBonus={adDetail?.isBonus}
            isValid={adDetail?.isValid}
            isAgainstBrandSafety={adDetail?.isAgainstBrandSafety}
          /> */}
        </div>
        <div className={styles.chatSection}>
          <TabSwitcher
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {activeTab === "chat" ? (
            <LabellingChat chatMessages={chatMessages} loading={loading.chat} />
          ) : activeTab === "transcript-text" ? (
            <TranscriptText
              adTranscripts={adTranscripts}
              adSubtitles={adSubtitles}
              promotionStartTime={adDetail?.promotionStartTimeSeconds}
              promotionEndTime={adDetail?.promotionEndTimeSeconds}
              clipType={clipType}
              loading={loading.transcriptText}
            />
          ) : activeTab === "transcript-subtitle" ? (
            <TranscriptSubtitle
              adSubtitles={adSubtitles}
              currentVideoTime={currentVideoTime}
              setCurrentVideoTime={setCurrentVideoTime}
              videoRef={videoRef}
              clipType={clipType}
              promotionStartTime={adDetail?.promotionStartTimeSeconds}
              promotionEndTime={adDetail?.promotionEndTimeSeconds}
              loading={loading.transcriptSubtitle}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Labelling;
