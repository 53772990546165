import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";

import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import { formatCurrency, formatLink, formatNumber } from "helpers";

const campaignSetupView: CustomDataGridViewDto = {
  viewName: "Campaign Setup",
  id: "campaign-setup",
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "__check__":
        break;
      default:
        navigate(`campaigns-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "name"] } },
    columns: [
      {
        field: "name",
        headerName: "Campaign",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.client.logo
                ? {
                    rounded: "standard",
                    value: params.row.client.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "id",
        headerName: "Id",
        width: 60,
      },
      {
        field: "client",
        headerName: "Client",
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.name}
            image={
              params.value.logo
                ? {
                    rounded: "standard",
                    value: params.value.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "objective",
        headerName: "Objective",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "startDate",
        headerName: "Start Date",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.value).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }),
      },
      {
        field: "endDate",
        headerName: "End Date",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.value).toLocaleDateString("tr-TR", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }),
      },
      {
        field: "type",
        headerName: "Type",
        width: 150,
        editable: false,
      },
      {
        field: "format",
        headerName: "Format",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "budget",
        headerName: "Budget",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${formatCurrency(params.value)}`
            : "-";
        },
      },
      {
        field: "cpm",
        headerName: "CPM",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${params.value}`
            : "-";
        },
      },
      {
        field: "viewsGoal",
        headerName: "Views Goal",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "asset",
        headerName: "Asset",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "pretext",
        headerName: "Chat Text",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "redirectLink",
        headerName: "Redirect Link",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "brief",
        headerName: "Brief",
        width: 300,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default campaignSetupView;
