import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import { formatCurrency, formatLink } from "helpers";
const payoutsView: CustomDataGridViewDto = {
  viewName: "Payouts",
  id: "payouts",
  // DO NOT USE both onRowClick and onCellClick or onCellDoubleClick and at the same time in the same view
  // onRowClick: (params, navigate, query) => {
  //   navigate(`/ads/ads-popup/${params.id}/overview`, {
  //     state: { query },
  //   });
  // },
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "streamer":
        navigate(`streamer-popup/${params.row.streamer.id}/overview`);
        break;
      case "payoutAccountId":
        navigate(
          `payout-accounts-popup/${params.row.payoutAccountId}/overview`
        );
        break;
      case "__check__":
        break;
      default:
        navigate(`payouts-popup/${params.id}/edit-payout`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "id"] } },
    columns: [
      {
        field: "id",
        headerName: "ID",
        width: 100,
      },
      {
        field: "streamer",
        headerName: "Streamer",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.name}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "payoutAccountId",
        headerName: "Payout Account ID",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value.id : "-";
        },
      },
      {
        field: "billingCountry",
        headerName: "Country",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value?.name}
              image={
                params.value?.flag
                  ? {
                      rounded: "standard",
                      value: params.value?.flag,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "paymentMethod",
        headerName: "Payment Method",
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "grossPaymentAmount",
        headerName: "Gross Payment",
        width: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0
            ? `${params.row?.currencySign || ""} ${formatCurrency(
                params.value
              )}`
            : "-",
      },
      {
        field: "totalTaxAmount",
        headerName: "Total Tax",
        width: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0
            ? `${params.row?.currencySign || ""} ${formatCurrency(
                params.value
              )}`
            : "-",
      },
      {
        field: "netPaymentAmount",
        headerName: "Net Payment",
        width: 120,
        renderCell: (params: GridRenderCellParams) =>
          params.value || params.value === 0
            ? `${params.row?.currencySign || ""} ${formatCurrency(
                params.value
              )}`
            : "-",
      },
      {
        field: "requestedTimestamp",
        headerName: "Requested Date",

        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "acceptedTimestamp",
        headerName: "Accepted Date",

        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "rejectedTimestamp",
        headerName: "Rejected Date",

        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "completedTimestamp",
        headerName: "Completed Date",

        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? new Date(params.value).toLocaleString() : "-";
        },
      },
      {
        field: "rejectionReason",
        headerName: "Rejection Reason",
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "invoiceUrl",
        headerName: "Invoice URL",
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? (
            <a href={formatLink(params.value)} target="_blank" rel="noreferrer">
              {params.value}
            </a>
          ) : (
            "-"
          );
        },
      },
      {
        field: "billingPreference",
        headerName: "Billing Preference",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "billingAccountType",
        headerName: "Billing Account Type",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "taxExemptionType",
        headerName: "Tax Exemption Type",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "withholdingRate",
        headerName: "Withholding Rate",
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "vatRate",
        headerName: "VAT Rate",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
      {
        field: "vatWithholdingRate",
        headerName: "VAT Withholding Rate",
        width: 180,
        renderCell: (params: GridRenderCellParams) => {
          return params.value ? params.value : "-";
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default payoutsView;
