// src/utils/analytics.js
import { initialReferrer } from '../index';  // Adjust the path based on your project structure

export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-WJZXZHK9EP');
}
  
export const logPageView = () => {
    if (window.gtag) {
      window.gtag('config', 'G-WJZXZHK9EP', {
        'page_path': window.location.pathname,
        'referrer': initialReferrer
      });
    }
}

// You can add event tracking functions here as needed.