import { useEffect, useRef } from "react";
//STYLES
import styles from "./Agencies.module.scss";

//CONFIG
import agencyViews from "./config/views";
//CONTEXT

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useAgenciesContext } from "context/AgenciesContext";
import { useFilterContext } from "context/FilterContext";
import { useSearchParams } from "react-router-dom";

const Agencies = () => {
  document.title = "Agencies - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchAgencies,
    gridIsLoading,
  } = useAgenciesContext();
  const {
    initialFilterList,
    filterList,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchAgencies();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchAgencies();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.agencies}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            {" "}
            <path fill="none" d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z" />{" "}
            <path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z" />{" "}
          </svg>
          Agencies
        </div>
      </div>
      <ActionSection
        search={false}
        export={true}
        from={"agencies"}
        clear={false}
      />
      {agencyViews.length > 1 && (
        <CustomSelect
          views={agencyViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          agencyViews.find((view) => view.id === activeView) ?? agencyViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default Agencies;
