import styles from "./DataCardItem.module.scss";

interface DataCardItemProps {
  title: string;
  value?: string | number;
  image?: string;
}

const DataCardItem = (props: DataCardItemProps) => {
  return (
    <div className={styles.dataCardItem}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.value}>
        {!!props.image && <img src={props.image} alt={props.title} />}
        {props.value ?? "-"}
      </div>
    </div>
  );
};

export default DataCardItem;
