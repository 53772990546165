import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";

const postReviewView: CustomDataGridViewDto = {
  viewName: "Post Review",
  id: "post-review",
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "__check__":
        break;
      default:
        navigate(`campaigns-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "name"] } },
    columns: [
      {
        field: "name",
        headerName: "Campaign",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.client.logo
                ? {
                    rounded: "standard",
                    value: params.row.client.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "id",
        headerName: "Id",
        width: 60,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "owner",
        headerName: "Owner",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.fullName}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "cpm",
        headerName: "CPM",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${params.value}`
            : "-";
        },
      },
      {
        field: "budget",
        headerName: "Budget",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? `${params.row.currencySign} ${params.value}`
            : "-";
        },
      },
      {
        field: "influencerCpms",
        headerName: "Influencer CPM",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => params.value || "-",
      },
      {
        field: "paymentDueDate",
        headerName: "Payment Due Date",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value
            ? new Date(params.value).toLocaleDateString("tr-TR", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })
            : "-";
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default postReviewView;
