// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBi8IZKWDoFvEMKPYzWpjDg3oXmXnqBII8",
  authDomain: "livad-team.firebaseapp.com",
  projectId: "livad-team",
  storageBucket: "livad-team.appspot.com",
  messagingSenderId: "526361333046",
  appId: "1:526361333046:web:ac83c422a170ac46547797",
  measurementId: "G-8PNCNPCL9G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
