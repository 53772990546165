import { useState, useEffect, useRef } from "react";
//STYLES
import styles from "./Ads.module.scss";

//CONFIG
import adViews from "./config/views";
//CONTEXT
import { useAdsContext } from "context/AdsContext";
import { useFilterContext } from "context/FilterContext";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useSearchParams } from "react-router-dom";

const Ads = () => {
  document.title = "Ads - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchAds,
    exportAds,
    gridIsLoading,
  } = useAdsContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchAds();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchAds();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  return (
    <div className={styles.ads}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 1L1 2V14L2 15H14L15 14V2L14 1H2ZM2 14V2H9V14H2Z"
            />{" "}
          </svg>
          Ads
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchAds}
        export={true}
        from={"ads"}
        clear={true}
      />
      {adViews.length > 1 && (
        <CustomSelect
          views={adViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={adViews.find((view) => view.id === activeView) ?? adViews[0]}
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default Ads;
