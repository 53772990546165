enum EnumCampaignStatus {
  Active = "active",
  Past = "past",
  Pending = "pending",
  Paused = "paused",
  Draft = "draft",
  In_Review = "in_review",
}

export default EnumCampaignStatus;
