import EditMessageRequest from "model/request/message/editMessageRequest";
import { createBaseApiService } from "./baseApiService";
import UpdateResponse from "model/response/updateResponse";

export const createMessageService = () => {
  const baseService = createBaseApiService("/message");

  const editMessage = async (req: EditMessageRequest) => {
    const response = await baseService.post<UpdateResponse>(
      "/editMessage",
      req
    );
    return response.data;
  };

  return {
    editMessage,
  };
};
