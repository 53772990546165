import styles from "./SelectItem.module.scss";
import { toAbsoluteUrl } from "helpers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useState } from "react";
import classNames from "classnames";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";

interface SelectItemProps {
  activeView: string;
  views: CustomDataGridViewDto[];
  viewSetter: (view: string) => void;
}
const SelectItem = (props: SelectItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.selectItem} onClick={() => setIsOpen(!isOpen)}>
      <div className={styles.selectTitle}>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M7 10h14l-4 -4"></path>
          <path d="M17 14h-14l4 4"></path>
        </svg>
        Change View
      </div>
      <div className={styles.selectInput}>
        <span className={styles.inputPlaceholder}>
          {props.views.find((view) => view.id === props.activeView)?.viewName}
        </span>
        <img
          src={toAbsoluteUrl("/assets/icons/downArrow.png")}
          alt="arrow-icon"
        />
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={styles.selectResult}>
            <div className={styles.itemList}>
              {props.views.map((view, index) => (
                <div
                  key={index}
                  className={classNames(styles.item, {
                    [styles.selected]: view.id === props.activeView,
                  })}
                  onClick={() => {
                    props.viewSetter(view.id);
                    setIsOpen(false);
                  }}
                >
                  <span className={styles.text} title={view.viewName}>
                    {view.viewName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default SelectItem;
