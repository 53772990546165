const tabs = [
  {
    title: "overview",
    value: "overview",
  },
  {
    title: "Edit Streamer",
    value: "edit-streamer",
  },
];
export default tabs;
