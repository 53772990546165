import styles from "./ConfirmPopup.module.scss";
import { ClickAwayListener } from "@mui/material";

interface ConfirmPopupProps {
  title: string | undefined;
  setShowConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

const ConfirmPopup = ({
  title,
  setShowConfirmPopup,
  onConfirm,
}: ConfirmPopupProps) => {
  return (
    <div className={styles.confirmPopup}>
      <ClickAwayListener onClickAway={() => setShowConfirmPopup(false)}>
        <div className={styles.popupContent}>
          <div className={styles.title}>{title ? title : "Are you sure?"}</div>
          <div className={styles.buttonSection}>
            <button
              onClick={() => {
                onConfirm();
                setShowConfirmPopup(false);
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                setShowConfirmPopup(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ConfirmPopup;
