import { useLiveConsoleContext } from "context/LiveConsoleContext";
import styles from "./ScheduledItem.module.scss";
import classNames from "classnames";
import { toAbsoluteUrl } from "helpers";
import { GetPostedAdsResponseItem } from "model/response/liveConsole/getPostedAdsResponse";
import { GetScheduledAdsResponseItem } from "model/response/liveConsole/getScheduledAdsResponse";
import { useEffect, useState } from "react";

interface ScheduledItemProps {
  item: GetScheduledAdsResponseItem | GetPostedAdsResponseItem;
}

const ScheduledItem = ({ item }: ScheduledItemProps) => {
  const [countDown, setCountDown] = useState<string | null>(null);
  const { setReloadData } = useLiveConsoleContext();

  let interval: NodeJS.Timeout;

  const calculateCountDown = () => {
    const now = new Date().getTime();
    const distance = new Date(item.predictedTimestamp).getTime() - now;
    if (distance < 0) {
      clearInterval(interval);
      setReloadData(true);
      setCountDown("00:00:00");
    } else {
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountDown(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      );
    }
  };
  useEffect(() => {
    if (item.status === "valid") {
      interval = setInterval(calculateCountDown, 1000);
    }
    return () => clearInterval(interval);
  }, [item.status, item.predictedTimestamp]);

  useEffect(() => {
    calculateCountDown();
  }, []);

  return (
    <div
      className={classNames(styles.scheduledItem, {
        [styles.posted]: item.status === "posted",
      })}
    >
      <div className={styles.dateSection}>
        {item.status === "valid" ? (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
          </svg>
        ) : (
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Calendar">
              <path d="M18.438,4.954H16.5c0-0.346,0-0.691,0-1.036c0-0.124,0-0.248,0-0.372c0-0.262-0.23-0.512-0.5-0.5 c-0.271,0.012-0.5,0.22-0.5,0.5c0,0.469,0,0.939,0,1.408h-7c0-0.346,0-0.691,0-1.036c0-0.124,0-0.248,0-0.372 c0-0.262-0.23-0.512-0.5-0.5c-0.271,0.012-0.5,0.22-0.5,0.5c0,0.469,0,0.939,0,1.408H5.562c-1.378,0-2.5,1.122-2.5,2.5v11 c0,1.379,1.122,2.5,2.5,2.5h12.875c1.379,0,2.5-1.121,2.5-2.5v-11C20.938,6.076,19.816,4.954,18.438,4.954z M5.562,5.954H7.5 c0,0.073,0,0.147,0,0.22c0,0.124,0,0.248,0,0.372c0,0.262,0.23,0.512,0.5,0.5c0.271-0.012,0.5-0.22,0.5-0.5c0-0.197,0-0.394,0-0.592 h7c0,0.073,0,0.147,0,0.22c0,0.124,0,0.248,0,0.372c0,0.262,0.23,0.512,0.5,0.5c0.271-0.012,0.5-0.22,0.5-0.5 c0-0.197,0-0.394,0-0.592h1.937c0.827,0,1.5,0.673,1.5,1.5v1.584H4.062V7.454C4.062,6.627,4.735,5.954,5.562,5.954z M18.438,19.954 H5.562c-0.827,0-1.5-0.673-1.5-1.5v-8.416h15.875v8.416C19.938,19.281,19.265,19.954,18.438,19.954z" />
            </g>
          </svg>
        )}
        {item.status === "valid"
          ? countDown
          : new Date(item.predictedTimestamp).toLocaleDateString() + " " + new Date(item.predictedTimestamp).toLocaleTimeString()}
      </div>
      <div className={styles.streamerSection}>
        <div className={styles.avatar}>
          <img
            className={styles.streamerAvatar}
            src={
              item.streamer.logo ??
              toAbsoluteUrl("/assets/icons/defaultAvatar.png")
            }
            alt="Avatar"
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl(
                "/assets/icons/defaultAvatar.png"
              );
            }}
          />
          <div className={styles.platform}>
            <img
              src={toAbsoluteUrl(
                `/assets/icons/Platforms/${item.platform}-icon.png`
              )}
              alt="Platform"
            />
          </div>
        </div>
        <div onClick={() => {
          if (item.platform === 'twitch') {
            // Open url in new tab
            window.open(`https://www.twitch.tv/${item.streamer.name}`, '_blank');
          } else if (item.platform === 'kick') {
            // Open url in new tab
            window.open(`https://www.kick.com/${item.streamer.name}`, '_blank');
          }
        }} className={styles.streamerName} title={item.streamer.name}>
          {item.streamer.name}
        </div>
      </div>
      <div className={styles.clientSection}>
        <div className={styles.client}>
          <img
            className={styles.clientLogo}
            src={
              item.client.logo ??
              toAbsoluteUrl("/assets/icons/defaultBusinessAvatar.png")
            }
            onError={(e) => {
              e.currentTarget.src = toAbsoluteUrl(
                "/assets/icons/defaultBusinessAvatar.png"
              );
            }}
            alt="Logo"
          />
          <div className={styles.clientName} title={item.client.name}>
            {item.client.name}
          </div>
        </div>
        <div className={styles.campaign} title={item.campaign.name}>
          {item.campaign.name}
        </div>
      </div>
    </div>
  );
};

export default ScheduledItem;
