import { useState, useEffect, useRef } from "react";
//STYLES
import styles from "./Campaigns.module.scss";

//CONFIG
import campaignViews from "./config/views";
//CONTEXT
import { useCampaignsContext } from "context/CampaignsContext";
import { useFilterContext } from "context/FilterContext";

//COMPONENTS
import CustomDataGrid from "components/CustomDataGrid/CustomDataGrid";
import CustomFilterList from "components/CustomFilterList/CustomFilterList";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ActionSection from "components/ActionSection/ActionSection";
import { useSearchParams } from "react-router-dom";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import EditCampaignsBulk from "./Components/EditCampaignsBulk/EditCampaignsBulk";

const Campaigns = () => {
  document.title = "Campaigns - LIVAD for Team";
  const {
    activeView,
    changeActiveView,
    gridRows,
    gridRowCount,
    fetchCampaigns,
    gridIsLoading,
  } = useCampaignsContext();
  const {
    initialFilterList,
    filterList,
    handleSearchFilter,
    handleSelectFilterItem,
    paginationModel,
    handlePaginationModelChange,
  } = useFilterContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFetch = useRef(false);

  useEffect(() => {
    if (initialFetch.current) return;
    if (initialFilterList === filterList) {
      fetchCampaigns();
      initialFetch.current = true;
    } else if (searchParams.get("filters")) {
      fetchCampaigns();
      initialFetch.current = true;
    }
  }, [initialFilterList, filterList]);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  return (
    <div className={styles.campaigns}>
      <div className={styles.titleSection}>
        <div className={styles.pageTitle}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25 25 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009l.496.008a64 64 0 0 1 1.51.048m1.39 1.081q.428.032.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a66 66 0 0 1 1.692.064q.491.026.966.06" />{" "}
          </svg>
          Campaigns
        </div>
      </div>
      <CustomFilterList
        filterList={filterList}
        handleSearchFilter={handleSearchFilter}
        handleSelectFilterItem={handleSelectFilterItem}
      />
      <ActionSection
        search={true}
        searchOnClick={fetchCampaigns}
        export={true}
        from="campaigns"
        clear={true}
      />
      {rowSelectionModel.length > 0 && (
        <EditCampaignsBulk
          rowSelectionModel={rowSelectionModel as number[]}
          setRowSelectionModel={setRowSelectionModel}
        />
      )}
      {campaignViews.length > 1 && (
        <CustomSelect
          views={campaignViews}
          activeView={activeView}
          viewSetter={changeActiveView}
        />
      )}
      <CustomDataGrid
        view={
          campaignViews.find((view) => view.id === activeView) ??
          campaignViews[0]
        }
        rows={gridRows}
        isLoading={gridIsLoading}
        rowCount={gridRowCount}
        checkboxSelection={true}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
      />
    </div>
  );
};

export default Campaigns;
