import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import EnumGameSessionStatus from "model/enum/enumGameSessionStatus";
import EnumGameType from "model/enum/enumGameType";
import EnumPayingEntity from "model/enum/enumPayingEntity";
import EnumPaymentMethod from "model/enum/enumPaymentMethod";
import EnumPayoutAccountStatus from "model/enum/enumPayoutAccountStatus";

const gameSessionsFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Status",
    tooltip: "Game session status",
    results: Object.entries(EnumGameSessionStatus).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    multiSelect: true,
    fieldName: "status",
    style: "select",
  },
  {
    type: "static",
    title: "Game Type",
    tooltip: "Game type of the session",
    results: Object.entries(EnumGameType).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    multiSelect: true,
    fieldName: "gameType",
    style: "select",
  },
  {
    type: "searchable",
    title: "Language",
    tooltip: "Language of the stream",
    results: [],
    filterType: EnumFilterType.Languages,
    multiSelect: true,
    fieldName: "languageId",
    style: "select",
    iconRounded: "none",
  },
];

export default gameSessionsFilterOptions;
