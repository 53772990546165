import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "styles/customReactDateTime.scss";
import App from "./App";
// Context
// Router
import { BrowserRouter as Router } from "react-router-dom";
import { initGA } from "./helpers/analytics";
import { AppLevelContextProvider } from "context/AppLevelContext";

// Capture the initial referrer when the application first loads
const initialReferrer = document.referrer;

// Global error handler to capture unhandled JavaScript errors
window.onerror = function (message, source, lineno, colno, error) {
  if (window.gtag) {
    window.gtag("event", "javascript_error", {
      event_category: "error",
      event_label: `${message} at ${source}:${lineno}:${colno}`,
      value: error,
    });
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Router>
      <AppLevelContextProvider>
        <App />
      </AppLevelContextProvider>
    </Router>
  </>
);

// Initialize GA after rendering the app
initGA();

// Export the initialReferrer so it can be used elsewhere, like in your analytics.js
export { initialReferrer };
