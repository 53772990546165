enum EnumCampaignFormatType {
  // Video, Poll, Rich Media, Sidebar, Snipe
  VIDEO = "video",
  POLL = "poll",
  RICH_MEDIA = "rich_media",
  SIDEBAR = "sidebar",
  SNIPE = "snipe",
}

export default EnumCampaignFormatType;
