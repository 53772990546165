import styles from "./ScreenshotList.module.scss";

//COMPONENTS
import ScreenshotListItem from "./Components/ScreenshotListItem/ScreenshotListItem";
import FilterItemDto from "model/dto/filterItemDto";
import { GetCampaignScreenshotsResponseItem } from "model/response/campaign/getCampaignScreenshotsResponse";
import { useEffect, useState } from "react";
import Loading from "components/Shared/Loading/Loading";
import { createCampaignService } from "services/campaignService";
import { useParams } from "react-router-dom";
import GetCampaignScreenshotsRequest from "model/request/campaign/getCampaignScreenshotsRequest";
import PageOptions from "model/dto/pageOptions";
import { toast } from "react-toastify";

interface ScreenshotListProps {
  filterList: FilterItemDto[];
  pageOptions: PageOptions;
  setPageOptions: (pageOptions: PageOptions) => void;
}

const ScreenshotList = ({
  filterList,
  pageOptions,
  setPageOptions,
}: ScreenshotListProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [screenshots, setScreenshots] = useState<
    GetCampaignScreenshotsResponseItem[] | null
  >(null);
  const [screenshotPerPage, setScreenshotPerPage] = useState(20);
  const { id } = useParams();
  const campaignService = createCampaignService();
  const [previousFilterList, setPreviousFilterList] = useState<FilterItemDto[]>(
    []
  );
  const [stateForTrigger, setStateForTrigger] = useState(false);

  const preparePostBody = (filterList: FilterItemDto[]) => {
    const postBody: {
      [key: string]:
        | Array<boolean | string | number | null>
        | boolean
        | string
        | number
        | null;
    } = {};
    filterList.forEach((filterItem) => {
      const selectedResults = filterItem.results.filter(
        (result) => result.selected
      );
      if (selectedResults.length > 0) {
        if (filterItem.style === "select") {
          if (filterItem.multiSelect) {
            postBody[filterItem.fieldName] = selectedResults.map(
              (result) => result.value
            );
          } else {
            postBody[filterItem.fieldName] = selectedResults[0].value;
          }
        } else if (filterItem.style === "toggle") {
          postBody[filterItem.fieldName] = selectedResults[0].value;
        }
      }
    });

    return postBody;
  };

  const fetchScreenshots = async (
    filterList: FilterItemDto[],
    pageOptions: PageOptions,
    setPageOptions: (pageOptions: PageOptions) => void
  ) => {
    if (!id) return;
    let postBody = preparePostBody(filterList);

    postBody.id = parseInt(id);
    postBody.take = screenshotPerPage;
    postBody.skip = pageOptions.page * screenshotPerPage;
    if (!postBody.type) {
      postBody.type = "screenshot";
    }
    try {
      setIsLoading(true);
      const response = await campaignService.getScreenshots(
        postBody as unknown as GetCampaignScreenshotsRequest
      );
      setScreenshots(response.data);
      setPageOptions({
        ...pageOptions,
        hasNextPage:
          response.rowCount > (pageOptions.page + 1) * screenshotPerPage,
      });
    } catch {
      toast.error("Failed to fetch screenshots");
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleScreenshotAction = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    screenshot: GetCampaignScreenshotsResponseItem
  ) => {
    if (!screenshots) return;
    try {
      if (e.currentTarget.name === "approve") {
        setScreenshots(
          screenshots.map((ss) =>
            ss.id === screenshot.id
              ? {
                  ...ss,
                  isApproved: screenshot.isApproved == true ? null : true,
                }
              : ss
          )
        );
        await campaignService.editScreenshots({
          id: screenshot.id,
          type: screenshot.type,
          isApproved: screenshot.isApproved == true ? null : true,
        });
      } else if (e.currentTarget.name === "reject") {
        setScreenshots(
          screenshots.map((ss) =>
            ss.id === screenshot.id
              ? {
                  ...ss,
                  isApproved: screenshot.isApproved == false ? null : false,
                }
              : ss
          )
        );
        await campaignService.editScreenshots({
          id: screenshot.id,
          type: screenshot.type,
          isApproved: screenshot.isApproved == false ? null : false,
        });
      } else {
        return;
      }
    } catch (error) {
      toast.error("Failed to edit message");
      setScreenshots(
        screenshots.map((ss) =>
          ss.id === screenshot.id
            ? { ...ss, isApproved: screenshot.isApproved }
            : ss
        )
      );
    }
  };

  const hasDiff = (
    previousFilterList: FilterItemDto[],
    filterList: FilterItemDto[]
  ) => {
    const previousSelectedFilters = preparePostBody(previousFilterList);
    const selectedFilters = preparePostBody(filterList);
    return (
      JSON.stringify(selectedFilters) !==
      JSON.stringify(previousSelectedFilters)
    );
  };

  useEffect(() => {
    setPreviousFilterList(filterList);
  }, []);

  useEffect(() => {
    let resetPage = false;
    if (hasDiff(previousFilterList, filterList) && pageOptions.page !== 0)
      resetPage = true;
    if (resetPage) {
      setPageOptions({
        ...pageOptions,
        page: 0,
      });
    } else {
      setPreviousFilterList(filterList);
      fetchScreenshots(filterList, pageOptions, setPageOptions);
    }
  }, [stateForTrigger, pageOptions.page]);

  useEffect(() => {
    if (hasDiff(previousFilterList, filterList)) {
      setStateForTrigger(!stateForTrigger);
    }
  }, [filterList]);

  if (!id) return null;
  if (isLoading || !screenshots) return <Loading />;

  return (
    <div className={styles.screenshotList}>
      {screenshots.map((screenshot, index) => (
        <ScreenshotListItem
          key={index}
          screenshot={screenshot}
          handleScreenshotAction={handleScreenshotAction}
        />
      ))}
    </div>
  );
};

export default ScreenshotList;
