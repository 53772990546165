import styles from "components/CustomDataGrid/CustomDataGrid.module.scss";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CustomComponentCell from "components/CustomDataGrid/Components/CustomComponentCell/CustomComponentCell";
import PercentageCell from "components/CustomDataGrid/Components/PercentageCell/PercentageCell";
import CustomDataGridViewDto from "model/dto/customDataGridViewDto";
import { formatNumber } from "helpers";

const adDetectionAndScreenshotsView: CustomDataGridViewDto = {
  viewName: "Ad Detection and Screenshots",
  id: "ad-detection-and-screenshots",
  onCellClick: (params, event, navigate) => {
    event.defaultMuiPrevented = true;
    switch (params.field) {
      case "__check__":
        break;
      default:
        navigate(`campaigns-popup/${params.id}/overview`);
        break;
    }
  },
  gridView: {
    initialState: { pinnedColumns: { left: ["__check__", "name"] } },
    columns: [
      {
        field: "name",
        headerName: "Campaign",
        width: 250,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value}
            image={
              params.row.client.logo
                ? {
                    rounded: "standard",
                    value: params.row.client.logo,
                  }
                : undefined
            }
          />
        ),
      },
      {
        field: "id",
        headerName: "Id",
        width: 60,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CustomComponentCell
            value={params.value.charAt(0).toUpperCase() + params.value.slice(1)}
            status={params.value}
          />
        ),
      },
      {
        field: "owner",
        headerName: "Owner",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <CustomComponentCell
              value={params.value.fullName}
              image={
                params.value.logo
                  ? {
                      rounded: "circle",
                      value: params.value.logo,
                    }
                  : undefined
              }
            />
          ) : (
            "-"
          ),
      },
      {
        field: "adCount",
        headerName: "Ad Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "screenshotCount",
        headerName: "Screenshot Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "gifCount",
        headerName: "Gif Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "adDetectionCount",
        headerName: "Ad Detection Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "displayedCount",
        headerName: "Displayed Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "notDisplayedCount",
        headerName: "Not Displayed Count",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) =>
          formatNumber(params.value) || "-",
      },
      {
        field: "displayRatio",
        headerName: "Display Ratio",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return <PercentageCell value={params.value} />;
        },
      },
      {
        field: "screenshotRatio",
        headerName: "Screenshot Ratio",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return <PercentageCell value={params.value} />;
        },
      },
      {
        field: "gifRatio",
        headerName: "Gif Ratio",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return <PercentageCell value={params.value} />;
        },
      },
      {
        field: "adDetectionRatio",
        headerName: "Ad Detection Ratio",
        width: 150,
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === null) {
            return "-";
          }
          return <PercentageCell value={params.value} />;
        },
      },
    ],
    density: "compact",
    rowHeight: 40,
    sx: {
      "& .MuiDataGrid-cell": {
        cursor: "pointer",
      },
    },
  },
};

export default adDetectionAndScreenshotsView;
