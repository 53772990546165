//MODELS
import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import EnumGender from "model/enum/enumGender";
import EnumBroadcastingSoftware from "model/enum/enumBroadcastingSoftware";
import EnumPlatform from "model/enum/enumPlatform";

const otherStreamersFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Currency",
    results: [],
    filterType: EnumFilterType.Currency,
    fieldName: "currencyId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Platform",
    results: Object.entries(EnumPlatform).map(([key, value]) => ({
      label: value.charAt(0).toUpperCase() + value.slice(1),
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "platform",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Category",
    results: [],
    filterType: EnumFilterType.Category,
    fieldName: "categoryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "static",
    title: "Live",
    results: [
      { label: "Live", value: 1, selected: false },
      { label: "Not Live", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "live",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Ads On",
    results: [
      { label: "On", value: 1, selected: false },
      { label: "Off", value: 0, selected: false },
      { label: "Null", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "adsOn",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Automated On",
    results: [
      { label: "On", value: 1, selected: false },
      { label: "Off", value: 0, selected: false },
      { label: "Null", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "automatedAdsOn",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Sign Up Completed",
    results: [
      { label: "On", value: 1, selected: false },
      { label: "Off", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "signUpCompleted",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Is Banned",
    results: [
      { label: "On", value: 1, selected: false },
      { label: "Off", value: 0, selected: false },
      { label: "Null", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "isBanned",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Broadcasting Language",
    results: [],
    filterType: EnumFilterType.Languages,
    fieldName: "broadcastingLanguageId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "static",
    title: "Broadcasting Software",
    results: [
      { label: "OBS", value: EnumBroadcastingSoftware.OBS, selected: false },
      {
        label: "Streamlabs",
        value: EnumBroadcastingSoftware.STREAMLABS,
        selected: false,
      },
      {
        label: "Twitch Studio",
        value: EnumBroadcastingSoftware.TWITCH_STUDIO,
        selected: false,
      },
      { label: "Null", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "broadcastingSoftware",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Gender",
    results: [
      ...Object.entries(EnumGender).map(([key, value]) => ({
        label: key,
        value: value,
        selected: false,
      })),
      { label: "Null", value: null, selected: false },
    ],
    filterType: null,
    fieldName: "gender",
    multiSelect: true,
    style: "select",
  },
];

export default otherStreamersFilterOptions;
