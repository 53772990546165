import styles from "./EditCampaignsBulk.module.scss";
import { useEffect, useState } from "react";
import { useCampaignsContext } from "context/CampaignsContext";
import { toast } from "react-toastify";
import EditCampaignSettingsRequest from "model/request/campaign/editCampaignSettingsRequest";
import EnumCampaignStatus from "model/enum/enumCampaignStatus";
import { createFilterService } from "services/filterService";
import EnumFilterType from "model/enum/enumFilterType";
import { SearchFilterResponseItem } from "model/response/filter/searchFilterResponse";

interface ChangeStatusProps {
  rowSelectionModel: number[];
  setRowSelectionModel: (rowSelectionModel: number[]) => void;
}

const EditCampaignsBulk = ({
  rowSelectionModel,
  setRowSelectionModel,
}: ChangeStatusProps) => {
  const [owners, setOwners] = useState<SearchFilterResponseItem[]>([]);
  const [ownerLoading, setOwnerLoading] = useState(true);
  const [editedCampaignsData, setEditedCampaignsData] = useState<
    Partial<EditCampaignSettingsRequest>
  >({});
  const [isLoading, setIsLoading] = useState(false);
  const { editCampaignsBulk } = useCampaignsContext();
  const filterService = createFilterService();
  const handleUpdateBulk = async () => {
    setIsLoading(true);
    if (
      editedCampaignsData.status === undefined &&
      editedCampaignsData.ownerId === undefined
    ) {
      setIsLoading(false);
      toast.error("Please select at least one field to update");
      return;
    }
    const data = await editCampaignsBulk(
      rowSelectionModel,
      editedCampaignsData
    );
    if (data.success) {
      setIsLoading(false);
      setRowSelectionModel([]);
      setEditedCampaignsData({});
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setOwnerLoading(true);
      const res = await filterService.get({
        type: EnumFilterType.Owner,
        search: "",
      });
      setOwners(res.data);
      setOwnerLoading(false);
    })();
  }, []);

  return (
    <div className={styles.editCampaignsBulk}>
      <div className={styles.section}>
        <div className={styles.title}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeWidth={2}
              d="M2.99787498,6.99999999 L2.99787498,0.999999992 L17.4999998,0.999999992 L20.9999998,4.50000005 L21,23 L15,23 M16,1 L16,6 L21,6 M8,23 C11.8659932,23 15,19.8659932 15,16 C15,12.1340068 11.8659932,9 8,9 C4.13400675,9 1,12.1340068 1,16 C1,19.8659932 4.13400675,23 8,23 Z M4.5,16.5 L8,13 L11.5,16.5 M8,13.5 L8,20"
            />
          </svg>
          Status
        </div>
        <select
          onChange={(e) => {
            if (e.target.value === "default-value") {
              setEditedCampaignsData((prev) => ({
                ...prev,
                status: undefined,
              }));
              return;
            }
            setEditedCampaignsData((prev) => ({
              ...prev,
              status: e.target.value as EnumCampaignStatus,
            }));
          }}
          value={editedCampaignsData.status}
        >
          <option value="default-value">Select</option>
          {Object.entries(EnumCampaignStatus).map(([key, value]) => (
            <option key={key} value={value}>
              {value
                .split("_")
                .map((word) => word[0].toUpperCase() + word.slice(1))
                .join(" ")}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeWidth={2}
              d="M2.99787498,6.99999999 L2.99787498,0.999999992 L17.4999998,0.999999992 L20.9999998,4.50000005 L21,23 L15,23 M16,1 L16,6 L21,6 M8,23 C11.8659932,23 15,19.8659932 15,16 C15,12.1340068 11.8659932,9 8,9 C4.13400675,9 1,12.1340068 1,16 C1,19.8659932 4.13400675,23 8,23 Z M4.5,16.5 L8,13 L11.5,16.5 M8,13.5 L8,20"
            />
          </svg>
          Owner
        </div>
        <select
          onChange={(e) => {
            if (e.target.value === "default-value") {
              setEditedCampaignsData((prev) => ({
                ...prev,
                ownerId: undefined,
              }));
              return;
            } else if (e.target.value === "null-value") {
              setEditedCampaignsData((prev) => ({
                ...prev,
                ownerId: null,
              }));
              return;
            }
            setEditedCampaignsData((prev) => ({
              ...prev,
              ownerId: e.target.value,
            }));
          }}
          value={
            editedCampaignsData.ownerId
              ? editedCampaignsData.ownerId
              : undefined
          }
        >
          {ownerLoading && <option>Loading...</option>}
          {!ownerLoading && (
            <>
              <option value="default-value">Select</option>
              <option value="null-value">None</option>
              {owners.map((owner) => (
                <option key={owner.value} value={owner.value}>
                  {owner.logo && (
                    <img className={styles.logo} src={owner.logo} alt="logo" />
                  )}
                  {owner.label}
                </option>
              ))}
            </>
          )}
        </select>
      </div>
      <button
        className={styles.saveChanges}
        disabled={isLoading}
        onClick={handleUpdateBulk}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth={0}
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18 19H19V6.82843L17.1716 5H16V9H7V5H5V19H6V12H18V19ZM4 3H18L20.7071 5.70711C20.8946 5.89464 21 6.149 21 6.41421V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM8 14V19H16V14H8Z" />
        </svg>
        Save for Selected
      </button>
    </div>
  );
};

export default EditCampaignsBulk;
