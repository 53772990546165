import FilterItemDto from "model/dto/filterItemDto";
import EnumFilterType from "model/enum/enumFilterType";
import EnumPlatform from "model/enum/enumPlatform";
import EnumGender from "model/enum/enumGender";
import EnumRiskLevel from "model/enum/enumRiskLevel";

const adFilterOptions: FilterItemDto[] = [
  {
    type: "searchable",
    title: "Streamer",
    tooltip: "Streamer username",
    results: [],
    filterType: EnumFilterType.Streamers,
    fieldName: "streamerId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Campaign",
    tooltip: "Campaign name",
    results: [],
    filterType: EnumFilterType.Campaigns,
    fieldName: "campaignId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Client",
    tooltip: "Client name",
    results: [],
    filterType: EnumFilterType.Clients,
    fieldName: "clientId",
    multiSelect: true,
    style: "select",
  },
  {
    type: "searchable",
    title: "Stream Category",
    tooltip: "Stream category",
    results: [],
    filterType: EnumFilterType.Category,
    fieldName: "categoryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "searchable",
    title: "Language",
    tooltip: "Stream language",
    results: [],
    filterType: EnumFilterType.Languages,
    fieldName: "languageId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "searchable",
    title: "Country",
    tooltip: "Country name",
    results: [],
    filterType: EnumFilterType.Country,
    fieldName: "countryId",
    multiSelect: true,
    style: "select",
    iconRounded: "none",
  },
  {
    type: "static",
    title: "Platform",
    tooltip: "Streamer platform",
    results: Object.entries(EnumPlatform).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "platform",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Gender",
    tooltip: "Streamer gender",
    results: Object.entries(EnumGender).map(([key, value]) => ({
      label: key,
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "gender",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Is Automated",
    tooltip: "Is the ad sent by the system",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "isAutomated",
    multiSelect: false,
    style: "select",
  },
  {
    type: "static",
    title: "Brand Mention Detected",
    tooltip: "Checks similarity score ≥ 0.3",
    results: [
      { label: "Yes", value: true, selected: false },
      { label: "No", value: false, selected: false },
    ],
    filterType: null,
    fieldName: "brandMentionDetected",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Brand Safety Risk Level",
    tooltip: "Brand safety risk level of the ad",
    results: Object.entries(EnumRiskLevel).map(([key, value]) => ({
      label:
        `${
          key.toLowerCase().charAt(0).toUpperCase() + key.toLowerCase().slice(1)
        } Risk ` +
        (key === "NO"
          ? "(0)"
          : key === "LOW"
          ? "(0.01 - 0.3)"
          : key === "MEDIUM"
          ? "(0.31 - 0.5)"
          : key === "HIGH"
          ? "(0.51 - 1)"
          : null),
      value: value,
      selected: false,
    })),
    filterType: null,
    fieldName: "brandSafetyRiskLevel",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Is Ad Detected",
    tooltip: "Is the ad detected by the system",
    results: [
      { label: "Yes", value: 1, selected: false },
      { label: "No", value: 0, selected: false },
    ],
    filterType: null,
    fieldName: "isAdDetected",
    multiSelect: true,
    style: "select",
  },
  {
    type: "static",
    title: "Brand Mention Processed",
    tooltip: "Is the brand mention processed",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "brandMentionProcessed",
    multiSelect: false,
    style: "toggle",
  },
  {
    type: "static",
    title: "Has Clip",
    tooltip: "Has a clip",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "hasClipLink",
    multiSelect: false,
    style: "toggle",
  },
  {
    type: "static",
    title: "Has Promotion Clip",
    tooltip:
      "Checks ad has promotion clip link and similarity score ≥ 0.3 & translated subtitle is not null",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "hasPromotionLink",
    multiSelect: false,
    style: "toggle",
  },
  {
    type: "static",
    title: "Has Promotion Score",
    tooltip: "Checks if ad promotion score is not null and sorts by promotion score in descending order",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "hasPromotionScore",
    multiSelect: false,
    style: "toggle",
  },
  {
    type: "static",
    title: "Has Subtitle",
    tooltip: "Has a subtitle of clip",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "hasSubtitleLink",
    multiSelect: false,
    style: "toggle",
  },
  {
    type: "static",
    title: "Labelled by Streamer",
    tooltip: "Clips marked and submitted by streamers",
    results: [{ label: "Yes", value: true, selected: false }],
    filterType: null,
    fieldName: "isLabelled",
    multiSelect: false,
    style: "toggle",
  },
];

export default adFilterOptions;
